import { ReactElement, useCallback } from "react";
import Grid from "@mui/material/Grid";
import useTheme from "@mui/material/styles/useTheme";
import { useHistory, useRouteMatch } from "react-router";
import SearchInput from "../common/SearchInput";

export default function ExplorerSearch(): ReactElement | null {
  const rm = useRouteMatch<{ customerId: string; sourceId: string }>(
    "/explorer/:customerId/:sourceId"
  );
  const { customerId, sourceId } = rm?.params ?? {
    customerId: "",
    sourceId: "",
  };
  const history = useHistory();
  const theme = useTheme();

  const handleSubmit = useCallback(
    (searchTerm: string) => {
      history.push(
        `/explorer/${customerId}/${sourceId}/search/${encodeURIComponent(
          searchTerm
        )}`
      );
    },
    [customerId, sourceId, history]
  );
  if (customerId && sourceId) {
    return (
      <Grid item>
        <SearchInput
          onSearchSubmit={handleSubmit}
          color={theme.palette.primary.main}
          placeholder="Search"
          clearSearchAriaLabel="Search by UUID or a National ID"
        />
      </Grid>
    );
  }
  return null;
}
