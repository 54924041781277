import IconButton from "@mui/material/IconButton";
import {
  mdiCheckboxBlankOutline,
  mdiCheckboxIntermediate,
  mdiCheckboxMarked,
  mdiCheckboxOutline,
  mdiCloseBox,
  mdiCloseBoxOutline,
} from "@mdi/js";
import { Icon } from "@mdi/react";
import { MouseEvent, ReactElement, useCallback } from "react";
import { DenormalizedChoiceInfo, PermissionChoice } from "./choices";

export interface PermissionCheckboxProps {
  choiceInfo: DenormalizedChoiceInfo;
  onChoose: (newChoice: undefined | PermissionChoice) => void;
}

export default function PermissionCheckbox({
  choiceInfo,
  onChoose,
}: PermissionCheckboxProps): ReactElement {
  let color: string = "gray";
  if (choiceInfo.choice === PermissionChoice.Include) {
    color = "green";
  } else if (choiceInfo.choice === PermissionChoice.Exclude) {
    color = "red";
  }

  const icon =
    !choiceInfo.choiceIsInhertied &&
    choiceInfo.choice === PermissionChoice.Include
      ? mdiCheckboxMarked
      : !choiceInfo.choiceIsInhertied &&
        choiceInfo.choice === PermissionChoice.Exclude
      ? mdiCloseBox
      : choiceInfo.choiceIsInhertied &&
        choiceInfo.choice === PermissionChoice.Include
      ? mdiCheckboxOutline
      : choiceInfo.choiceIsInhertied &&
        choiceInfo.choice === PermissionChoice.Exclude
      ? mdiCloseBoxOutline
      : choiceInfo.choice === undefined && choiceInfo.subtreeContainsChoices
      ? mdiCheckboxIntermediate
      : mdiCheckboxBlankOutline;

  let newChoiceAfterClick: undefined | PermissionChoice;
  if (choiceInfo.choiceIsInhertied || choiceInfo.choice === undefined) {
    newChoiceAfterClick = PermissionChoice.Include;
  } else if (choiceInfo.choice === PermissionChoice.Include) {
    newChoiceAfterClick = PermissionChoice.Exclude;
  }

  const handleClick = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      onChoose(newChoiceAfterClick);
    },
    [newChoiceAfterClick, onChoose]
  );

  return (
    <IconButton
      style={{
        padding: "6px",
        margin: "-2px 4px -2px 0",
      }}
      aria-label={
        newChoiceAfterClick === PermissionChoice.Exclude
          ? "Restrict access"
          : "Enable access"
      }
      onClick={handleClick}
    >
      <Icon path={icon} size="24px" color={color} />
    </IconButton>
  );
}
