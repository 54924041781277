import { MouseEvent, ReactElement, useState } from "react";
import Grid from "@mui/material/Grid";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { groupBy } from "lodash";
import { useHistory, useRouteMatch } from "react-router-dom";
import { SourceInfo, useSourceInfoList } from "../common/sourceInfo";
import { HeaderButton } from "./HeaderButton";

export interface OrganisationSelectProps {
  role?: "VENDOR_ADMIN" | "ADMIN";
}

export default function OrganisationSelect({
  role,
}: OrganisationSelectProps): ReactElement {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const history = useHistory();
  const match = useRouteMatch<{
    basePath: string;
    customerId?: string;
    sourceId?: string;
  }>("/:basePath/:customerId?/:sourceId?");
  const { sourceId, customerId } = match?.params ?? {};
  const { sourceInfo, sourceInfoList } = useSourceInfoList(role, customerId);

  let selectedCustomer: SourceInfo["organisation"] | undefined;
  let selectedSource: SourceInfo | undefined;

  if (sourceId) {
    selectedSource = sourceInfo.get(sourceId);
    selectedCustomer = selectedSource?.organisation;
  } else if (customerId) {
    selectedCustomer = sourceInfoList?.find(
      (v) => v.organisation?.id === customerId
    )?.organisation;
  }

  const handleMenuOpen = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const select = (orgId: string, id?: string) => {
    if (id) {
      history.push(`/${match?.params.basePath}/${orgId}/${id}`);
    } else {
      history.push(`/${match?.params.basePath}/${orgId}`);
    }

    handleMenuClose();
  };

  const byOrg = groupBy(sourceInfoList, (si) => si.organisation?.id ?? "");
  const menuItems = Object.entries(byOrg).flatMap((org) =>
    org[1].map((source) => (
      <MenuItem key={source.id} onClick={() => select(org[0], source.id)}>
        {source.organisation?.name.concat(" / ")}
        {source.description ?? source.id}
      </MenuItem>
    ))
  );

  return (
    <Grid item>
      <HeaderButton
        aria-label="Select Organisation"
        aria-controls="menu-org"
        aria-haspopup="true"
        onClick={handleMenuOpen}
      >
        {selectedSource
          ? `${selectedSource.organisation?.name.concat(" / ") ?? ""}${
              selectedSource.description ?? selectedSource.id
            }`
          : selectedCustomer?.name ?? "Select organisation"}
        <ArrowDropDownIcon color="inherit" />
      </HeaderButton>
      <Menu
        id="menu-org"
        anchorEl={anchorEl}
        style={{ marginTop: 40 }}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        {menuItems}
      </Menu>
    </Grid>
  );
}
