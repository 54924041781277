import { ReactElement } from "react";
import { StylistHeader } from "common/stylist/header/StylistHeader";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { HeaderBreadcrumbs } from "../common/CustomizedBreadcrumbs";
import { UserMenu } from "../common/generic";
import OrganisationSelect from "common/OrganisationSelect";

export interface PermissionsHeaderProps {
  typeName?: string;
  itemName?: string;
}

export default function PermissionsHeader({
  typeName,
  itemName,
}: PermissionsHeaderProps): ReactElement {
  const paths = [];

  if (typeName) {
    paths.push({ name: typeName });
  }

  if (itemName) {
    paths.push({ name: itemName });
  }

  return (
    <Box pb={8}>
      <StylistHeader>
        <Box py={1} width="100%">
          <Grid justifyContent="flex-end" spacing={1} container>
            <Grid sm={12} md={"auto"} style={{ marginRight: "auto" }} item>
              <HeaderBreadcrumbs name={"Permissions"} paths={paths} />
            </Grid>
            <OrganisationSelect role="ADMIN" />
            <UserMenu />
          </Grid>
        </Box>
      </StylistHeader>
    </Box>
  );
}
