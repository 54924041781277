import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import { debounce } from "lodash";
import {
  ChangeEvent,
  Dispatch,
  ReactElement,
  SetStateAction,
  useCallback,
  useMemo,
} from "react";
import { FilterData } from "../explorer/OrganisationTree";
import { Icon } from "@mdi/react";
import { mdiFilter } from "@mdi/js";

export interface FilterInputProps {
  filters: FilterData;
  field: keyof FilterData;
  title: string;
  setFilters: Dispatch<SetStateAction<FilterData>>;
}

export default function FilterInput({
  filters,
  title,
  field,
  setFilters,
}: FilterInputProps): ReactElement {
  const setFilterDeb = useMemo(
    () => debounce(setFilters, 200, { maxWait: 800 }),
    [setFilters]
  );

  const cb = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const value = event.target?.value;
      setFilterDeb((old) => ({ ...old, [field]: value }));
      return () => {
        setFilterDeb.cancel();
      };
    },
    [setFilterDeb, field]
  );

  return (
    <TextField
      type="search"
      defaultValue={filters[field] ?? ""}
      onChange={cb}
      InputProps={{
        endAdornment: (
          <InputAdornment style={{ marginRight: "8px" }} position="end">
            <Tooltip title={title}>
              <Icon path={mdiFilter} size={1} color="gray" />
            </Tooltip>
          </InputAdornment>
        ),
      }}
    />
  );
}
