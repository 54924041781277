import { ReactElement } from "react";
import { useRouteMatch } from "react-router-dom";
import { useSourceId } from "../common";
import { RowsComponent } from "../common/rows";
import { ApiCall, Method, Person, Target } from "../common/types";
import { PersonsListComponent } from "./PersonsListComponent";

export default function PersonSearchResults(): ReactElement {
  const match = useRouteMatch<{
    customerId: string;
    sourceId: string;
    searchTerm: string;
  }>("/explorer/:customerId/:sourceId/name-search/:searchTerm");
  const sourceId = useSourceId();
  if (match?.params.searchTerm) {
    const ac: ApiCall = {
      sourceId,
      target: Target.persons,
      method: Method.GET,
      nameContains: match?.params.searchTerm.split(" "),
    };

    return <RowsComponent<Person> apiCall={ac} view={PersonsListComponent} />;
  }
  return <div>You need to enter a search string.</div>;
}
