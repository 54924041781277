import Chip from "@mui/material/Chip";
import useTheme from "@mui/material/styles/useTheme";
import { ReactElement } from "react";
import { DataPackage, useSourceInfo } from "../common/sourceInfo";

export interface ClientAccessChipListProps {
  clientId: string;
  sourceId: string;
}

export default function ClientAccessChipList({
  clientId,
  sourceId,
}: ClientAccessChipListProps): ReactElement {
  const { sourceInfo } = useSourceInfo();
  const theme = useTheme();

  const packets: DataPackage[] =
    sourceInfo.get(sourceId)?.clients.find(({ id }) => id === clientId)
      ?.dataPackets ?? [];
  return (
    <>
      {packets.map(({ id, name }) => (
        <Chip
          style={{
            marginRight: theme.spacing(1),
            marginBottom: theme.spacing(1),
            backgroundColor: theme.palette.primary.light,
          }}
          label={name ?? id}
          key={id}
        />
      ))}
    </>
  );
}
