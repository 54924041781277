import { CSSProperties } from "react";
import { validDateFilter } from "../common";

const redColorStyle = { color: "red" };
const greenColorStyle = { color: "green" };

export default function rowValidDateFilterStyle(
  _data: unknown,
  rowData:
    | {
        startDate?: string | null;
        endDate?: string | null;
      }
    | undefined
    | null
): CSSProperties {
  return rowData && validDateFilter(rowData) ? greenColorStyle : redColorStyle;
}
