import Button, { type ButtonProps } from "@mui/material/Button";
import Collapse from "@mui/material/Collapse";
import Popover from "@mui/material/Popover";
import type { SvgIconProps } from "@mui/material/SvgIcon";
import type { Theme } from "@mui/material/styles";
import useTheme from "@mui/material/styles/useTheme";
import Typography from "@mui/material/Typography";
import styled from "@mui/material/styles/styled";
import { useContext, ReactElement, useCallback, useState } from "react";
import { useIsDrawerOpen, DrawerItemContext } from "./drawerContext";
import { Arrow } from "./StylistDrawer";

const FloatingMenu = styled(Popover)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  borderRadius: "8px",
  color: theme.palette.primary.contrastText,
  marginLeft: theme.spacing(2),
  padding: theme.spacing(1, 1, 0, 1),
}));

const DrawerItem = styled(Button)(
  ({
    active,
    theme,
  }: ButtonProps & { active?: boolean } & { theme: Theme }) => ({
    color: theme.palette.text.primary,
    backgroundColor: "transparent",
    justifyContent: "start",
    minWidth: 24,
    minHeight: 40,
    height: "auto",
    width: "100%",
    padding: theme.spacing(1),
    margin: theme.spacing(1, 0),
    borderRadius: "0",
    clipPath:
      "polygon(0 0, calc(100% - 20px) 0%, calc(100% - 1px) calc(50% - 3px), 100% 50%,calc(100% - 1px) calc(50% + 3px), calc(100% - 20px) 100%, 0 100%)",
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
    },

    ...(active
      ? {
          backgroundColor: theme.palette.primary.light,
          color: theme.palette.primary.dark,
        }
      : {}),
  })
);

const DrawerItemText = styled(Typography)(({ theme }) => ({
  textTransform: "none",
  padding: theme.spacing(0, 1),
  fontWeight: 400,
  fontSize: 16,
}));

const DrawerItemContainer = styled("div")(({ theme }) => ({
  color: "inherit",
  padding: 0,
  paddingRight: 4,
  margin: theme.spacing(0, -1),
}));

export type StylistDrawerItemProps = {
  Icon?: React.FC<SvgIconProps>;
  label?: string;
  expanded?: boolean;
  active?: boolean;
} & ButtonProps;

export function StylistDrawerItem({
  Icon,
  label,
  expanded,
  active,
  children,
  ...rest
}: StylistDrawerItemProps): ReactElement {
  const theme = useTheme();
  const expandedInherited = useIsDrawerOpen();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const htmlId = label?.replace(/[^a-z ]/i, "_");
  const parentContext = useContext(DrawerItemContext);
  const [open, setOpen] = useState<boolean | undefined>(undefined);
  const isExpanded = expanded !== undefined ? expanded : expandedInherited.open;
  const hasChildren = Boolean(children);

  const handlePopoverOpen = useCallback(
    (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
      if (hasChildren && !isExpanded && active) {
        setAnchorEl(event.currentTarget);
      }
    },
    [hasChildren, isExpanded, active]
  );
  const handlePopoverClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  // If open has not been set explicit, set it to the same value as active.
  const isOpen = isExpanded
    ? open === undefined
      ? active
        ? active
        : false
      : open
    : false;

  let submenu: ReactElement | null = null;
  if (hasChildren) {
    if (isExpanded && parentContext.level === 0) {
      submenu = (
        <Collapse in={isOpen} timeout="auto" unmountOnExit>
          {children}
        </Collapse>
      );
    } else {
      submenu = (
        <FloatingMenu
          id={htmlId}
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          onClose={handlePopoverClose}
        >
          <Typography style={{ padding: "0 8px" }} variant="overline">
            {label}
          </Typography>
          {children}
        </FloatingMenu>
      );
    }
  }

  const drawerItemContainerSubmenuActive = isExpanded && hasChildren && active;
  return (
    <DrawerItemContainer
      sx={{
        paddingRight: isExpanded ? theme.spacing(1) : undefined,

        backgroundColor: drawerItemContainerSubmenuActive
          ? "rgba(0,106,131,0.5)"
          : undefined,
      }}
    >
      <DrawerItem
        sx={{
          paddingLeft: isExpanded
            ? theme.spacing(4 + parentContext.level)
            : theme.spacing(2),

          backgroundColor: active ? theme.palette.primary.light : undefined,
          color: active ? theme.palette.primary.dark : undefined,
        }}
        aria-label={label}
        aria-owns={Boolean(anchorEl) ? htmlId : undefined}
        aria-haspopup={hasChildren && isExpanded}
        onMouseEnter={handlePopoverOpen}
        {...rest}
      >
        {hasChildren && isExpanded ? (
          <Arrow
            style={{
              color: theme.palette.primary.main,
              marginTop: isOpen ? theme.spacing(-1) : 0,
            }}
            onClick={() => setOpen((prev) => !prev)}
            dir={isOpen ? "down" : "right"}
          />
        ) : null}
        {Icon && parentContext.level === 0 && (
          <Icon style={{ height: 24, width: 24 }} color="inherit" />
        )}
        {isExpanded || parentContext.level !== 0 ? (
          <DrawerItemText variant="body1" color="inherit">
            {label}
          </DrawerItemText>
        ) : null}
      </DrawerItem>
      {Boolean(submenu) && (
        <DrawerItemContext.Provider
          value={{ level: parentContext.level + 1, open: isOpen }}
        >
          {submenu}
        </DrawerItemContext.Provider>
      )}
    </DrawerItemContainer>
  );
}
