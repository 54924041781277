import { FilterData } from "../explorer/OrganisationTree";
import { Organisation } from "./types";
import { validDateFilter } from ".";

export default function filterRow(
  org: Organisation,
  filters: FilterData
): boolean {
  if (
    filters.org &&
    !org.displayName.toLowerCase().includes(filters.org.toLowerCase())
  ) {
    return false;
  }

  if (
    filters.code &&
    !org.organisationCode?.toLowerCase().includes(filters.code.toLowerCase())
  ) {
    return false;
  }

  if (
    filters.schoolType?.length &&
    !org.schoolTypes?.some((type) => filters.schoolType?.includes(type))
  ) {
    return false;
  }

  if (
    filters.type &&
    !org.organisationType?.toLowerCase().includes(filters.type!.toLowerCase())
  ) {
    return false;
  }

  if (
    filters.schoolUnitCode &&
    !org.schoolUnitCode?.includes(filters.schoolUnitCode)
  ) {
    return false;
  }

  if (!filters.historical && !validDateFilter(org)) {
    return false;
  }

  return true;
}
