import { Person } from "./types";

export function personNamesInSortOrder({
  familyName,
  givenName,
  middleName,
}: Person): string {
  return `${middleName?.concat(" ") ?? ""}${familyName} ${givenName}`;
}

interface WithEmbeddedPerson {
  _embedded?: {
    person?: Person;
  };
}

export function compareEmbeddedPersonDisplayNames(
  rowA: WithEmbeddedPerson,
  rowB: WithEmbeddedPerson
) {
  const aName = rowA?._embedded?.person
    ? personNamesInSortOrder(rowA._embedded.person)
    : "";
  const bName = rowB?._embedded?.person
    ? personNamesInSortOrder(rowB._embedded.person)
    : "";
  return aName.localeCompare(bName);
}
