import { useAccessItems, useRolesSummary } from "common/sessionInfo";
import { StylistDrawer } from "common/stylist/drawer/StylistDrawer";
import { ReactElement } from "react";
import appSections from "./appSections";
import DrawerItem from "./DrawerItem";

export interface DrawerMenuProps {}

export default function DrawerMenu(_props: DrawerMenuProps): ReactElement {
  const accessItems = useAccessItems(true);
  const roles = useRolesSummary();

  if (accessItems && accessItems.length > 0) {
    return (
      <StylistDrawer title="SS12000:2020">
        {appSections.map(
          ({ availableTo, Icon, name, path }, index) =>
            accessItems.includes(path) && (
              <DrawerItem path={path} Icon={Icon} name={name} key={index} />
            )
        )}
      </StylistDrawer>
    );
  }
  return (
    <StylistDrawer title="SS12000:2020">
      {appSections.map(
        ({ availableTo, Icon, name, path }, index) =>
          availableTo.some((role) => roles?.[role]) && (
            <DrawerItem path={path} Icon={Icon} name={name} key={index} />
          )
      )}
    </StylistDrawer>
  );
}
