import { ReactElement } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { Link, useLocation, useParams } from "react-router-dom";
import { rowName, useSourceId, validDateFilter } from "../common";
import { RowData } from "../common/api";
import {
  DataBox,
  DataBoxBlock,
  DataBoxSection,
  ListGenericComponent,
  ObjectReferenceLink,
  PropertyItem,
  PropertyItemDateRange,
} from "../common/generic";
import {
  GenericListViewProps,
  RowComponent,
  RowsComponent,
} from "../common/rows";
import * as common from "../common/types";
import { SchoolTypeLookupDef } from "../common/types";
import { ActivitiesListComponent as ActivityListComponent } from "./activity";
import { GroupsListComponent } from "./group";
import ExplorerHeader from "./ExplorerHeader";
import { OrganisationTreeComponent } from "./OrganisationTree";
import { personNamesInSortOrder } from "common/personNames";
import DutiesListComponent from "./DutiesListComponent";
import rowValidDateFilterStyle from "./rowValidDateFilterStyle";
import { PlacementsListComponent } from "./PlacementsListComponent";

export interface OrganisationComponentProps
  extends RowData<common.Organisation> {}

export function OrganisationComponent({
  row: org,
}: OrganisationComponentProps): ReactElement {
  const { sourceId, customerId } = useParams<{
    customerId?: string;
    sourceId?: string;
  }>();
  const clientId = new URLSearchParams(useLocation().search).get("clientId");

  if (!sourceId) {
    return <div>No data loaded yet.</div>;
  }

  if (!org?.id) {
    return <Typography variant="h1">No organisation found</Typography>;
  }

  return (
    <Grid
      container
      spacing={3}
      alignItems="stretch"
      alignContent="stretch"
      style={{
        flexGrow: 1,
      }}
    >
      <DataBox data={org} title={org.displayName}>
        <DataBoxSection>
          <DataBoxBlock xs={3}>
            <PropertyItem title="Parent organisation">
              {org.parentOrganisation ? (
                <ObjectReferenceLink
                  kind={common.Target.organisations}
                  item={org.parentOrganisation}
                />
              ) : (
                <Link
                  to={`/explorer/${customerId}/${sourceId}/${
                    clientId ? `?clientId=${clientId}` : ""
                  }`}
                >
                  Top level
                </Link>
              )}
            </PropertyItem>
            <PropertyItem
              title="Organisation Number"
              value={org.organisationNumber}
            />
          </DataBoxBlock>
          <DataBoxBlock xs={3}>
            <PropertyItem
              title="Organisation type"
              value={org.organisationType}
            />
            <PropertyItem
              title="Organisation Code"
              value={org.organisationCode}
            />
          </DataBoxBlock>
          <DataBoxBlock xs={3}>
            <PropertyItem
              title="School types"
              value={org.schoolTypes?.join(", ")}
            />
            <PropertyItem
              title="Municipality Code"
              value={org.municipalityCode}
            />
          </DataBoxBlock>
          <DataBoxBlock xs={3}>
            <PropertyItem title="School Unit Code" value={org.schoolUnitCode} />
            <PropertyItemDateRange
              title="Valid"
              start={org.startDate}
              end={org.endDate}
            />
          </DataBoxBlock>
        </DataBoxSection>
        <DataBoxSection>
          <DataBoxBlock style={{ wordBreak: "break-word" }} xs={3}>
            <PropertyItem title="Phone" value={org.phoneNumber} />
            <PropertyItem title="Email" value={org.email} />
          </DataBoxBlock>
          {org.contactInfo && (
            <DataBoxBlock xs={3}>
              {org.contactInfo?.map((ci) => (
                <PropertyItem
                  title={`Contact info (${ci.infoType})`}
                  value={ci.info}
                />
              ))}
            </DataBoxBlock>
          )}
          <DataBoxBlock xs={3}>
            <PropertyItem title="Url" value={org.url} />
          </DataBoxBlock>
        </DataBoxSection>
      </DataBox>

      {org.organisationType !== "Skolenhet" ? (
        <RowsComponent<common.Organisation>
          apiCall={{
            sourceId,
            method: common.Method.GET,
            target: common.Target.organisations,
            parent: org.id,
          }}
          view={ListSubOrganisations}
          name="Organisation"
        />
      ) : null}

      <Grid spacing={3} item container>
        <Grid item xs={12} lg={6}>
          <RowsComponent<common.Group>
            apiCall={{
              sourceId,
              method: common.Method.GET,
              target: common.Target.groups,
              organisation: org.id,
            }}
            view={GroupsListComponent}
            name="Groups"
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <RowsComponent
            apiCall={{
              target: common.Target.activities,
              method: common.Method.GET,
              sourceId,
              organisation: org.id,
            }}
            view={ActivityListComponent}
            name="Activities"
          />
        </Grid>
      </Grid>

      <RowsComponent<common.DutyExpanded>
        apiCall={{
          expand: ["person"],
          method: common.Method.GET,
          organisation: org.id,
          sourceId,
          target: common.Target.duties,
        }}
        view={DutiesListComponent}
        name="Duties"
      />

      <RowsComponent<
        common.Person,
        common.Enrolment & {
          displayName: string;
          namesInSortOrder: string;
          personId: string;
        }
      >
        apiCall={{
          sourceId,
          method: common.Method.GET,
          target: common.Target.persons,
          "relationship.entity.type": "enrolment",
          "relationship.organisation": org.id,
        }}
        view={SchoolUnitsEnrolledPersonsListComponent}
        mapper={(data: common.Person[]) =>
          data
            .flatMap((person) => {
              const namesInSortOrder = personNamesInSortOrder(person);

              const displayName = rowName(person, "persons");

              return (
                person.enrolments
                  ?.filter((enr) => enr.enroledAt.id === org.id)
                  .map((enr, enrolmentIndex) => ({
                    ...enr,
                    displayName,
                    id: `${person.id}:${enrolmentIndex}`, // Rows need unique ids or things like sorting break
                    namesInSortOrder,
                    personId: person.id,
                  })) ?? []
              );
            })
            // This is the fallback order, used in case no column has been selected for sorting or if multiple cells are equal in the selected column
            .sort((enA, enB) => enA.startDate.localeCompare(enB.startDate))
        }
        name="Enrolled students"
      />

      <RowsComponent<common.Placement>
        apiCall={{
          sourceId,
          method: common.Method.GET,
          target: common.Target.placements,
          organisation: org.id,
        }}
        view={PlacementsListComponent}
        name="Placed children"
      />
    </Grid>
  );
}

function renderSchoolType(org: common.Organisation) {
  return org.schoolTypes?.join(", ") ?? "";
}
function renderDisplayName(org: common.Organisation) {
  return <ObjectReferenceLink item={org} kind={common.Target.organisations} />;
}

interface ListSubOrganisationsProps
  extends GenericListViewProps<common.Organisation> {}

function ListSubOrganisations(props: ListSubOrganisationsProps): ReactElement {
  return (
    <ListGenericComponent<common.Organisation>
      checkboxFilter={{
        label: "Only current elements",
        condition: validDateFilter,
        defaultValue: true,
      }}
      columns={[
        {
          title: "Name",
          field: "displayName",
          render: renderDisplayName,
        },
        { title: "Typ", field: "organisationType" },
        {
          title: "Utbildningstyp",
          field: "schoolTypes",
          render: renderSchoolType,
        },
        { title: "Organisationskod", field: "organisationCode" },
        { title: "Skolenhetskod", field: "schoolUnitCode" },
        { title: "Start", field: "startDate" },
        { title: "End", field: "endDate" },
      ]}
      {...props}
    />
  );
}

interface SchoolUnitsEnrolledPersonsListComponentProps
  extends GenericListViewProps<
    common.Enrolment & {
      displayName: string;
      namesInSortOrder: string;
      personId: string;
    }
  > {}

function SchoolUnitsEnrolledPersonsListComponent(
  props: SchoolUnitsEnrolledPersonsListComponentProps
): ReactElement {
  return (
    <ListGenericComponent<
      common.Enrolment & {
        displayName: string;
        namesInSortOrder: string;
        personId: string;
      }
    >
      checkboxFilter={{
        label: "Only current enrolments",
        condition: (enr) => validDateFilter(enr),
        defaultValue: true,
      }}
      columns={[
        {
          title: "Name",
          field: "displayName",
          defaultSort: "asc",
          render: (value) => (
            <ObjectReferenceLink
              idProperty="personId"
              item={value}
              kind={props.kind}
            />
          ),
          customSort: (a, b) =>
            a.namesInSortOrder.localeCompare(b.namesInSortOrder),
        },
        {
          title: "School Year",
          field: "schoolYear",
          type: "numeric",
        },
        {
          title: "Type",
          field: "schoolType",
          lookup: SchoolTypeLookupDef,
        },
        { title: "EducationCode", field: "educationCode" },
        {
          title: "Programme",
          field: "programme",
          render: (row) => row.programme?.displayName ?? "",
        },
        {
          title: "Start",
          field: "startDate",
          cellStyle: rowValidDateFilterStyle,
        },
        {
          title: "End",
          field: "endDate",
          cellStyle: rowValidDateFilterStyle,
        },
      ]}
      {...props}
    />
  );
}

export interface OrganisationProps {
  id?: string;
}

export function Organisation(props: OrganisationProps): ReactElement | null {
  const { sourceId, customerId, id } = useParams<{
    id?: string;
    customerId?: string;
    sourceId?: string;
  }>();
  if (!sourceId) {
    return null;
  }

  return (
    <>
      <ExplorerHeader
        typeName="Organisation"
        typeLink={`${customerId}/${sourceId}`}
      ></ExplorerHeader>
      <RowComponent<common.Organisation>
        apiCall={{
          sourceId,
          target: common.Target.organisations,
          method: common.Method.GET,
          path: props.id ?? id,
        }}
        view={OrganisationComponent}
      />
    </>
  );
}

export function Organisations(): ReactElement | null {
  const sourceId = useSourceId();

  if (!sourceId) {
    return null;
  }

  const ac: common.ApiCall = {
    sourceId,
    target: common.Target.organisations,
    method: common.Method.GET,
  };
  return (
    <RowsComponent<common.Organisation>
      apiCall={ac}
      view={OrganisationTreeComponent}
    />
  );
}
