import CssBaseline from "@mui/material/CssBaseline";
import { ReactElement, ReactNode } from "react";
import { StylistAppContent } from "./StylistAppContent";
import { StylistThemeProvider } from "./StylistThemeProvider";

export function StylistApp({
  children,
}: {
  children: ReactNode;
}): ReactElement {
  return (
    <StylistThemeProvider>
      <CssBaseline />
      <StylistAppContent>{children}</StylistAppContent>
    </StylistThemeProvider>
  );
}
