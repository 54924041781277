import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link, { LinkProps } from "@mui/material/Link";
import type { Theme } from "@mui/material/styles";
import { Link as RouterLink, useRouteMatch } from "react-router-dom";
import { useSourceInfoList } from "./sourceInfo";
import useMediaQuery from "@mui/material/useMediaQuery";

export interface BreadcrumbsProps {
  name?: string;
  paths?: { name: string; link?: string }[];
}
interface LinkRouterProps extends LinkProps {
  to: string;
  replace?: boolean;
}

const LinkRouter = (props: LinkRouterProps) => (
  <Link
    {...props}
    component={RouterLink as any}
    sx={{
      borderBottom: "none",
      fontFamily: (theme) => theme.typography.h6.fontFamily,
      fontSize: (theme) => theme.typography.h6.fontSize,
      fontWeight: (theme) => theme.typography.h6.fontWeight,
      lineHeight: "32px",
    }}
  />
);

export function SourceBasedBreadcrumbs({ paths, name }: BreadcrumbsProps) {
  const route = useRouteMatch<{
    baseRoute: string;
    customerId?: string;
    sourceId?: string;
  }>("/:baseRoute/:customerId?/:sourceId?");
  const { customerId, sourceId } = route?.params ?? { baseRoute: "explorer" };
  const { sourceInfo, sourceInfoList } = useSourceInfoList();

  const source = sourceId ? sourceInfo.get(sourceId) : undefined;
  const organisation =
    source?.organisation ??
    (customerId
      ? sourceInfoList?.find((v) => v.organisation?.id === customerId)
          ?.organisation
      : undefined);

  const renderPaths = [
    ...(organisation
      ? [{ name: organisation.name, link: organisation.id }]
      : []),
    ...(source
      ? [
          {
            name: `${source.id} (${source.description})`,
            link: `${source.organisation?.id}/${source.id}`,
          },
        ]
      : []),
    ...(paths
      ? paths.map((path) => ({
          ...path,
          link: path.link ?? customerId?.concat("/" + (sourceId ?? "")),
        }))
      : []),
  ];

  return <HeaderBreadcrumbs paths={renderPaths} name={name} />;
}

export function HeaderBreadcrumbs({ paths, name }: BreadcrumbsProps) {
  const route = useRouteMatch<{
    baseRoute: string;
    customerId?: string;
    sourceId?: string;
  }>("/:baseRoute?");
  const { baseRoute } = route?.params ?? { baseRoute: "explorer" };
  const renderPaths = [...(name ? [{ name }] : []), ...(paths ?? [])];
  const medium = useMediaQuery((theme: Theme) => theme.breakpoints.up("md"));

  return (
    <Breadcrumbs maxItems={medium ? 5 : 2} aria-label="breadcrumb">
      {renderPaths.map((path, i) => (
        <LinkRouter
          key={i}
          color={i + 1 === renderPaths.length ? "primary" : "inherit"}
          to={`/${baseRoute}/${path.link ?? ""}`}
        >
          {path.name}
        </LinkRouter>
      ))}
    </Breadcrumbs>
  );
}
