import { MouseEvent, ReactElement, useState } from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Option } from "./types";

export interface OrgOrVendorSelectorPresentationProps {
  className?: string;
  onChange: (option: Option) => void;
  options?: Option[];
  selectedOption?: Option;
}

export default function OrgOrVendorSelectorPresentation({
  className,
  onChange,
  options,
  selectedOption,
}: OrgOrVendorSelectorPresentationProps): ReactElement {
  const [anchorEl, setAnchorEl] = useState<HTMLElement>();

  const handleClose = () => setAnchorEl(undefined);

  return (
    <div className={className}>
      <Button
        color="inherit"
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={(ev: MouseEvent<HTMLElement>) => setAnchorEl(ev.currentTarget)}
      >
        {selectedOption?.name ?? "Select an organisation or vendor"}
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={!!anchorEl}
        onClose={handleClose}
      >
        {options?.map((option) => (
          <MenuItem
            key={option.type + option.id}
            onClick={() => {
              onChange(option);
              handleClose();
            }}
          >
            {option.type}: {option.name}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}
