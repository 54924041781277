export enum OptionType {
  Organisation = "Organisation",
  Vendor = "Vendor",
}

export type Option = {
  name: string;
  id: string;
  type: OptionType;
};
