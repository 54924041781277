import { FC, ReactElement, useCallback } from "react";
import type { SvgIconProps } from "@mui/material/SvgIcon";
import { StylistDrawerItem } from "../common/stylist/drawer/StylistDrawerItem";
import { useHistory, useRouteMatch } from "react-router-dom";

export interface DrawerItemProps {
  path: string;
  Icon: FC<SvgIconProps>;
  name: string;
}

export default function DrawerItem({
  path,
  Icon,
  name,
}: DrawerItemProps): ReactElement {
  const match = useRouteMatch(path);
  const history = useHistory();
  const onClick = useCallback(() => {
    history.push(path);
  }, [path, history]);
  return (
    <StylistDrawerItem
      onClick={onClick}
      label={name}
      active={Boolean(match)}
      Icon={Icon}
    />
  );
}
