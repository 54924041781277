import Drawer from "@mui/material/Drawer";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import { type ListProps } from "@mui/material/List";
import useTheme from "@mui/material/styles/useTheme";
import styled from "@mui/material/styles/styled";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import MenuIcon from "@mui/icons-material/Menu";
import {
  CSSProperties,
  useContext,
  useCallback,
  ReactNode,
  ReactElement,
} from "react";
import { DrawerOpenContext } from "./drawerContext";
import { drawerWidth } from "../stylistTheme";

const StyledDrawer = styled(Drawer)(({ open, theme }) => ({
  width: drawerWidth,
  flexShrink: 0,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  whiteSpace: "nowrap",
  ...(open
    ? {}
    : {
        overflowX: "hidden",
        width: `calc(${theme.spacing(7)} + 1px)`,
        [theme.breakpoints.up("sm")]: {
          width: `calc(${theme.spacing(7)} + 1px)`,
        },
      }),
}));

const DrawerMenuButton = styled(IconButton)(() => ({
  margin: "12px 0",
  padding: 0,
}));

const DrawerNavigation = styled(
  ({ open, ...props }: ListProps & { open: boolean }) => (
    <List {...props} component="nav" />
  )
)(({ open, theme }) => ({
  display: "flex",
  alignItems: "stretch",
  flexDirection: "column",
  padding: theme.spacing(0, 1),
  width: drawerWidth,
  whiteSpace: "nowrap",

  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  ...(open
    ? {}
    : {
        overflowX: "hidden",
        width: `calc(${theme.spacing(7)} + 1px)`,
        [theme.breakpoints.up("sm")]: {
          width: `calc(${theme.spacing(7)} + 1px)`,
        },
      }),
}));

const DrawerTitle = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(1),
  borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
  minHeight: 65,
}));

const ArrowBase = styled("div")(({ theme }) => ({
  display: "inline-block",
  borderTopColor: "transparent",
  borderBottomColor: "transparent",
  borderLeftColor: "transparent",
  borderRightColor: "transparent",
  borderStyle: "solid",
  width: 0,
  height: 0,
}));

const ArrowCanvas = styled("div")(({ theme }) => ({
  width: 24,
  marginLeft: -24,
  textAlign: "center",
}));

export interface StylistDrawerProps {
  children: ReactNode;
  title: string;
}

export function StylistDrawer({
  title,
  children,
}: StylistDrawerProps): ReactElement {
  const theme = useTheme();
  const openContext = useContext(DrawerOpenContext);
  const { open, setOpen } = openContext;
  const toggleDrawer = useCallback(() => {
    if (setOpen) {
      setOpen((old) => !old);
    }
  }, [setOpen]);
  return (
    <StyledDrawer
      variant="permanent"
      open={open}
      transitionDuration={{ appear: 500, enter: 500, exit: 500 }}
    >
      <DrawerTitle
        wrap="nowrap"
        justifyContent="space-between"
        alignItems="center"
        container
      >
        {openContext.open ? (
          <Grid item>
            <Typography
              variant="h5"
              color="inherit"
              style={{ overflow: "hidden", marginLeft: 8 }}
            >
              {title}
            </Typography>
          </Grid>
        ) : null}
        <Grid
          style={{
            flexGrow: 0,
            height: 48,
            marginLeft: "auto",
            marginRight: open ? 0 : "auto",
          }}
          item
        >
          {!open ? (
            <DrawerMenuButton
              color="primary"
              aria-label="open drawer"
              onClick={toggleDrawer}
              edge="start"
            >
              <MenuIcon />
            </DrawerMenuButton>
          ) : (
            <DrawerMenuButton
              color="primary"
              aria-label="close drawer"
              onClick={toggleDrawer}
              edge="start"
            >
              {theme.direction === "rtl" ? (
                <ChevronRightIcon />
              ) : (
                <ChevronLeftIcon />
              )}
            </DrawerMenuButton>
          )}
        </Grid>
      </DrawerTitle>
      <DrawerNavigation open={open} role="navigate">
        {children}
      </DrawerNavigation>
    </StyledDrawer>
  );
}

export const Arrow: React.FC<{
  dir: "up" | "right" | "down" | "left";
  style?: CSSProperties;
  size?: number;
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}> = ({ dir, size = 5, onClick, style: givenStyles }) => {
  const style: CSSProperties =
    dir === "up"
      ? { borderTopWidth: 0, borderBottomColor: "inherit" }
      : dir === "down"
      ? { borderBottomWidth: 0, borderTopColor: "inherit" }
      : dir === "left"
      ? { borderLeftWidth: 0, borderRightColor: "inherit" }
      : { borderRightWidth: 0, borderLeftColor: "inherit" };

  return (
    <ArrowCanvas onClick={onClick} sx={givenStyles}>
      <ArrowBase
        sx={{
          borderTopWidth: size,
          borderBottomWidth: size,
          borderLeftWidth: size,
          borderRightWidth: size,
          ...style,
        }}
      />
    </ArrowCanvas>
  );
};
