import styled from "@mui/material/styles/styled";
import TableRow from "@mui/material/TableRow";

export const TableHeadTextRow = styled(TableRow)(({ theme }) => ({
  "& td,th": {
    paddingLeft: 0,
    color: theme.palette.primary.main,
    textTransform: "uppercase",
    paddingBottom: 0,
    fontSize: "12px",
    fontWeight: "normal",
    minWidth: 150,
    height: theme.spacing(3),
  },
}));

export const TableHeadFilterRow = styled(TableRow)(({ theme }) => ({
  "& td,th": {
    paddingTop: 0,
    paddingLeft: 0,
    paddingRight: theme.spacing(1),
    paddingBottom: theme.spacing(3),
    borderBottomWidth: 1,
    borderBottomStyle: "solid",
    borderBottomColor: theme.palette.divider,
  },
}));

export const TableClickableRow = styled(TableRow)(({ theme }) => ({
  cursor: "pointer",
  "&:hover td": {
    backgroundColor: "rgba(0,0,0,0.05)",
  },
}));
