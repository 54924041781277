import { Column } from "@material-table/core";
import { ReactElement, SyntheticEvent, useCallback, useMemo } from "react";
import { Redirect, useHistory, useParams, useRouteMatch } from "react-router";
import { ListGenericComponent, TimePassedView } from "../common/generic";
import { SourceInfo, useSourceInfoList } from "./sourceInfo";

export interface SelectCustomerSourceListProps {
  role?: "ADMIN" | "VENDOR_ADMIN";
}

export default function SelectCustomerSourceList({
  role,
}: SelectCustomerSourceListProps): ReactElement {
  const match = useRouteMatch<{ type: string }>("/:type");
  const { customerId } = useParams<{ customerId?: string }>();
  const { sourceInfoList: sources } = useSourceInfoList(role, customerId);

  if (sources?.length === 1) {
    return (
      <Redirect
        to={`/${match?.params.type}/${sources[0].organisation?.id}/${sources[0].id}`}
      />
    );
  }

  return <SourceList sources={sources} baseView={match?.params.type} />;
}

const columns: Column<SourceListEntry>[] = [
  {
    title: "Organisation",
    field: "organisation",
  },
  {
    title: "Source ID",
    field: "sourceId",
  },
  {
    title: "Source",
    field: "description",
  },
  {
    title: "Version",
    field: "version",
    render: (row) =>
      row?.version ?? <span style={{ color: "red" }}>OFFLINE</span>,
  },
  {
    title: "Last updated",
    field: "lastUpdated",
    render: (row) =>
      row.lastUpdated ? <TimePassedView date={row.lastUpdated} /> : null,
  },
];

const tableOptions = {
  pageSizeOptions: [30, 50, 100],
  pageSize: 100,
};

interface SourceListEntry {
  organisation: string | undefined;
  organisationId: string | undefined;
  sourceId: string;
  description: string;
  version: string | undefined;
  lastUpdated: string | undefined;
}

interface SourceListProps {
  sources?: SourceInfo[];
  baseView?: string;
}

function SourceList({
  sources,
  baseView = "explorer",
}: SourceListProps): ReactElement {
  const history = useHistory();

  const clickHandler = useCallback(
    (_event?: SyntheticEvent, item?: SourceListEntry) => {
      if (item) {
        history.push(`/${baseView}/${item.organisationId}/${item.sourceId}`);
      }
    },
    [baseView, history]
  );

  const rowData = useMemo(
    () =>
      sources?.map(({ id, description, organisation, datasourceInfo }) => ({
        organisation: organisation?.name,
        organisationId: organisation?.id,
        sourceId: id,
        description,
        version: datasourceInfo?.dataFormatVersion,
        lastUpdated: datasourceInfo?.time,
      })),
    [sources]
  );

  return (
    <ListGenericComponent
      title="Available datasources"
      isLoading={sources === undefined}
      onRowClick={clickHandler}
      columns={columns}
      data={rowData}
      options={tableOptions}
    />
  );
}
