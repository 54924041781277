import { ReactElement } from "react";
import { Route, Switch, useRouteMatch } from "react-router";
import SelectCustomerSourceList from "common/SelectCustomerSourceList";
import { Activities } from "./activity";
import { Duties } from "./duty";
import { Grades } from "./grade";
import { Groups } from "./group";
import { Organisation, Organisations } from "./organisation";
import { Persons } from "./person";
import { Studyplans } from "./studyplan";
import { Syllabi } from "./syllabus";
import Programmes from "./Programmes";
import ExplorerHeader from "./ExplorerHeader";
import PersonSearchResults from "./PersonSearchResults";
import SearchResults from "./SearchResults";

export default function ExplorerContent(): ReactElement {
  const match = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${match.path}/:customerId?`}>
        <ExplorerHeader />
        <SelectCustomerSourceList />
      </Route>
      <Route path={`${match.path}/:customerId/:sourceId`}>
        <Switch>
          <Route path={`${match.path}/:customerId/:sourceId/activities`}>
            <Activities />
          </Route>
          <Route path={`${match.path}/:customerId/:sourceId/grades`}>
            <Grades />
          </Route>
          <Route path={`${match.path}/:customerId/:sourceId/persons`}>
            <Persons />
          </Route>
          <Route path={`${match.path}/:customerId/:sourceId/duties`}>
            <Duties />
          </Route>
          <Route path={`${match.path}/:customerId/:sourceId/groups`}>
            <Groups />
          </Route>
          <Route path={`${match.path}/:customerId/:sourceId/syllabuses`}>
            <Syllabi />
          </Route>
          <Route path={`${match.path}/:customerId/:sourceId/studyplans`}>
            <Studyplans />
          </Route>
          <Route path={`${match.path}/:customerId/:sourceId/programmes`}>
            <Programmes />
          </Route>
          <Route path={`${match.path}/:customerId/:sourceId/organisations/:id`}>
            <Organisation />
          </Route>
          <Route
            path={`${match.path}/:customerId/:sourceId/search/:searchTerm`}
          >
            <ExplorerHeader typeName="Search results"></ExplorerHeader>
            <SearchResults />
          </Route>
          <Route
            path={`${match.path}/:customerId/:sourceId/name-search/:searchTerm`}
          >
            <ExplorerHeader typeName="Search results"></ExplorerHeader>
            <PersonSearchResults />
          </Route>
          <Route path={`${match.path}/:customerId/:sourceId`}>
            <ExplorerHeader typeName="Organisations"></ExplorerHeader>
            <Organisations />
          </Route>
        </Switch>
      </Route>
      <Route path="/:target">Page not found</Route>
    </Switch>
  );
}
