import { useMutation } from "@apollo/client";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import { ReactElement, useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import FilterInput from "common/FilterInput";
import { DataBoxSection, PropertyItem } from "common/generic";
import { GenericListViewProps } from "common/rows";
import { useIsSuperadmin } from "common/sessionInfo";
import { TableClickableRow, TableHeadTextRow } from "common/tableComponents";
import * as common from "common/types";
import { FilterData, SchoolTypeSelect } from "explorer/OrganisationTree";
import {
  PermissionMode,
  SET_CLIENT_SOURCE_ACCESS_MUTATION,
  useAccessQuery,
} from "./api";
import {
  accessToChoices,
  choicesToAccess,
  denormalizeChoices,
  PermissionChoice,
} from "./choices";
import ErrorMessage from "./ErrorMessage";
import useAuditDataFileDownloads from "./useAuditDataFileDownloads";
import RowAndChildren from "./RowAndChildren";
import { constructNodes, OrgNode } from "./orgNodes";

export interface ListOrganisationsComponentProps
  extends GenericListViewProps<common.Organisation> {}

export default function ListOrganisationsComponent({
  data: organisations,
  isLoading: organisationsLoading,
}: ListOrganisationsComponentProps): ReactElement {
  const { clientId, sourceId } = useParams<{
    clientId: string;
    sourceId: string;
  }>();
  const [permissionMode, setPermissionMode] =
    useState<PermissionMode>("NORMAL");
  const [dirty, setDirty] = useState(false);
  const [filters, setFilters] = useState<FilterData>({ historical: true });
  const [saveAccessMutation] = useMutation(SET_CLIENT_SOURCE_ACCESS_MUTATION);
  const [includeAliasPersons, setIncludeAliasPersons] = useState(false);
  const [explicitChoices, setExplicitChoices] = useState(
    new Map<string, PermissionChoice>()
  );

  const { data, loading, error, refetch } = useAccessQuery(clientId, sourceId);

  useEffect(() => {
    if (data?.clientSourceAccess) {
      setExplicitChoices(accessToChoices(data.clientSourceAccess));
      setPermissionMode(data.clientSourceAccess.permissionMode);
      setIncludeAliasPersons(data.clientSourceAccess.includeAliasPersons);
    }
  }, [data]);

  const downloadAuditDataFile = useAuditDataFileDownloads(
    sourceId,
    clientId,
    organisations
  );

  const isSuperadmin = useIsSuperadmin();

  const { topNodes, orphans } = useMemo(
    () => constructNodes(organisations),
    [organisations]
  );

  const denormalizedChoices = useMemo(
    () => denormalizeChoices(explicitChoices, topNodes, orphans),
    [explicitChoices, topNodes, orphans]
  );

  const setChoiceForNode = useCallback(
    (node: OrgNode, choice: PermissionChoice | undefined) => {
      const newExplicitChoices = new Map(explicitChoices);
      if (choice === undefined) {
        newExplicitChoices.delete(node.organisation.id);
      } else {
        newExplicitChoices.set(node.organisation.id, choice);
      }
      setExplicitChoices(newExplicitChoices);
      setDirty(true);
    },
    [explicitChoices]
  );

  const getChoiceInfoForNode = useCallback(
    (node: OrgNode) => {
      const result = denormalizedChoices.get(node);
      if (!result) {
        throw new Error("Couldn't find OrgNode in denormalizedChoices");
      }
      return result;
    },
    [denormalizedChoices]
  );

  const handleSaveButtonClick = useCallback(() => {
    const access = choicesToAccess(explicitChoices);
    saveAccessMutation({
      variables: {
        input: {
          clientId,
          sourceId,
          ...access,
          permissionMode,
          includeAliasPersons,
        },
      },
    })
      .then(() => {
        refetch();
        setDirty(false);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [
    clientId,
    explicitChoices,
    includeAliasPersons,
    permissionMode,
    refetch,
    saveAccessMutation,
    sourceId,
  ]);

  if (organisationsLoading || loading) {
    return <Box p={3}>Loading...</Box>;
  }

  if (error) {
    return <ErrorMessage>{error.message}</ErrorMessage>;
  }

  const renderRowAndChildren = (node: OrgNode) => (
    <RowAndChildren
      filters={filters}
      getChoiceInfoForNode={getChoiceInfoForNode}
      key={node.organisation.id}
      node={node}
      setChoiceForNode={setChoiceForNode}
    />
  );

  return (
    <>
      <DataBoxSection>
        <Box pb={3} pl={1}>
          <PropertyItem title="Permission mode">
            <FormControl fullWidth variant="outlined" sx={{ width: 220 }}>
              <Select
                id="permission_mode"
                defaultValue="NORMAL"
                value={permissionMode || ""}
                onChange={(e) => {
                  setPermissionMode(e.target.value as any);
                  setDirty(true);
                }}
              >
                <MenuItem value="NORMAL">Access to selected</MenuItem>
                {permissionMode === "ALL_ACCESS_WITH_EXCLUDES" && (
                  <MenuItem value="ALL_ACCESS_WITH_EXCLUDES">
                    Access to everything except excluded
                  </MenuItem>
                )}
                <MenuItem value="ALL_ACCESS">Access to everything</MenuItem>
              </Select>
            </FormControl>
            &nbsp;
          </PropertyItem>
        </Box>
        <Box pb={3} pl={1}>
          <PropertyItem title="Include persons with alternative person data">
            <Checkbox
              checked={includeAliasPersons}
              onChange={(evt) => {
                setIncludeAliasPersons(evt.target?.checked);
                setDirty(true);
              }}
              color="primary"
            />
          </PropertyItem>
        </Box>
      </DataBoxSection>
      {permissionMode !== "ALL_ACCESS" ? (
        <Box style={{ overflow: "auto" }}>
          <Table size="small" sx={{ width: "100%" }}>
            <TableHead>
              <TableHeadTextRow>
                <TableCell style={{ paddingLeft: 24, width: "40%" }}>
                  Organisations
                </TableCell>
                <TableCell>Type</TableCell>
                <TableCell style={{ minWidth: 200 }}>SchoolType(s)</TableCell>
                <TableCell>Code</TableCell>
                <TableCell>SchoolUnitCode</TableCell>
                <TableCell style={{ minWidth: "auto" }}>Start</TableCell>
                <TableCell style={{ minWidth: "auto" }}>End</TableCell>
              </TableHeadTextRow>
              <TableClickableRow>
                <TableCell style={{ paddingLeft: 24 }}>
                  <FilterInput
                    filters={filters}
                    setFilters={setFilters}
                    field="org"
                    title="Filter By Organisation Name"
                  />
                </TableCell>
                <TableCell>
                  <FilterInput
                    filters={filters}
                    setFilters={setFilters}
                    field="type"
                    title="Filter By Organisation Type"
                  />
                </TableCell>
                <TableCell>
                  <SchoolTypeSelect filters={filters} setFilters={setFilters} />
                </TableCell>
                <TableCell>
                  <FilterInput
                    filters={filters}
                    setFilters={setFilters}
                    field="code"
                    title="Filter By Code"
                  />
                </TableCell>
                <TableCell>
                  <FilterInput
                    filters={filters}
                    setFilters={setFilters}
                    field="schoolUnitCode"
                    title="Filter By SchoolUnit Code"
                  />
                </TableCell>
                <TableCell colSpan={2}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={filters.historical}
                        onChange={(evt) =>
                          setFilters((val) => ({
                            ...val,
                            historical: evt.target?.checked ?? true,
                          }))
                        }
                        color="primary"
                      />
                    }
                    label={
                      <Typography
                        variant={"body2"}
                        style={{
                          fontSize: "0.75rem",
                          whiteSpace: "nowrap",
                          overflow: "visible",
                        }}
                      >
                        Show Historical
                      </Typography>
                    }
                  />
                </TableCell>
              </TableClickableRow>
            </TableHead>
            <TableBody>
              {topNodes.map(renderRowAndChildren)}
              {orphans.length ? (
                <>
                  <TableRow>
                    <TableCell>
                      <strong>
                        Organisations with parents that were not found.
                      </strong>
                    </TableCell>
                  </TableRow>
                  {orphans.map(renderRowAndChildren)}
                </>
              ) : null}
            </TableBody>
          </Table>
        </Box>
      ) : null}
      <Box p={3}>
        <Button
          color="primary"
          variant="contained"
          disabled={!dirty}
          onClick={handleSaveButtonClick}
        >
          Save
        </Button>
        {isSuperadmin && (
          <Button
            onClick={downloadAuditDataFile}
            style={{ marginLeft: 8 }}
            variant="contained"
          >
            Change log
          </Button>
        )}
      </Box>
    </>
  );
}
