import { Environment } from "@ist-group-private-scope/web-skolid";

export interface Settings {
  apiHost: string;
  graphqlConfigApiUrl: string;
  educloudGraphqlUrl: string;
  authenticatedUserBasePath: string;

  skolid: {
    environment: Environment;
    clientId: string;
    scope: string;
  };
}

export const defaultSettings: Settings = {
  skolid: {
    environment: "demo",
    clientId: "com.ist.home.dev.ss12k2-viewer-portal-dev",
    scope:
      "ss12k2-viewer openid profile ss12000v2-config com.ist.educloud.portal-api roles",
  },

  apiHost: "https://dataviewer-dev.ist.com/ss12000v2-api",
  graphqlConfigApiUrl:
    "http://internal.home-dev.dev-rancher.ist.com/ss12000v2-config/graphql",
  educloudGraphqlUrl: "https://api-dev.ist.com/educloud/graphql",
  authenticatedUserBasePath:
    "https://api-v3-dev.ist.com/educloud-service/authenticated-user/v1",
};

// Override settings per environment
const environmentSettings = {
  local: {
    apiHost: "http://localhost:8080",
    graphqlConfigApiUrl: "http://localhost:4011/graphql",
  },
  dev: {
    apiHost: "https://dataviewer-dev.ist.com/ss12000v2-api",
    graphqlConfigApiUrl: "https://dataviewer-dev.ist.com/graphql",
  },
  systemtest2a: {
    apiHost: "http://internal.2a.systemtest-rancher.ist.com/ss12000v2-api",
  },
  prod: {
    apiHost: "https://api.ist.com/ss12000v2-api",
    graphqlConfigApiUrl: "https://api.ist.com/ss12000v2-config/graphql",
    educloudGraphqlUrl: "https://api.ist.com/educloud/graphql",
    authenticatedUserBasePath:
      "https://api-v3.ist.com/educloud-service/authenticated-user/v1",
    skolid: {
      environment: "prod",
      clientId: "com.ist.educloud.ss12k2-viewer-portal",
      scope: defaultSettings.skolid.scope,
    },
  },
} as const;
type EnvironmentName = keyof typeof environmentSettings;

const hostToEnvironment: Partial<Record<string, EnvironmentName>> = {
  "bst-2a-01.bst.ist.local:8081": "systemtest2a",
  "dataviewer-dev.ist.com": "dev",
  "dataviewer.ist.com": "prod",
  "localhost:3113": "local",
  "localhost:3114": "local",
} as const;

function isEnvironmentName(str: string | undefined): str is EnvironmentName {
  return str !== undefined && str in environmentSettings;
}

// Exported settings
function getSettings(): Settings {
  // You can choose which settings to use using an environment variable, which is useful during development
  // if you don't want to test against services on the local machine.
  // See https://create-react-app.dev/docs/adding-custom-environment-variables/ for information about how
  // to work with environment variables and CRA.
  const environmentOverride: EnvironmentName | string | undefined =
    process.env.REACT_APP_ENVIRONMENT_OVERRIDE;

  const env = isEnvironmentName(environmentOverride)
    ? environmentOverride
    : hostToEnvironment[window.location.host];

  let settings: Settings = defaultSettings;
  if (env) {
    settings = { ...settings, ...environmentSettings[env] };
  } else if (process.env.JEST_WORKER_ID === undefined) {
    console.error(
      `There are no settings for this environment! Using default settings.`
    );
  }
  return settings;
}

export const settings = getSettings();
