import { useMemo } from "react";
import { SessionContext } from "@ist-group-private-scope/web-skolid";
import axios from "axios";
import _ from "lodash";
import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useLocation } from "react-router-dom";
import { settings } from "../settings";
import {
  normalizeNorwayNationalId,
  normalizeSwedishNationalId,
} from "./nationalIdNormalizers";
import * as common from "./types";
import { Person } from "./types";

export type RowData<T = any> = {
  kind: common.Target;
  list: false;
  row: T | null | undefined;
};

export type RowsData<T = any> = {
  kind: common.Target;
  list: true;
  rows: T[];
};

export type ErrorData = {
  kind: "error";
  protocolError?: string;
  message?: string;
  code?: string;
};

export type ResultData = RowsData | RowData | ErrorData;

const cache: Map<string, Map<string, any>> = new Map();

common.acceptedTargets.forEach((target) => {
  cache.set(target, new Map());
});

export const httpGET = <T = any>(
  url: string,
  jwt?: string,
  impersonateClientId?: string | null
) => {
  const headers: any = {
    Accept: "application/json",
    "Content-Type": "application/json",
  };

  if (jwt) {
    headers["Authorization"] = "Bearer " + jwt;
  }
  if (impersonateClientId) {
    headers["impersonate-client-id"] = impersonateClientId;
  }

  return axios.get<T>(url, {
    headers,
    timeout: 120000,
  });
};

const httpPOST = (
  url: string,
  data: any,
  jwt?: string,
  impersonateClientId?: string | null
) => {
  const headers: any = {
    Accept: "application/json",
    "Content-Type": "application/json",
  };

  if (jwt) {
    headers["Authorization"] = "Bearer " + jwt;
  }
  if (impersonateClientId) {
    headers["impersonate-client-id"] = impersonateClientId;
  }

  return axios.post(url, data, {
    headers,
    timeout: 20000,
  });
};

export const apiRequest = async (
  data: common.ApiCall,
  jwt?: string,
  impersonateClientId?: string | null
) => {
  if (common.acceptedTargets.includes(data?.target)) {
    const queryParams: string[] = ["expandReferenceNames=true"];
    const paramList = Object.keys(data) as (keyof typeof data)[];

    for (const param of paramList) {
      if (!common.paramBlacklist.includes(param)) {
        const value = (data as any)[param];
        if (Array.isArray(value)) {
          value.forEach((vi) =>
            queryParams.push(param + "=" + encodeURIComponent(vi))
          );
        } else if (value !== undefined) {
          queryParams.push(param + "=" + encodeURIComponent(value));
        }
      }
    }
    const path =
      settings.apiHost +
      "/source/" +
      data.sourceId +
      "/v2.0/" +
      data.target +
      "/" +
      (data.path ?? "") +
      // Add supplied query params to the call
      (queryParams.length ? "?" + queryParams.join("&") : "");

    const res =
      data.method === "POST"
        ? await httpPOST(path, data.body, jwt, impersonateClientId)
        : await httpGET(path, jwt, impersonateClientId);
    return res.data ? res.data : res;
  }
};

const fetchNextPage = (
  sourceId: string,
  target: common.Target,
  token: string,
  limit: string,
  setValue: Dispatch<SetStateAction<RowsData<any> | ErrorData>>,
  accessToken: string,
  impersonateClientId: string,
  mountedStatus: { current: boolean }
) => {
  const path =
    settings.apiHost +
    "/source/" +
    sourceId +
    "/v2.0/" +
    target +
    "?pageToken=" +
    token +
    "&limit=" +
    limit;
  httpGET(path, accessToken, impersonateClientId)
    .then((res) => {
      if (res?.data?.data && Array.isArray(res.data?.data)) {
        setValue((oldValue) => {
          if ((oldValue as RowsData<any>)?.rows) {
            return {
              rows: [...(oldValue as RowsData<any>).rows, ...res.data.data],
              list: true,
              kind: target,
            };
          }
          return oldValue;
        });
        if (res.data.pageToken && mountedStatus.current) {
          fetchNextPage(
            sourceId,
            target,
            res.data.pageToken,
            limit,
            setValue,
            accessToken,
            impersonateClientId,
            mountedStatus
          );
        }
      }
    })
    .catch((e) => {
      if (axios.isAxiosError(e) && e.response && e.response.data) {
        setValue({
          kind: "error",
          protocolError: e.response.statusText,
          message: (e.response.data as { message?: string }).message,
          code: (e.response.data as { code?: string }).code,
        });
      }
    });
};

export const useApiCall = (
  acOptions: common.ApiCall,
  multipleRows: boolean
): ResultData | undefined => {
  const ac = useMemo(
    () => ({
      ...acOptions,
      limit: multipleRows ? "5000" : undefined, // Add max items to fetch
    }),
    [acOptions, multipleRows]
  );

  const clientId = new URLSearchParams(useLocation().search).get("clientId");
  const [value, setValue] = useState<ResultData | undefined>();
  const { user } = useContext(SessionContext);
  // Keep track of the current elemet (to stop pagenation after )
  const mountedStatus = useRef(false);

  const cacheKey = JSON.stringify(ac) + clientId ?? "";

  useEffect(() => {
    mountedStatus.current = true;
    setValue(undefined);

    const fetchData = async () => {
      try {
        const res = await apiRequest(
          ac,
          (user && user.access_token) || undefined,
          clientId
        );
        const data = res.data ? res.data : res;

        let newState: ResultData;

        if (Array.isArray(data)) {
          newState = {
            rows: data,
            list: true,
            kind: ac.target,
          };
        } else {
          newState = {
            row: data,
            list: false,
            kind: ac.target,
          };
        }

        cache.get(ac.target)!.set(cacheKey, newState);
        setValue(newState);
        if (res.pageToken && mountedStatus.current && ac.limit !== undefined) {
          fetchNextPage(
            ac.sourceId,
            ac.target,
            res.pageToken,
            ac.limit,
            setValue as any,
            user?.access_token ?? "",
            clientId ?? "",
            mountedStatus
          );
        }
      } catch (e) {
        if (axios.isAxiosError(e) && e.response && e.response.data) {
          setValue({
            kind: "error",
            protocolError: e.response.statusText,
            message: (e.response.data as { message?: string }).message,
            code: (e.response.data as { code?: string }).code,
          });
        } else {
          setValue({
            kind: "error",
            message: e instanceof Error ? e.message : String(e),
          });
        }
      }
    };

    fetchData();
    return () => {
      mountedStatus.current = false;
    };
  }, [user, ac, cacheKey, clientId]);

  return value;
};

export interface CommonOrganisationInfo {
  id: string;
  name: string;
  country: {
    id: string;
    name: string;
  };
}

export interface ApiAccessReturnType {
  product: {
    subscriptions: {
      nodes: {
        description: string;
        productConfig?: {
          key: string;
          value: string | null;
        }[];
        organisation: CommonOrganisationInfo;
      }[];
    };
  };
}

export interface OrganisationRoleAssignment {
  __typename: "OrganisationRoleAssignment";
  organisation: {
    id: string;
    name: string;
    country: {
      id: string;
      name: string;
    };
    subscriptions: {
      nodes: {
        id: string;
        product: {
          id: string;
        };
        productConfig: {
          key: string;
          value: string | null;
        }[];
      }[];
    };
  };
  role: string;
}

export interface SubscriptionRoleAssignment {
  __typename: "SubscriptionRoleAssignment";
  subcriptionRole: string;
  subscription: {
    id: string;
    productConfig: {
      key: string;
      value: string | null;
    }[];
    organisation: CommonOrganisationInfo;
  };
}

export interface AccessReturn {
  org: CommonOrganisationInfo;
  sources: { sourceId: string; description: string }[];
}
export interface SearchResultItem {
  displayName: string;
  id: string;
  kind: common.Target;
}

export const getSourceFromConfig = (
  config: { key: string; value: string | null }[]
): string | null => {
  return config.find((conf) => conf.key === "CustomerId")?.value ?? null;
};

interface ListItem {
  displayName: string;
  id: string;
}
type PaddedListItem = { data: ListItem[] };

const attachSearchKind = async (
  foo: Promise<ListItem[] | PaddedListItem>,
  kind: common.Target
): Promise<SearchResultItem[]> => {
  let list = await foo;
  list = (list as PaddedListItem).data
    ? (list as PaddedListItem).data
    : (list as ListItem[]); // Remove list padding
  return (list as ListItem[]).map((i) => ({ ...i, kind }));
};

export const search = async (
  jwt: string,
  customerId: string,
  searchTerm: string,
  clientId?: string | null
) => {
  const potentialNationalIds = [
    normalizeSwedishNationalId(searchTerm),
    normalizeNorwayNationalId(searchTerm),
  ].filter((natId) => Boolean(natId));

  const people = apiRequest(
    {
      method: common.Method.POST,
      target: common.Target.persons,
      path: "lookup",
      sourceId: customerId,
      body: {
        ids: [searchTerm],
        civicNos: potentialNationalIds,
      },
    },
    jwt,
    clientId
  );

  const orgs = apiRequest(
    {
      method: common.Method.POST,
      target: common.Target.organisations,
      path: "lookup",
      sourceId: customerId,
      body: {
        ids: [searchTerm],
      },
    },
    jwt,
    clientId
  );
  const orgsCode = apiRequest(
    {
      method: common.Method.GET,
      target: common.Target.organisations,
      sourceId: customerId,
      organisationCode: searchTerm,
    },
    jwt,
    clientId
  );
  const orgsSuCode = apiRequest(
    {
      method: common.Method.GET,
      target: common.Target.organisations,
      sourceId: customerId,
      schoolUnitCode: searchTerm,
    },
    jwt,
    clientId
  );

  const groups = apiRequest(
    {
      method: common.Method.POST,
      target: common.Target.groups,
      path: "lookup",
      sourceId: customerId,
      body: {
        ids: [searchTerm],
      },
    },
    jwt,
    clientId
  );
  const searchTargerts = [
    ((await people) as Person[])?.map((i) => ({
      ...i,
      kind: common.Target.persons,
      displayName: i.givenName + " " + i.familyName,
    })) ?? [],
    attachSearchKind(groups, common.Target.groups),
    attachSearchKind(orgs, common.Target.organisations),
    attachSearchKind(orgsCode, common.Target.organisations),
    attachSearchKind(orgsSuCode, common.Target.organisations),
  ];

  return _.flatten(await Promise.all(searchTargerts));
};

export const searchPersonByName = async (
  jwt: string,
  customerId: string,
  searchTerm: string,
  clientId?: string | null
) =>
  apiRequest(
    {
      method: common.Method.GET,
      target: common.Target.persons,
      sourceId: customerId,
      nameContains: searchTerm.split(" "),
      limit: "100",
    },
    jwt,
    clientId
  );
