import { useContext } from "react";
import React from "react";

export interface DrawerItemParentContext {
  level: number;
  open: boolean;
}

export const DrawerOpenContext = React.createContext<{
  open: boolean;
  setOpen?: React.Dispatch<React.SetStateAction<boolean>>;
}>({ open: true });

export const DrawerItemContext = React.createContext<DrawerItemParentContext>({
  level: 0,
  open: false,
});

export const useIsDrawerOpen = () => useContext(DrawerOpenContext);
