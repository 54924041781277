import { ReactElement } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import { useSourceId } from "../common";
import {
  DataBox,
  DataBoxBlock,
  DataBoxSection,
  ListGenericComponent,
  PropertyItem,
  useObjectReferenceCallback,
} from "../common/generic";
import {
  GenericListViewProps,
  RowComponent,
  RowsComponent,
} from "../common/rows";
import * as common from "../common/types";
import ExplorerHeader from "./ExplorerHeader";

export interface SyllabusDataBoxProps {
  item: common.Syllabus;
}
export function SyllabusDataBox({ item }: SyllabusDataBoxProps): ReactElement {
  return (
    <DataBox
      data={item}
      title={
        item.courseName ?? item.subjectName ?? item.subjectDesignation ?? ""
      }
    >
      <DataBoxSection>
        <DataBoxBlock>
          <PropertyItem title="Type" value={item.schoolType} />
          <PropertyItem title="SubjectCode" value={item.subjectCode} />
        </DataBoxBlock>
        <DataBoxBlock>
          <PropertyItem title="SubjectName" value={item.subjectName} />
          <PropertyItem
            title="SubjectDesignation"
            value={item.subjectDesignation}
          />
        </DataBoxBlock>
        <DataBoxBlock>
          <PropertyItem title="CourseCode" value={item.courseCode} />
          <PropertyItem title="CourseName" value={item.courseName} />
        </DataBoxBlock>
      </DataBoxSection>
      <DataBoxSection>
        <DataBoxBlock>
          <PropertyItem
            title="Start school year"
            value={item.startSchoolYear}
          />
          <PropertyItem title="End school year" value={item.endSchoolYear} />
        </DataBoxBlock>
        <DataBoxBlock>
          <PropertyItem title="Points" value={item.points} />
          <PropertyItem title="Curriculum" value={item.curriculum} />
        </DataBoxBlock>
        <DataBoxBlock>
          <PropertyItem title="Language Code" value={item.languageCode} />
          <PropertyItem title="Official" value={item.official} />
        </DataBoxBlock>
      </DataBoxSection>
      {item.specialisationCourseContent ? (
        <DataBoxSection>
          <DataBoxBlock>
            <PropertyItem title="SpecialisationCourseContent">
              <Typography variant="h6">
                {item.specialisationCourseContent?.title}
              </Typography>
              <Typography variant="body2" paragraph>
                {item.specialisationCourseContent?.description}
              </Typography>
            </PropertyItem>
            <PropertyItem title="SpecialisationCourseContent (EN)">
              <Typography variant="h6">
                {item.specialisationCourseContent?.titleEngish}
              </Typography>
              <Typography variant="body2" paragraph>
                {item.specialisationCourseContent?.descriptionEnglish}
              </Typography>
            </PropertyItem>
          </DataBoxBlock>
        </DataBoxSection>
      ) : null}
    </DataBox>
  );
}

export const SyllabiListComponent = (
  props: GenericListViewProps<common.Syllabus>
) => {
  const click = useObjectReferenceCallback(common.Target.syllabuses);

  return (
    <ListGenericComponent<common.Syllabus>
      onRowClick={click}
      columns={[
        {
          title: "Name",
          field: "displayName",
        },
        {
          title: "Subject Code",
          field: "subjectCode",
        },
        {
          title: "Subject",
          field: "subjectName",
        },
        {
          title: "Course Code",
          field: "courseCame",
        },
        {
          title: "Course",
          field: "courseName",
        },
        {
          title: "Designation",
          field: "subjectDesignation",
        },
      ]}
      {...props}
    />
  );
};

export interface SingleSyllabusProps {
  row?: common.Syllabus | null;
}

export function SingleSyllabus(props: SingleSyllabusProps): ReactElement {
  const syllabus = props.row;

  if (!syllabus) {
    return <Typography variant="h2">No syllabus found</Typography>;
  }

  return (
    <Grid
      container
      spacing={3}
      alignItems="stretch"
      alignContent="stretch"
      style={{
        flexGrow: 1,
      }}
    >
      <SyllabusDataBox item={syllabus} />
    </Grid>
  );
}

export function Syllabi(): ReactElement {
  const rm = useRouteMatch();

  const ac: common.ApiCall = {
    sourceId: useSourceId(),
    target: common.Target.syllabuses,
    method: common.Method.GET,
  };

  if (!rm) {
    throw Error("RouteMatch not defined.");
  }

  return (
    <>
      <ExplorerHeader typeLink={rm.path} typeName="Syllabus"></ExplorerHeader>
      <Switch>
        <Route
          path={`${rm.path}/:syllabusId`}
          render={({ match }) => (
            <RowComponent<common.Syllabus>
              apiCall={{ ...ac, path: match.params.syllabusId }}
              view={SingleSyllabus}
            />
          )}
        />

        <Route path={`${rm.path}`}>
          <RowsComponent<common.Syllabus>
            apiCall={ac}
            view={SyllabiListComponent}
          />
        </Route>
      </Switch>
    </>
  );
}
