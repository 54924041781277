import { gql, useQuery } from "@apollo/client";
import { useIsGraphqlAuthenticated } from "../graphql";

export type PermissionMode =
  | "NORMAL"
  | "ALL_ACCESS_WITH_EXCLUDES"
  | "ALL_ACCESS";

const GET_CLIENT_SOURCE_ACCESS_QUERY = gql`
  query ClientAccess($input: ClientAccessId!) {
    clientSourceAccess(input: $input) {
      sourceId
      clientId
      permissionMode
      includes
      excludes
      educloudEnabled
      includeAliasPersons
    }
  }
`;

export const GET_CLIENT_SOURCE_AUDIT_LOGS_QUERY = gql`
  query ClientSourceAuditLogs($clientId: String!, $sourceId: String!) {
    clientSourceAuditLogs(input: { clientId: $clientId, sourceId: $sourceId }) {
      actor {
        id
        name
      }
      actorClientId
      created
      id
      ip
      newValue
      oldValue
    }
  }
`;

export const SET_CLIENT_SOURCE_ACCESS_MUTATION = gql`
  mutation SetClientSourceAccess($input: ClientAccessInput!) {
    setClientSourceAccess(input: $input) {
      sourceId
      clientId
      permissionMode
      includes
      excludes
      educloudEnabled
      includeAliasPersons
    }
  }
`;

export interface AccessQuery {
  client: {
    id: string;
    name: string | null;
  };
  clientId: string;
  educloudEnabled: boolean;
  excludes: string[];
  includeAliasPersons: boolean;
  includes: string[];
  permissionMode: PermissionMode;
  sourceId: string;
}

export interface AuditEntryValue {
  excludes: string[];
  includeAliasPersons: boolean;
  includes: string[];
  permissionMode: string;
}

export interface AuditEntry {
  actorClientId: string | null;
  actor: null | {
    id: string;
    name: string | null;
  };
  clientId: string;
  created: string;
  oldValue: AuditEntryValue | null;
  newValue: AuditEntryValue;
  id: string;
  ip: string | null;
  sourceId: string;
}

export const useClientList = (sourceId: string) => {
  const graphqlAuthenticated = useIsGraphqlAuthenticated();
  return useQuery<{ sourceAccessList: AccessQuery[] }>(
    gql`
      query SourceAccessList($sourceId: String!) {
        sourceAccessList(sourceId: $sourceId) {
          sourceId
          clientId
          permissionMode
          includes
          excludes
          educloudEnabled
          includeAliasPersons
        }
      }
    `,
    {
      fetchPolicy: "network-only",
      skip: !graphqlAuthenticated,
      variables: { sourceId: sourceId },
    }
  );
};

export const useAccessQuery = (clientId: string, sourceId: string) => {
  const graphqlAuthenticated = useIsGraphqlAuthenticated();
  return useQuery<{ clientSourceAccess: AccessQuery }>(
    GET_CLIENT_SOURCE_ACCESS_QUERY,
    {
      variables: { input: { clientId, sourceId }, skip: !graphqlAuthenticated },
      fetchPolicy: "network-only",
    }
  );
};

export const useAccessMutation = () => {};
