import { ReactElement, ReactNode } from "react";
import ThemeProvider from "@mui/material/styles/ThemeProvider";
import { stylistTheme } from "./stylistTheme";

export function StylistThemeProvider({
  children,
}: {
  children: ReactNode;
}): ReactElement {
  return <ThemeProvider theme={stylistTheme}>{children}</ThemeProvider>;
}
