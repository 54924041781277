import { ReactElement, ReactNode } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

export interface ConfirmDialogProps {
  cancelButtonContent?: ReactNode;
  children?: ReactNode;
  confirmButtonContent?: ReactNode;
  onCancel: () => void;
  onConfirm: () => void;
  open: boolean;
  title?: ReactNode;
}

// Disable the exit transition to avoid ugly changes to the content that
// the onCancel and onConfirm callbacks may cause
const transitionDuration = { exit: 0 };

export default function ConfirmDialog({
  cancelButtonContent = "Cancel",
  children,
  confirmButtonContent = "Yes",
  onCancel,
  onConfirm,
  open,
  title = "Are you sure?",
}: ConfirmDialogProps): ReactElement | null {
  return (
    <Dialog
      aria-labelledby="confirm-dialog-title"
      aria-describedby="confirm-dialog-description"
      onClose={onCancel}
      open={open}
      transitionDuration={transitionDuration}
    >
      <DialogTitle id="confirm-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="confirm-dialog-description">
          {children}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={onCancel}>
          {cancelButtonContent}
        </Button>
        <Button autoFocus color="primary" onClick={onConfirm}>
          {confirmButtonContent}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
