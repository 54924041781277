import { ReactElement } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import { useSourceId, validDateFilter } from "../common";
import {
  DataBox,
  DataBoxBlock,
  DataBoxSection,
  ListGenericComponent,
  ObjectReferenceLink,
  PropertyItem,
  PropertyItemDateRange,
  useObjectReferenceCallback,
  useObjectLinkCallback,
} from "../common/generic";
import {
  GenericListViewProps,
  RowComponent,
  RowsComponent,
} from "../common/rows";
import * as common from "../common/types";
import { SchoolTypeLookupDef } from "../common/types";
import ExplorerHeader from "./ExplorerHeader";
import { Column } from "@material-table/core";

interface SingleActivityProps {
  kind: common.Target;
  row?: common.ActivityExpanded | null;
}

type Teacher = Exclude<common.ActivityExpanded["teachers"], undefined>[number];

const activityTeachersListColumns: Column<Teacher>[] = [
  {
    title: "Duty",
    field: "displayName",
  },
  { title: "MinutesPlanned", field: "minutesPlanned" },
  { title: "Start", field: "startDate" },
  { title: "Stop", field: "endDate" },
];

function SingleActivity(props: SingleActivityProps): ReactElement {
  const activity = props.row;
  const openReference = useObjectLinkCallback();

  if (!activity) {
    return <Typography variant="h2">No activity found</Typography>;
  }

  return (
    <Grid
      container
      spacing={3}
      alignItems="stretch"
      alignContent="stretch"
      style={{
        flexGrow: 1,
      }}
    >
      <DataBox data={activity} title={activity.displayName}>
        <DataBoxSection>
          <DataBoxBlock>
            <PropertyItem title="Organisation">
              <ObjectReferenceLink
                kind={common.Target.organisations}
                item={activity.organisation}
              />
            </PropertyItem>
            <PropertyItem title="Type" value={activity.activityType} />
          </DataBoxBlock>
          <DataBoxBlock>
            <PropertyItem title="Syllabus">
              <ObjectReferenceLink
                kind={common.Target.syllabuses}
                item={activity.syllabus}
              />
            </PropertyItem>
            <PropertyItem
              title="calendarEventsRequired"
              value={activity.calendarEventsRequired}
            />
          </DataBoxBlock>
          <DataBoxBlock>
            <PropertyItem title="comment" value={activity.comment} />
          </DataBoxBlock>
        </DataBoxSection>
        <DataBoxSection>
          <DataBoxBlock>
            {activity.parentActivity && (
              <PropertyItem title="Parent">
                <ObjectReferenceLink
                  kind={common.Target.activities}
                  item={activity.parentActivity}
                />
              </PropertyItem>
            )}
            <PropertyItemDateRange
              title="Valid"
              start={activity.startDate}
              end={activity.endDate}
            />
          </DataBoxBlock>
        </DataBoxSection>
      </DataBox>

      <ListGenericComponent
        data={activity.teachers ?? []}
        name={(row) => row?.duty?.displayName ?? "???"}
        onRowClick={(evt, row) =>
          openReference(evt, common.Target.duties, row?.duty?.id)
        }
        columns={activityTeachersListColumns}
        title="Teachers"
      />
      <ListGenericComponent
        data={activity._embedded?.groups ?? []}
        onRowClick={(evt, row) =>
          openReference(evt, common.Target.groups, row?.id)
        }
        columns={[
          {
            title: "Namn",
            field: "displayName",
          },
          {
            title: "SchoolType",
            field: "schoolType",
            lookup: SchoolTypeLookupDef,
          },
          {
            title: "GroupType",
            field: "groupType",
          },
          {
            title: "Organisation",
            field: "organisation",
            render: (row) => (
              <ObjectReferenceLink
                kind={common.Target.organisations}
                item={row.organisation}
              />
            ),
          },
        ]}
        title="Groups"
      />
    </Grid>
  );
}

export const ActivitiesListComponent = (
  props: GenericListViewProps<common.Activity>
) => {
  const click = useObjectReferenceCallback(common.Target.activities);
  return (
    <ListGenericComponent<common.ActivityExpanded>
      checkboxFilter={{
        label: "Only current activities",
        condition: validDateFilter,
        defaultValue: true,
      }}
      onRowClick={click}
      columns={[
        {
          title: "Name",
          field: "displayName",
        },
        {
          title: "Type",
          field: "activityType",
          defaultSort: "desc",
          lookup: {
            Undervisning: "Undervisning",
            Elevaktivitet: "Elevaktivitet",
            Provaktivitet: "Provaktivitet",
            Läraraktivitet: "Läraraktivitet",
            Övrig: "Övrig",
          },
        },
        {
          title: "Teachers",
          field: "teachers",
          render: (value) =>
            value.teachers?.map((t) => t.duty?.displayName).join(", "),
        },
        {
          title: "Syllabus",
          field: "syllabus.displayName",
          render: (value) => value.syllabus?.displayName,
        },
      ]}
      {...props}
      title="Activities"
    />
  );
};

export const Activities = () => {
  const rm = useRouteMatch();

  const ac: common.ApiCall = {
    sourceId: useSourceId(),
    target: common.Target.activities,
    method: common.Method.GET,
    expand: ["teachers", "groups", "syllabus"],
  };

  if (!rm) {
    throw Error("RouteMatch not defined.");
  }

  return (
    <>
      <ExplorerHeader typeLink={rm.path} typeName="Acticity" />
      <Switch>
        <Route
          path={`${rm.path}/:activityId`}
          render={({ match }) => (
            <RowComponent<common.Activity>
              apiCall={{ ...ac, path: match.params.activityId }}
              view={SingleActivity}
            />
          )}
        />

        <Route path={`${rm.path}`}>
          <RowsComponent<common.Activity>
            apiCall={ac}
            view={ActivitiesListComponent}
          />
        </Route>
      </Switch>
    </>
  );
};
