import { ReactElement } from "react";
import {
  ListGenericComponent,
  useObjectReferenceCallback,
} from "../common/generic";
import { GenericListViewProps } from "../common/rows";
import * as common from "../common/types";
import { Column } from "@material-table/core";

const personsListColumns: Column<common.Person>[] = [
  {
    title: "Personnummer",
    field: "civicNo.value",
    render: (r) => r.civicNo?.value,
  },
  { title: "Namn", field: "givenName" },
  { title: "Efternamn", field: "familyName" },
  {
    title: "Status",
    field: "personStatus",
    lookup: {
      Aktiv: "Aktiv",
      Utvandrad: "Utvandrad",
      Avliden: "Avliden",
    },
  },
  {
    title: "Sekritess",
    field: "securityMarking",
    lookup: {
      Ingen: "Ingen",
      Sekretessmarkering: "Sekretessmarkering",
      "Skyddad folkbokföring": "Skyddad folkbokföring",
    },
  },
];

export interface PersonsListComponentProps
  extends GenericListViewProps<common.Person> {}

export function PersonsListComponent(
  props: PersonsListComponentProps
): ReactElement {
  const click = useObjectReferenceCallback(common.Target.persons);

  return (
    <ListGenericComponent<common.Person>
      onRowClick={click}
      columns={personsListColumns}
      {...props}
    />
  );
}
