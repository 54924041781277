import { Column } from "@material-table/core";

// Adds a fixed CSS width to the filter cells of columns with a lookup (select) filter
export default function fixedSizeLookupFilterColumns<RowData extends object>(
  columns: Column<RowData>[]
): Column<RowData>[] {
  return columns.map((column) =>
    column.lookup
      ? {
          ...column,

          filterCellStyle: {
            minWidth: "12em",
            maxWidth: "12em",
            ...column.filterCellStyle,
          },
        }
      : column
  );
}
