import { ReactElement, useCallback, useEffect, useState } from "react";
import useOrgsAndVendors from "./useOrgsAndVendors";
import { Option } from "./types";
import OrgOrVendorSelectorPresentation from "./OrgOrVendorSelectorPresentation";
import { EducloudRole } from "common/educloudRoles";

export interface OrgOrVendorSelectorProps {
  className?: string;
  educloudRoleFilter?: EducloudRole[]; // Only show organisations and vendors where the current user has one of these roles
  onChange: (orgOrVendor: Option) => void;
}

export default function OrgOrVendorSelector({
  className,
  educloudRoleFilter,
  onChange,
}: OrgOrVendorSelectorProps): ReactElement | null {
  const { options } = useOrgsAndVendors(educloudRoleFilter);
  const [selectedOption, setSelectedOption] = useState<Option>();

  const handleChange = useCallback(
    (option: Option) => {
      setSelectedOption(option);
      onChange(option);
    },
    [onChange]
  );

  // There is no need to choose if there's only one option
  const onlyOption = options?.length === 1 ? options[0] : undefined;
  useEffect(() => {
    if (onlyOption) {
      handleChange(onlyOption);
    }
  }, [handleChange, onlyOption]);

  if (onlyOption) {
    return null;
  }

  if (options?.length === 0) {
    return <>You are not an administrator of an organisation or vendor</>;
  }

  return (
    <OrgOrVendorSelectorPresentation
      className={className}
      onChange={handleChange}
      options={options}
      selectedOption={selectedOption}
    />
  );
}
