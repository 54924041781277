import createTheme from "@mui/material/styles/createTheme";
import type { Palette } from "@mui/material/styles/";
import responsiveFontSizes from "@mui/material/styles/responsiveFontSizes";
import createPalette from "@mui/material/styles/createPalette";

// Load the font CSS
import "@fontsource-variable/libre-franklin";

const palette = createPalette({
  background: { default: "#F8F8F8", paper: "#FFFFFF" },
  primary: { main: "#007E94", light: "#D9F1F6", dark: "#006A83" },
  text: { primary: "#262626" },
  action: { active: "#007E94" },
  secondary: { main: "#7066ff" },
  success: { main: "#57D48A" },
  warning: { main: "#DDD600" },
  error: { main: "#FF493A" },
  divider: "#E5E5E5",
});

export function px2Rem(px: number) {
  return px / 16 + "rem";
}

export const drawerWidth = "252px";

export const stylistTheme = responsiveFontSizes(
  createTheme({
    typography: (palette: Palette) => ({
      htmlFontSize: 16,
      fontFamily:
        "'Libre Franklin Variable', 'Libre Franklin', 'Helvetica', 'Arial', sans-serif",
      fontSize: 16,
      allVariants: {
        lineHeight: 1.2,
        fontFamily:
          "'Libre Franklin Variable', 'Libre Franklin', 'Helvetica', 'Arial', sans-serif",
      },
      h1: {
        fontSize: px2Rem(42),
        fontWeight: 700,
        color: palette.primary.main,
        marginBottom: 25,
      },
      h2: {
        fontSize: px2Rem(36),
        fontWeight: 700,
        color: palette.primary.main,
        marginBottom: 20,
      },
      h3: {
        fontSize: px2Rem(24),
        fontWeight: 700,
        marginBottom: 10,
      },
      h4: {
        fontSize: px2Rem(20),
        fontWeight: 700,
      },
      h5: {
        fontSize: px2Rem(18),
        fontWeight: 700,
      },
      h6: {
        fontSize: px2Rem(16),
        fontWeight: 700,
      },
      subtitle1: {
        fontSize: px2Rem(16),
        lineHeight: 1.5,
        fontWeight: 600,
      },
      subtitle2: {
        fontSize: px2Rem(16),
        lineHeight: 1.5,
        fontWeight: 600,
      },
      body1: {
        fontSize: px2Rem(16),
        lineHeight: 1.5,
      },
      body2: {
        fontSize: px2Rem(14),
        lineHeight: 1.5,
      },
      button: {
        fontSize: px2Rem(14),
        lineHeight: 1.5,
        fontWeight: 700,
        letterSpacing: 1,
        textTransform: "uppercase",
      },
      caption: {
        fontSize: px2Rem(12),
        fontWeight: 600,
        lineHeight: 1.5,
        color: palette.primary.main,
      },
      overline: {
        color: palette.primary.main,
        fontSize: px2Rem(12),
        lineHeight: px2Rem(12),
        textTransform: "uppercase",
        letterSpacing: 1,
      },
    }),
    shape: {
      borderRadius: 12,
    },
    palette,
    shadows: [
      "none",
      "0px 3px 50px rgba(0,0,0,0.1)",
      "0px 3px 50px rgba(0,0,0,0.1)",
      "0px 3px 50px rgba(0,0,0,0.1)",
      "0px 3px 50px rgba(0,0,0,0.1)",
      "0px 3px 50px rgba(0,0,0,0.1)",
      "0px 3px 50px rgba(0,0,0,0.1)",
      "0px 3px 50px rgba(0,0,0,0.1)",
      "0px 3px 50px rgba(0,0,0,0.1)",
      "0px 3px 50px rgba(0,0,0,0.1)",
      "0px 3px 50px rgba(0,0,0,0.1)",
      "0px 3px 50px rgba(0,0,0,0.1)",
      "0px 3px 50px rgba(0,0,0,0.1)",
      "0px 3px 50px rgba(0,0,0,0.1)",
      "0px 3px 50px rgba(0,0,0,0.1)",
      "0px 3px 50px rgba(0,0,0,0.1)",
      "0px 3px 50px rgba(0,0,0,0.1)",
      "0px 3px 50px rgba(0,0,0,0.1)",
      "0px 3px 50px rgba(0,0,0,0.1)",
      "0px 3px 50px rgba(0,0,0,0.1)",
      "0px 3px 50px rgba(0,0,0,0.1)",
      "0px 3px 50px rgba(0,0,0,0.1)",
      "0px 3px 50px rgba(0,0,0,0.1)",
      "0px 3px 50px rgba(0,0,0,0.1)",
      "0px 3px 50px rgba(0,0,0,0.1)",
    ],
    components: {
      MuiAppBar: {
        styleOverrides: {
          root: { boxShadow: "none", minHeight: 64 },
        },
      },
      MuiButton: {
        defaultProps: {
          variant: "contained",
          disableElevation: true,
        },
        styleOverrides: {
          root: {},
          contained: {
            height: 48,
            borderRadius: 24,
            paddingRight: 35,
            paddingLeft: 35,
          },
          containedSizeLarge: ({ theme: { breakpoints } }) => ({
            paddingLeft: 50,
            paddingRight: 50,
            height: 60,
            borderRadius: 32,
            [breakpoints.down("sm")]: {
              paddingLeft: 50,
              paddingRight: 50,
            },
            [breakpoints.down("xs")]: {
              width: "100%",
              textAlign: "center",
              paddingLeft: 15,
              paddingRight: 15,
            },
          }),
        },
      },
      MuiContainer: {
        styleOverrides: {
          root: ({ theme: { breakpoints } }) => ({
            [breakpoints.down("sm")]: {
              paddingLeft: 8,
              paddingRight: 8,
            },
          }),
        },
      },
      MuiLink: {
        styleOverrides: {
          underlineAlways: {
            textDecoration: "none",
            borderBottomWidth: 2,
            borderBottomStyle: "solid",
            borderBottomColor: "inherit",
          },
          underlineHover: {
            borderBottomWidth: 2,
            borderBottomStyle: "solid",
            borderBottomColor: "inherit",
            "&:hover": {
              borderBottomWidth: 3,
              textDecoration: "none",
            },
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          input: {
            paddingTop: 10,
            paddingBottom: 10,
          },
          root: {
            borderRadius: 8,
            padding: 0,
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          rounded: {
            borderRadius: 15,
          },
        },
      },
      MuiSelect: {
        styleOverrides: {
          icon: {
            color: "inherit",
          },
        },
      },
      MuiTable: {
        styleOverrides: {
          root: ({ theme: { spacing } }) => ({
            marginTop: spacing(1),
          }),
        },
      },
      MuiTableCell: {
        styleOverrides: {
          body: {
            borderBottom: "1px solid #E5E5E5",
          },
          head: {
            color: palette.primary.main,
            fontSize: px2Rem(12),
            lineHeight: px2Rem(12),
            textTransform: "uppercase",
            letterSpacing: 1,
          },
          sizeSmall: {
            padding: "6px",
          },
        },
      },
      MuiTextField: {
        defaultProps: {
          variant: "outlined",
        },
      },
      MuiTypography: {
        styleOverrides: {
          paragraph: {
            marginBottom: "1rem",
          },
        },
      },
    },
  })
);
