export enum EducloudRole {
  Admin = "ADMIN",
  Developer = "DEVELOPER",
  Sales = "SALES",
}

export const allEducloudAdminRoles = [EducloudRole.Admin];
export const allEducloudOrganisationRoles = [EducloudRole.Admin];
export const allEducloudVendorRoles = [
  EducloudRole.Admin,
  EducloudRole.Developer,
  EducloudRole.Sales,
];

export interface EducloudUserRoleAssignment {
  emailAddress: string;
  name: string;
  role: EducloudRole;
  userId: string;
}

const humanFriendlyEducloudRoleNames: Record<EducloudRole, string> & {
  "": "";
} = {
  [EducloudRole.Admin]: "Administrator",
  [EducloudRole.Developer]: "Developer",
  [EducloudRole.Sales]: "Sales",
  "": "",
};

export function describeEducloudRole(educloudRole?: EducloudRole): string {
  return humanFriendlyEducloudRoleNames[educloudRole ?? ""];
}
