import { useCallback, useRef, useState } from "react";

type Key = number;
type PushFunction<Value> = (valueToAdd: Value) => void;
type PopFunction = () => void;

// Creates a queue of values and gives you the front element and an automatically
// generated key for that element, and push and pop functions
export default function useQueueWithGeneratedKeys<Value>():
  | [Value, Key, PushFunction<Value>, PopFunction]
  | [undefined, undefined, PushFunction<Value>, PopFunction] {
  const [key, setKey] = useState<Key | undefined>();
  const dataRef = useRef({ numPops: 0, values: [] as Value[] });

  const push = useCallback((valueToAdd: Value) => {
    const {
      current: { numPops, values },
    } = dataRef;
    if (!values.length) {
      setKey(numPops);
    }
    values.push(valueToAdd);
  }, []);

  const pop = useCallback(() => {
    const { current: data } = dataRef;
    if (data.values.splice(0, 1).length) {
      data.numPops++;
      setKey(data.values.length ? data.numPops : undefined);
    }
  }, []);

  if (key) {
    return [dataRef.current.values[0], key, push, pop];
  }
  return [undefined, undefined, push, pop];
}
