import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
  ReactElement,
} from "react";
import {
  ApolloLink,
  InMemoryCache,
  ApolloClient,
  createHttpLink,
} from "@apollo/client";
import { ApolloProvider } from "@apollo/client";
import { settings } from "./settings";
import { AuthContext, SessionState } from "@ist-group-private-scope/web-skolid";

const GraphqlAuthContext = createContext(false);

export function useIsGraphqlAuthenticated(): boolean {
  return useContext(GraphqlAuthContext);
}

const configHttpLink = createHttpLink({
  uri: settings.graphqlConfigApiUrl,
});
const educloudHttpLink = createHttpLink({
  uri: settings.educloudGraphqlUrl,
});

const getAuthLink = (user: SessionState["user"]) => {
  const accessToken = user ? "Bearer " + user.access_token : null;

  const link = new ApolloLink((operation, next) => {
    if (!accessToken) {
      return next(operation);
    }

    operation.setContext((context: any) => {
      return {
        headers: {
          ...context.headers,
          authorization: accessToken,
        },
      };
    });

    return next(operation);
  });

  return { link, success: Boolean(accessToken) };
};

const getClient = (authLink: ApolloLink) => {
  return new ApolloClient({
    link: ApolloLink.split(
      (op) => op.getContext().clientName === "educloud",
      authLink.concat(educloudHttpLink),
      authLink.concat(configHttpLink)
    ),
    cache: new InMemoryCache({
      possibleTypes: {
        __schema: [],
      },
    }),
  });
};

export interface GraphqlProviderProps {
  children: ReactNode;
}

export function GraphqlProvider(props: GraphqlProviderProps): ReactElement {
  const auth = useContext(AuthContext);
  const initialAuthLink = getAuthLink(auth.user);
  const [client, setClient] = useState(() => getClient(initialAuthLink.link));
  const authUser = auth.user;
  const firstUpdate = useRef(true);
  const authenticated = useRef(initialAuthLink.success);

  useEffect(() => {
    // Do not run effect at first render, only when authUser changes
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    const authLink = getAuthLink(authUser);
    authenticated.current = authLink.success;
    setClient(getClient(authLink.link));
  }, [authUser]);

  return (
    <ApolloProvider client={client}>
      <GraphqlAuthContext.Provider value={authenticated.current}>
        {props.children}
      </GraphqlAuthContext.Provider>
    </ApolloProvider>
  );
}
