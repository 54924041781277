import { ReactElement } from "react";
import { useParams } from "react-router-dom";
import { ObjectTableComponent } from "./generic";
import Typography from "@mui/material/Typography";

export const nameFuncs = {
  persons: ({
    givenName,
    familyName,
  }: {
    givenName: string;
    familyName: string;
  }) => givenName + " " + familyName,
};

function withoutTime(dateTime: Date) {
  const date = new Date(dateTime.getTime());
  date.setHours(0, 0, 0, 0);
  return date;
}
const todayWithoutTime = withoutTime(new Date()).getTime();

export function validDateFilter(item?: {
  startDate?: string | null;
  endDate?: string | null;
}) {
  if (!item) return false;
  return validStartDate(item.startDate) && validEndDate(item.endDate);
}

export function validStartDate(startDate?: string | null) {
  return startDate ? todayWithoutTime >= new Date(startDate).getTime() : true;
}
export function validEndDate(endDate?: string | null) {
  return endDate ? todayWithoutTime <= new Date(endDate).getTime() : true;
}

export const pluralize = (str: string) => (str.endsWith("s") ? str : str + "s");
export const singularize = (str: string) =>
  str.endsWith("s") ? str.substr(0, str.length - 1) : str;
export const capitalize = (str: string) =>
  str
    .split(" ")
    .map((s) => s[0].toUpperCase() + s.slice(1))
    .join(" ");

export const rowName = (data: any, kind?: string): string => {
  if (kind && (nameFuncs as any)[kind]) {
    return (nameFuncs as any)[kind](data);
  }

  if (data.displayName) {
    return data.displayName;
  }

  if (kind && data.rows) {
    return pluralize(kind);
  } else if (kind) {
    return singularize(kind);
  }

  return "<no name found>";
};

export function useSourceId(): string {
  const { sourceId } = useParams<{ sourceId?: string }>();

  if (!sourceId) {
    throw new Error("No sourceId defined!");
  }

  return sourceId;
}

export interface SingleGenericComponentProps {
  kind: string;
  row?: any;
}
export function SingleGenericComponent({
  kind,
  row,
}: SingleGenericComponentProps): ReactElement {
  if (!row) {
    return <div>Waiting for data...</div>;
  }

  return (
    <>
      <Typography variant="h2">
        {singularize(kind)}: {rowName(row, kind)}
      </Typography>
      <ObjectTableComponent depth={5} data={row} />
    </>
  );
}
