import { ReactElement } from "react";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import styled from "@mui/material/styles/styled";
import MuiAlert from "@mui/material/Alert";
import * as qs from "query-string";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import { useSourceId } from "../common";
import { RowData } from "../common/api";
import {
  DataBox,
  DataBoxBlock,
  DataBoxSection,
  ListGenericComponent,
  ObjectReferenceLink,
  PropertyItem,
  useObjectLinkCallback,
} from "../common/generic";
import { RowComponent, RowsComponent } from "../common/rows";
import * as common from "../common/types";
import { SchoolTypeLookupDef } from "../common/types";
import { ActivitiesListComponent } from "./activity";
import { GradesListComponent } from "./grade";
import ExplorerHeader from "./ExplorerHeader";
import { StudyplansListComponent } from "./studyplan";
import { PersonsListComponent } from "./PersonsListComponent";

const LongEmail = styled("div")(() => ({
  wordBreak: "break-word",
}));

export const SecurityMarking = styled(Paper)(() => ({
  color: "white",
  backgroundColor: "red",
  padding: 20,
  marginTop: 20,
}));

const PersonComponent = (props: RowData<common.PersonExpanded>) => {
  const user = props.row;

  if (user?.securityMarking && user?.securityMarking !== "Ingen") {
    return (
      <SecurityMarking elevation={1}>
        <Typography variant="h4">Observera: {user.securityMarking}</Typography>
        <PersonComponentInner {...props} />
      </SecurityMarking>
    );
  }
  return <PersonComponentInner {...props} />;
};

const PersonComponentInner = (props: RowData<common.PersonExpanded>) => {
  const rm = useRouteMatch();
  const sourceId = useSourceId();
  const rowClick = useObjectLinkCallback();

  if (!rm) {
    throw Error("RouteMatch not defined.");
  }

  const user = props.row;

  if (!user) {
    return <Typography variant="h4">No person found</Typography>;
  }

  return (
    <Grid container direction="row" spacing={3}>
      <DataBox
        data={user}
        title={`${user.givenName} ${
          user.middleName ? `(${user.middleName}) ` : ""
        }${user.familyName}`}
      >
        <DataBoxSection>
          <DataBoxBlock xs={4}>
            <PropertyItem title="National Id">
              {user.civicNo?.value} ({user.civicNo?.nationality})
            </PropertyItem>
            <PropertyItem
              title="EPPN"
              value={user?.eduPersonPrincipalNames?.join(", ")}
            />
          </DataBoxBlock>
          <DataBoxBlock xs={4}>
            <PropertyItem title="Identifiers">
              {user.externalIdentifiers?.length
                ? user.externalIdentifiers.map((ident, i: number) => (
                    <div key={i}>
                      {ident.value} ({ident.context})
                    </div>
                  ))
                : null}
            </PropertyItem>
            <PropertyItem title="Emails">
              {user.emails?.length
                ? user.emails.map((email: any, i: number) => (
                    <LongEmail key={i}>
                      {email.value} ({email.type})
                    </LongEmail>
                  ))
                : null}
            </PropertyItem>
          </DataBoxBlock>

          <DataBoxBlock xs={4}>
            <PropertyItem title="Status" value={user?.personStatus} />
            <PropertyItem title="Birth Date" value={user?.birthDate} />
          </DataBoxBlock>
        </DataBoxSection>
        <DataBoxSection>
          <DataBoxBlock xs={4}>
            <PropertyItem title="Sex" value={user?.sex} />
            <PropertyItem title="Photo" value={user?.photo} />
          </DataBoxBlock>
          <DataBoxBlock xs={4}>
            <PropertyItem title="Phone Numbers">
              {user.phoneNumbers?.length
                ? user.phoneNumbers.map((number, i) => (
                    <div key={i}>{`${number.value} (${number.type}) ${
                      number.mobile ? "[Mobile]" : ""
                    }`}</div>
                  ))
                : ""}
            </PropertyItem>
          </DataBoxBlock>
          <DataBoxBlock xs={4}>
            {user.addresses?.length
              ? user.addresses.map((address, i) => (
                  <PropertyItem title="Address">
                    {address.type ? (
                      <>
                        {address.type}
                        <br />
                      </>
                    ) : null}
                    <>
                      {[
                        address.streetAddress,
                        `${address.locality ?? ""} ${
                          address.postalCode ?? ""
                        }`.trim(),
                        address.country?.trim() ?? "",
                      ]
                        .filter((v) => v)
                        .map((v) => (
                          <>
                            {v}
                            <br />
                          </>
                        ))}
                    </>
                  </PropertyItem>
                ))
              : null}
          </DataBoxBlock>
        </DataBoxSection>
      </DataBox>

      <Grid item lg={6}>
        <ListGenericComponent
          title="Guardians"
          onRowClick={(evt, row) =>
            rowClick(evt, common.Target.persons, row?.person?.id)
          }
          options={{ filtering: false }}
          columns={[
            { title: "Name", render: (row) => row.person?.displayName ?? "" },
            {
              title: "Type",
              field: "relationType",
            },
          ]}
          data={user.responsibles ?? []}
        />
      </Grid>
      <Grid item lg={6}>
        <ListGenericComponent
          title="Children"
          onRowClick={(evt, row) =>
            rowClick(evt, common.Target.persons, row?.person?.id)
          }
          options={{ filtering: false }}
          columns={[
            { title: "Name", render: (row) => row.person?.displayName ?? "" },
            {
              title: "Type",
              field: "relationType",
            },
          ]}
          data={user._embedded?.responsibleFor ?? []}
        />
      </Grid>

      <ListGenericComponent<common.Enrolment>
        onRowClick={(evt, row) =>
          rowClick(evt, common.Target.organisations, row?.enroledAt?.id ?? "")
        }
        columns={[
          {
            title: "School",
            field: "enroledAt",
            render: (en) => en.enroledAt.displayName ?? "",
            customFilterAndSearch: (filter, row) =>
              row.enroledAt.displayName?.includes(filter) ?? false,
          },
          { title: "SchoolYear", field: "schoolYear" },
          {
            title: "Type",
            field: "schoolType",
            lookup: SchoolTypeLookupDef,
          },
          { title: "Start", field: "startDate", defaultSort: "asc" },
          { title: "End", field: "endDate" },
          { title: "EducationCode", field: "educationCode" },
          {
            title: "Programme",
            field: "programme",
            render: (row) => (
              <ObjectReferenceLink
                kind={common.Target.programmes}
                item={row.programme}
              />
            ),
            customFilterAndSearch: (filter, row) =>
              row.programme?.displayName?.includes(filter) ?? false,
          },
        ]}
        kind={common.Target.organisations}
        data={user.enrolments ?? []}
        title="Enrolments"
      />

      <Grid item lg={6}>
        <ListGenericComponent
          title="Student Groups"
          data={user._embedded?.groupMemberships ?? []}
          onRowClick={(evt, row?: common.GroupMembership) =>
            rowClick(evt, common.Target.groups, row?.group?.id ?? "")
          }
          columns={[
            {
              title: "Name",
              customFilterAndSearch: (filter, row) =>
                row.group?.displayName.includes(filter) ?? false,
              render: (row) => row.group?.displayName,
            },
            {
              title: "Type",
              render: (row) => row.group?.groupType,
              customFilterAndSearch: (filter, row) =>
                (filter as string[])?.includes(row.group?.groupType ?? "") ??
                false,
              lookup: {
                Undervisning: "Undervisning",
                Klass: "Klass",
                Mentor: "Mentor",
                Schema: "Schema",
                Avdelning: "Avdelning",
                Personalgrupp: "Personalgrupp",
                Övrigt: "Övrigt",
              },
            },
            { title: "Start", field: "startDate" },
            { title: "End", field: "endDate" },
          ]}
        />
      </Grid>

      <Grid item lg={6}>
        <RowsComponent
          apiCall={{
            target: common.Target.activities,
            method: common.Method.GET,
            sourceId,
            member: user.id,
          }}
          view={ActivitiesListComponent}
        />
      </Grid>

      <RowsComponent
        apiCall={{
          target: common.Target.grades,
          method: common.Method.GET,
          sourceId,
          student: user.id,
        }}
        view={GradesListComponent}
      />

      <RowsComponent
        apiCall={{
          target: common.Target.studyplans,
          method: common.Method.GET,
          sourceId,
          student: user.id,
        }}
        view={StudyplansListComponent}
      />

      <ListGenericComponent
        title="Placements"
        data={user?._embedded?.placements ?? []}
        onRowClick={(evt, row?: common.Placement) =>
          rowClick(evt, common.Target.organisations, row?.placedAt?.id ?? "")
        }
        columns={[
          {
            title: "School",
            customFilterAndSearch: (filter, row) =>
              row.placedAt?.displayName?.includes(filter) ?? false,
            render: (row) => row.placedAt?.displayName,
          },
          {
            title: "Group",
            render: (row) => row.group?.displayName,
            customFilterAndSearch: (filter, row) =>
              (filter as string[])?.includes(row.group?.displayName ?? "") ??
              false,
          },
          { title: "Reason", field: "reason" },
          {
            title: "Owners",
            customFilterAndSearch: (filter, row) =>
              row.owners?.some((o) => o.displayName?.includes(filter)) ?? false,
            render: (pl) =>
              pl.owners?.map((o) => (
                <ObjectReferenceLink kind={common.Target.persons} item={o} />
              )),
          },
          { title: "School Type", field: "schoolType" },
          { title: "Start", field: "startDate" },
          { title: "End", field: "endDate" },
        ]}
      />

      <ListGenericComponent
        title="Duties"
        onRowClick={(evt, row) =>
          rowClick(evt, common.Target.duties, row?.id ?? "")
        }
        data={user._embedded?.duties ?? []}
        columns={[
          {
            title: "Signature",
            field: "signature",
          },
          {
            title: "Organisation",
            render: (row) => row.dutyAt?.displayName,
            customFilterAndSearch: (filter, row) =>
              (filter as string[])?.includes(row.dutyAt?.displayName ?? "") ??
              false,
          },
          { title: "Role", field: "dutyRole" },
          { title: "Start", field: "startDate" },
          { title: "End", field: "endDate" },
        ]}
      />
    </Grid>
  );
};

export function Persons(): ReactElement {
  const rm = useRouteMatch();

  const ac: common.ApiCall = {
    sourceId: useSourceId(),
    target: common.Target.persons,
    method: common.Method.GET,
    expand: [
      "responsibleFor",
      "duties",
      "placements",
      "ownedPlacements",
      "groupMemberships",
    ],
  };

  if (!rm) {
    throw Error("RouteMatch not defined.");
  }

  return (
    <>
      <ExplorerHeader typeLink={rm.path} typeName="Person"></ExplorerHeader>
      <Switch>
        <Route
          path={`${rm.path}/:personId`}
          render={({ match }) => (
            <RowComponent<common.PersonExpanded>
              apiCall={{
                ...ac,
                path: match.params.personId,
              }}
              view={PersonComponent}
            />
          )}
        />

        <Route
          path={`${rm.path}`}
          render={({ match, location }) => {
            if (location.search) {
              const search = qs.parse(location.search);

              if (search.ids) {
                const ids = (search.ids as string).split(",");

                return (
                  <RowsComponent<common.PersonExpanded>
                    apiCall={{
                      ...ac,
                      path: "lookup",
                      method: common.Method.POST,
                      body: { ids, norEduPersonNINs: [] },
                    }}
                    view={PersonsListComponent}
                  />
                );
              }
            }

            return (
              <MuiAlert elevation={3} variant="filled" severity="warning">
                Not found.
              </MuiAlert>
            );
          }}
        />
      </Switch>
    </>
  );
}
