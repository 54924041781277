import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import IconButton from "@mui/material/IconButton";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import ChevronRight from "@mui/icons-material/ChevronRight";
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import _ from "lodash";
import {
  Dispatch,
  ReactElement,
  SetStateAction,
  SyntheticEvent,
  useCallback,
  useMemo,
  useState,
} from "react";
import FilterInput from "common/FilterInput";
import filterRow from "common/filterRow";
import {
  Adjust,
  Section,
  useObjectReferenceCallback,
  ValidEndDateString,
  ValidStartDateString,
} from "common/generic";
import { GenericListViewProps } from "common/rows";
import { TableClickableRow, TableHeadTextRow } from "common/tableComponents";
import * as common from "common/types";

const constructTree = (orgs: common.Organisation[]) => {
  const map = new Map<string, common.Organisation[]>([
    ["ROOT", []],
    ["LEFTOVERS", []],
  ]);
  const ids = orgs.map((org) => org.id);
  for (const org of orgs) {
    const key = org.parentOrganisation?.id ?? "ROOT";
    // Detect dangling nodes
    if (key !== "ROOT" && !ids.includes(key)) {
      map.get("LEFTOVERS")?.push(org);
    } else {
      const list = map.get(key) ?? [];
      list.push(org);
      map.set(key, list);
    }
  }
  return new Map(
    Array.from(map.entries()).map((entry) => [
      entry[0],
      _.orderBy(entry[1], "displayName"),
    ])
  );
};

export interface FilterData {
  org?: string;
  type?: string;
  schoolType?: string[];
  code?: string;
  schoolUnitCode?: string;
  historical?: boolean;
}

export interface OrganisationTreeComponentProps
  extends GenericListViewProps<common.Organisation> {}
export function OrganisationTreeComponent(
  props: OrganisationTreeComponentProps
): ReactElement {
  const [filters, setFilters] = useState<FilterData>({ historical: false });

  const tree = useMemo(() => constructTree(props.data), [props.data]);
  const leftOvers = tree.get("LEFTOVERS") ?? [];

  return (
    <Section
      style={{ width: "fit-content" }}
      md="auto"
      name="Organisations"
      titleControls={
        <FormControlLabel
          style={{ marginLeft: "auto" }}
          control={
            <Checkbox
              checked={filters.historical}
              onChange={(evt) =>
                setFilters((val) => ({
                  ...val,
                  historical: evt.target.checked,
                }))
              }
              color="primary"
            />
          }
          label="Show historical organisations"
        />
      }
      loading={props.isLoading}
    >
      <Box style={{ overflow: "auto" }} pb={4}>
        <Table size="small" sx={{ width: "100%" }}>
          <TableHead>
            <TableHeadTextRow>
              <TableCell style={{ paddingLeft: 24, width: "40%" }}>
                Organisations
              </TableCell>
              <TableCell>Type</TableCell>
              <TableCell>SchoolType(s)</TableCell>
              <TableCell>Code</TableCell>
              <TableCell>SchoolUnitCode</TableCell>
              <TableCell style={{ minWidth: "auto" }}>Start</TableCell>
              <TableCell style={{ minWidth: "auto" }}>End</TableCell>
            </TableHeadTextRow>
            <TableClickableRow>
              <TableCell style={{ paddingLeft: 24 }}>
                <FilterInput
                  filters={filters}
                  setFilters={setFilters}
                  field="org"
                  title="Filter By Organisation Name"
                />
              </TableCell>
              <TableCell>
                <FilterInput
                  filters={filters}
                  setFilters={setFilters}
                  field="type"
                  title="Filter By Organisation Type"
                />
              </TableCell>
              <TableCell>
                <SchoolTypeSelect filters={filters} setFilters={setFilters} />
              </TableCell>
              <TableCell>
                <FilterInput
                  filters={filters}
                  setFilters={setFilters}
                  field="code"
                  title="Filter By Code"
                />
              </TableCell>
              <TableCell>
                <FilterInput
                  filters={filters}
                  setFilters={setFilters}
                  field="schoolUnitCode"
                  title="Filter By SchoolUnit Code"
                />
              </TableCell>
              <TableCell />
              <TableCell />
            </TableClickableRow>
          </TableHead>
          <TableBody>
            {(tree.get("ROOT") ?? []).map((row) => (
              <RowAndChildren
                key={row.id}
                {...props}
                filters={filters}
                organisation={row}
                tree={tree}
                depth={0}
              />
            ))}
            {leftOvers.length >= 1 ? (
              <>
                <TableRow>
                  <TableCell>
                    <strong>
                      Organisations with parents that weren't found.
                    </strong>
                  </TableCell>
                </TableRow>
                {leftOvers.map((row) => (
                  <RowAndChildren
                    key={row.id}
                    {...props}
                    organisation={row}
                    tree={tree}
                    depth={0}
                    filters={filters}
                  />
                ))}
              </>
            ) : null}
          </TableBody>
        </Table>
      </Box>
    </Section>
  );
}

export interface SchoolTypeSelectProps {
  filters: FilterData;
  setFilters: Dispatch<SetStateAction<FilterData>>;
}

export function SchoolTypeSelect({
  filters,
  setFilters,
}: SchoolTypeSelectProps): ReactElement {
  return (
    <FormControl style={{ width: "100%" }}>
      <Select
        multiple
        value={filters.schoolType ?? []}
        onChange={(event) => {
          setFilters((filter) => ({
            ...filter,
            schoolType: event.target?.value as string[] | undefined,
          }));
        }}
        variant="outlined"
        renderValue={(selecteds) =>
          (selecteds as Array<keyof typeof common.SchoolTypeLookupDef>)
            .map((selected) => common.SchoolTypeLookupDef[selected])
            .join(", ")
        }
        style={{ marginTop: 0, maxWidth: 150 }}
      >
        {Object.keys(common.SchoolTypeLookupDef).map((key) => (
          <MenuItem key={key} value={key}>
            <Checkbox
              checked={
                (filters?.schoolType?.indexOf(key.toString()) ?? -1) > -1
              }
            />
            <ListItemText
              primary={
                common.SchoolTypeLookupDef[
                  key as keyof typeof common.SchoolTypeLookupDef
                ]
              }
            />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

interface RowAndChildrenProps {
  depth: number;
  filters: FilterData;
  organisation: common.Organisation;
  tree: Map<string, common.Organisation[]>;
}

function RowAndChildren(props: RowAndChildrenProps): ReactElement {
  const persistanceId = `org_tree_${props.depth}_${props.organisation.id}`;
  const link = useObjectReferenceCallback(common.Target.organisations);
  const field = props.organisation.id;
  const children = props.tree.get(field) ?? [];
  const visible = filterRow(props.organisation, props.filters);

  const [unfolded, setUnFolded] = useState<Set<string>>(() => {
    try {
      const persistanceItem = localStorage.getItem(persistanceId);
      if (persistanceItem) {
        return new Set(JSON.parse(persistanceItem) as string[]);
      }
    } catch (e) {}

    if (props.depth < 1) {
      return new Set([field]);
    }
    return new Set();
  });

  const updateFolded = useCallback(
    (e: SyntheticEvent) => {
      e.preventDefault();
      e.stopPropagation();
      if (unfolded.has(field)) {
        unfolded.delete(field);
      } else {
        unfolded.add(field);
      }
      localStorage.setItem(persistanceId, JSON.stringify(Array.from(unfolded)));
      // Make sure that the set changes!
      setUnFolded(new Set(unfolded));
    },
    [field, unfolded, persistanceId]
  );

  const opened = unfolded.has(field);

  return (
    <>
      {visible ? (
        <TableClickableRow
          key={props.organisation.id}
          onClick={(e) => link(e, props.organisation)}
        >
          <TableCell
            style={{ paddingLeft: props.depth * 16, whiteSpace: "nowrap" }}
          >
            <div
              style={{
                width: 32,
                display: "inline-block",
                marginTop: -6,
                height: 14,
              }}
            >
              {children.length > 0 ? (
                <Adjust y={-2}>
                  <IconButton onClick={updateFolded} size="small">
                    {opened ? <KeyboardArrowDown /> : <ChevronRight />}{" "}
                  </IconButton>
                </Adjust>
              ) : null}
            </div>
            {props.organisation.displayName}
          </TableCell>
          <TableCell>{props.organisation.organisationType ?? ""}</TableCell>
          <TableCell>
            {props.organisation.schoolTypes?.join(", ") ?? ""}
          </TableCell>
          <TableCell>{props.organisation.organisationCode ?? ""}</TableCell>
          <TableCell>{props.organisation.schoolUnitCode ?? ""}</TableCell>
          <TableCell>
            <ValidStartDateString start={props.organisation.startDate} />
          </TableCell>
          <TableCell>
            <ValidEndDateString end={props.organisation.endDate} />
          </TableCell>
        </TableClickableRow>
      ) : null}
      {(opened || !visible) &&
        children.map((childRow) => {
          return (
            <RowAndChildren
              key={childRow.id}
              {...props}
              organisation={childRow}
              depth={props.depth + 1}
            />
          );
        })}
    </>
  );
}
