import Box from "@mui/material/Box";
import Grid, { GridProps } from "@mui/material/Grid";
import type { Theme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { ReactElement } from "react";

export function StylistPageGrid(props: GridProps): ReactElement {
  const large = useMediaQuery((theme: Theme) => theme.breakpoints.up("lg"));
  const medium = useMediaQuery((theme: Theme) => theme.breakpoints.up("md"));

  const paddingTop = large ? 3 : medium ? 4 : 2;
  const paddingBottom = large ? 3 : medium ? 4 : 0;
  const paddingX = large ? 6 : medium ? 4 : 2;

  return (
    <Box
      pt={paddingTop}
      px={paddingX}
      pb={paddingBottom}
      style={{ flexGrow: 1 }}
    >
      <Grid container spacing={large ? 3 : 2} {...props}>
        {props.children}
      </Grid>
    </Box>
  );
}
