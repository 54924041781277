import { SessionContext } from "@ist-group-private-scope/web-skolid";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { useCallback, useContext, useEffect, useState } from "react";
import { ReactElement } from "react";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";
import { search, SearchResultItem } from "../common/api";
import { ListGenericComponent, useObjectLinkCallback } from "../common/generic";
import { Target } from "../common/types";

const kindTexts: Record<Target, string> = {
  [Target.activities]: "Activity",
  [Target.duties]: "Duty",
  [Target.grades]: "Grade",
  [Target.groups]: "Group",
  [Target.organisations]: "Organisation",
  [Target.persons]: "Person",
  [Target.placements]: "Placement",
  [Target.studyplans]: "Studyplan",
  [Target.syllabuses]: "Syllabus",
  [Target.programmes]: "Programme",
};

export default function SearchResults(): ReactElement {
  const match = useRouteMatch<{
    customerId: string;
    sourceId: string;
    searchTerm: string;
  }>("/explorer/:customerId/:sourceId/search/:searchTerm");
  const clientId = new URLSearchParams(useLocation().search).get("clientId");
  const { sourceId, searchTerm, customerId } = match?.params ?? {};
  const history = useHistory();
  const onClick = useObjectLinkCallback();
  const session = useContext(SessionContext);
  const jwt = (session.user && session.user.access_token) || undefined;

  const [results, setResults] = useState<SearchResultItem[]>();

  useEffect(() => {
    if (jwt && sourceId && searchTerm) {
      search(jwt, sourceId, searchTerm, clientId).then((d) => {
        setResults(d);
      });
    }
  }, [jwt, sourceId, searchTerm, clientId]);

  const searchPerson = useCallback(() => {
    history.push(
      `/explorer/${customerId}/${sourceId}/name-search/${encodeURIComponent(
        searchTerm ?? ""
      )}`
    );
  }, [history, customerId, searchTerm, sourceId]);

  if (!match) {
    return <div>Use the search box at the top</div>;
  }

  if (!Array.isArray(results)) {
    return <strong>Loading</strong>;
  }

  if (!results.length) {
    return (
      <>
        <Typography variant="h2">No results found</Typography>
        No results found for "{searchTerm}".
        <br />
        Do you want to search for persons with a name matching "{searchTerm}"?
        <Box mt={3}>
          <Button variant="contained" color="primary" onClick={searchPerson}>
            Search by person name
          </Button>
        </Box>
      </>
    );
  }

  return (
    <ListGenericComponent
      title="Search result"
      columns={[
        { title: "Id", field: "id" },
        {
          title: "Type",
          field: "kind",
          render: (row) => kindTexts[row.kind],
        },
        {
          title: "Namn",
          field: "displayName",
          headerStyle: { width: "70%" },
        },
      ]}
      options={{ filtering: false, tableLayout: "auto" }}
      data={results}
      onRowClick={(e, row) => onClick(e, row?.kind, row?.id)}
    />
  );
}
