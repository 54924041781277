import { ReactElement, useMemo } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import { useSourceId } from "../common";
import {
  DataBox,
  DataBoxBlock,
  DataBoxSection,
  ListGenericComponent,
  ObjectReferenceLink,
  PropertyItem,
  PropertyItemDateRange,
  useObjectReferenceCallback,
  useObjectLinkCallback,
} from "../common/generic";
import {
  GenericListViewProps,
  RowComponent,
  RowsComponent,
} from "../common/rows";
import * as common from "../common/types";
import { ActivitiesListComponent } from "./activity";
import ExplorerHeader from "./ExplorerHeader";
import { Column } from "@material-table/core";

interface SingleGroupProps {
  kind: common.Target;
  row?: common.GroupExpanded | null;
}

function SingleGroup({ row: group }: SingleGroupProps): ReactElement {
  const sourceId = useSourceId();
  const rowPersonClick = useObjectReferenceCallback(common.Target.persons);
  const rowClick = useObjectLinkCallback();

  if (!group) {
    return <Typography variant="h2">No student group found</Typography>;
  }

  return (
    <Grid
      container
      spacing={3}
      alignItems="stretch"
      alignContent="stretch"
      style={{
        flexGrow: 1,
      }}
    >
      <DataBox data={group} title={group.displayName}>
        <DataBoxSection>
          <DataBoxBlock>
            <PropertyItem title="Organisation">
              <ObjectReferenceLink
                kind={common.Target.organisations}
                item={group.organisation}
              />
              ,
            </PropertyItem>
            <PropertyItem title="Group Type">{group.groupType}</PropertyItem>
          </DataBoxBlock>
          <DataBoxBlock>
            <PropertyItemDateRange
              title="Valid"
              start={group.startDate}
              end={group.endDate}
            />
          </DataBoxBlock>
        </DataBoxSection>
      </DataBox>
      <ListGenericComponent<common.GroupMembership>
        data={group.groupMemberships ?? []}
        name={(row) => row.person?.displayName ?? "???"}
        onRowClick={(evt, row) => rowPersonClick(evt, row?.person)}
        columns={[
          {
            title: "Namn",
            field: "displayName",
          },
          { title: "Start", field: "startDate" },
          { title: "Stop", field: "endDate" },
        ]}
        title="Members"
      />
      <Grid item lg={6}>
        <RowsComponent
          apiCall={{
            target: common.Target.activities,
            method: common.Method.GET,
            sourceId,
            group: group.id,
          }}
          view={ActivitiesListComponent}
        />
      </Grid>
      <Grid item lg={6}>
        <ListGenericComponent<common.GroupAssignmentRole>
          data={group._embedded?.assignmentRoles ?? []}
          name={(row) => row.duty?.displayName ?? "???"}
          onRowClick={(evt, row) =>
            rowClick(evt, common.Target.duties, row?.duty?.id)
          }
          columns={[
            {
              title: "Name",
              field: "displayName",
            },
            { title: "Typ", field: "assignmentRoleType" },
            { title: "Start", field: "startDate" },
            { title: "Stop", field: "endDate" },
          ]}
          title="Assigned duty roles"
        />
      </Grid>
    </Grid>
  );
}
interface GroupRow extends common.Group {
  status: "Expired" | "Active" | "Upcoming";
}

function renderGroupMembershipCount(groupRow: GroupRow) {
  return String(groupRow.groupMemberships?.length ?? 0);
}

const groupsListColumns: Column<GroupRow>[] = [
  {
    title: "Name",
    field: "displayName",
  },
  {
    title: "Type",
    field: "groupType",
    defaultSort: "desc",
    lookup: {
      Avdelning: "Avdelning",
      Klass: "Klass",
      Mentor: "Mentor",
      Personalgrupp: "Personalgrupp",
      Schema: "Schema",
      Undervisning: "Undervisning",
      Övrigt: "Övrigt",
    },
  },
  {
    title: "Members",
    field: "groupMemberships",
    render: renderGroupMembershipCount,
  },
  {
    title: "Status",
    field: "status",
    defaultSort: "desc",
    defaultFilter: ["Active"],
    lookup: {
      Expired: "Expired",
      Active: "Active",
      Upcoming: "Upcoming",
    },
  },
];

export const GroupsListComponent = (
  props: GenericListViewProps<common.Group>
) => {
  const click = useObjectReferenceCallback(common.Target.groups);

  const { data } = props;
  const rows = useMemo(
    () =>
      data.map((group) => {
        // TODO: Use the data source's country's time zone instead of the browser's time zone.
        // It might be best to wait for the TC39 Temporal API to achieve that since Date
        // doesn't have good support for time zones.
        const today = new Date().toISOString().split("T")[0];

        let status: "Expired" | "Active" | "Upcoming" = "Upcoming";

        if (
          group.startDate <= today &&
          (group.endDate === undefined || group.endDate >= today)
        ) {
          status = "Active";
        } else if (group.startDate > today) {
          status = "Upcoming";
        } else {
          status = "Expired";
        }

        return {
          ...group,
          status,
        };
      }),
    [data]
  );

  return (
    <ListGenericComponent<GroupRow>
      onRowClick={click}
      columns={groupsListColumns}
      {...props}
      data={rows}
    />
  );
};

export function Groups(): ReactElement {
  const rm = useRouteMatch();

  const ac: common.ApiCall = {
    sourceId: useSourceId(),
    target: common.Target.groups,
    method: common.Method.GET,
  };

  if (!rm) {
    throw Error("RouteMatch not defined.");
  }

  return (
    <>
      <ExplorerHeader typeLink={rm.path} typeName="Group"></ExplorerHeader>
      <Switch>
        <Route
          path={`${rm.path}/:groupId`}
          render={({ match }) => (
            <RowComponent<common.GroupExpanded>
              apiCall={{
                ...ac,
                path: match.params.groupId,
                expand: ["assignmentRoles"],
              }}
              view={SingleGroup}
            />
          )}
        />

        <Route path={`${rm.path}`}>
          <RowsComponent<common.Group>
            apiCall={ac}
            view={GroupsListComponent}
          />
        </Route>
      </Switch>
    </>
  );
}
