import { ReactElement, ReactNode, useCallback, useState } from "react";
import AccountsHeader from "./AccountsHeader";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import styled from "@mui/material/styles/styled";
import MaterialTable from "@material-table/core";
import {
  EducloudUserRoleAssignment,
  describeEducloudRole,
} from "common/educloudRoles";
import ConfirmDialog from "common/ConfirmDialog";

const UnassignButton = styled(Button)(() => ({
  height: 36,
}));

export interface AccountsAccessEditorPresentationProps {
  newAssignmentsForm: ReactNode;
  loading: boolean;
  onUnassign: (assignment: EducloudUserRoleAssignment) => void;
  subjectName?: string;
  roleAssignments: EducloudUserRoleAssignment[];
}

export default function AccountsAccessEditorPresentation({
  loading,
  newAssignmentsForm,
  onUnassign,
  roleAssignments,
  subjectName,
}: AccountsAccessEditorPresentationProps): ReactElement | null {
  const [unconfirmedUnassigment, setUnconfirmedUnassigment] =
    useState<EducloudUserRoleAssignment>();

  const handleCancelUnassignment = useCallback(() => {
    setUnconfirmedUnassigment(undefined);
  }, []);

  const handleConfirmUnassignment = useCallback(() => {
    if (unconfirmedUnassigment) {
      setUnconfirmedUnassigment(undefined);
      onUnassign(unconfirmedUnassigment);
    }
  }, [onUnassign, unconfirmedUnassigment]);

  return (
    <>
      <ConfirmDialog
        confirmButtonContent={`Remove access`}
        onCancel={handleCancelUnassignment}
        onConfirm={handleConfirmUnassignment}
        open={!!unconfirmedUnassigment}
      >
        Do you want to remove <strong>{unconfirmedUnassigment?.name}</strong> as{" "}
        {describeEducloudRole(unconfirmedUnassigment?.role)}?
      </ConfirmDialog>

      <AccountsHeader />

      <Box pt={8}>
        <Paper elevation={1}>
          <MaterialTable
            title={
              <Typography variant="h3">
                Manage Accounts {subjectName ? `for ${subjectName}` : ""}
              </Typography>
            }
            isLoading={loading}
            columns={[
              {
                field: "name",
                title: "User",
              },
              {
                field: "emailAddress",
                title: "Email",
              },
              {
                field: "role",
                title: "Role",
              },
              {
                title: "Remove access",
                cellStyle: {
                  padding: 8,
                },
                render: (assignment) => (
                  <UnassignButton
                    onClick={() => setUnconfirmedUnassigment(assignment)}
                  >
                    Remove user access
                  </UnassignButton>
                ),
              },
            ]}
            options={{
              minBodyHeight: 140,
              filtering: false,
              paging: false,
              emptyRowsWhenPaging: false,
              grouping: false,
              overflowY: "auto",
              maxBodyHeight: 400,
            }}
            data={roleAssignments}
            components={{
              Container: (props) => (
                <Paper {...props} elevation={1}>
                  {props.children}
                </Paper>
              ),
            }}
          />

          {newAssignmentsForm}
        </Paper>
      </Box>
    </>
  );
}
