import { ReactElement } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import MuiAlert from "@mui/material/Alert";
import { Route, Switch, useParams, useRouteMatch } from "react-router-dom";
import { useSourceId } from "../common";
import { RowData } from "../common/api";
import {
  DataBox,
  DataBoxBlock,
  DataBoxSection,
  ObjectReferenceLink,
  PropertyItem,
  PropertyItemDateRange,
  Section,
  SimpleTableWithClick,
  useObjectReferenceCallback,
} from "../common/generic";
import { RowComponent, RowsComponent } from "../common/rows";
import * as common from "../common/types";
import { ActivitiesListComponent } from "./activity";
import ExplorerHeader from "./ExplorerHeader";

function DutyComponent({ row: duty }: RowData<common.Duty>): ReactElement {
  const rm = useRouteMatch();
  const { sourceId } = useParams<{ sourceId: string }>();
  const click = useObjectReferenceCallback(common.Target.groups);

  if (!rm) {
    throw Error("RouteMatch not defined.");
  }

  if (!duty) {
    return <Typography variant="h4">No duty found</Typography>;
  }

  return (
    <Grid container direction="row" spacing={3}>
      <DataBox
        data={duty}
        title={`${duty.signature} (${duty.person?.displayName})`}
      >
        <DataBoxSection>
          <DataBoxBlock>
            <PropertyItem title="Signature" value={duty.signature} />
            <PropertyItem title="Duty at">
              <ObjectReferenceLink
                kind={common.Target.organisations}
                item={duty.dutyAt}
              />
            </PropertyItem>
          </DataBoxBlock>
          <DataBoxBlock>
            <PropertyItem title="Person">
              <ObjectReferenceLink
                kind={common.Target.persons}
                item={duty.person}
              />
            </PropertyItem>
            <PropertyItem title="Duty %" value={duty.dutyPercent} />
          </DataBoxBlock>
          <DataBoxBlock>
            <PropertyItem title="Hours/Year" value={duty.hoursPerYear} />
            <PropertyItem title="Role" value={duty.dutyRole} />
          </DataBoxBlock>
        </DataBoxSection>
        <DataBoxSection>
          <DataBoxBlock>
            <PropertyItemDateRange
              title="Valid"
              start={duty.startDate}
              end={duty.endDate}
            />
          </DataBoxBlock>
        </DataBoxSection>
      </DataBox>

      <Section name="Assignment roles" lg={6}>
        <SimpleTableWithClick
          noRowsMsg="No assigned roles"
          cols={["Group", "Role", "Start", "End"]}
          onRowClick={(evt, id) => click(evt, { id })}
          data={
            duty.assignmentRole?.map((re) => ({
              id: re.group?.id ?? "",
              row: [
                re.group.displayName,
                re.assignmentRoleType ?? "Okänd",
                re.startDate,
                re.endDate,
              ],
            })) ?? []
          }
        />
      </Section>
      <Grid item md={6}>
        <RowsComponent
          apiCall={{
            target: common.Target.activities,
            method: common.Method.GET,
            sourceId,
            teacher: duty.id,
          }}
          view={ActivitiesListComponent}
        />
      </Grid>
    </Grid>
  );
}

export function Duties(): ReactElement {
  const rm = useRouteMatch();

  const ac: common.ApiCall = {
    sourceId: useSourceId(),
    target: common.Target.duties,
    method: common.Method.GET,
  };

  if (!rm) {
    throw Error("RouteMatch not defined.");
  }

  return (
    <>
      <ExplorerHeader typeLink={rm.path} typeName="Duty"></ExplorerHeader>
      <Switch>
        <Route
          path={`${rm.path}/:dutyId`}
          render={({ match }) => (
            <RowComponent<common.Duty>
              apiCall={{
                ...ac,
                path: match.params.dutyId,
              }}
              view={DutyComponent}
            />
          )}
        />

        <Route path={`${rm.path}/`}>
          <MuiAlert elevation={3} variant="filled" severity="warning">
            Not found.
          </MuiAlert>
        </Route>
      </Switch>
    </>
  );
}
