import Container from "@mui/material/Container";

import { Route, Switch, Redirect } from "react-router-dom";
import appSections, { redirectToAt404 } from "./appSections";

export function AppContent() {
  return (
    <Container fixed maxWidth={"xl"} style={{ marginBottom: 64 }}>
      <Switch>
        {appSections.map(({ Content, path }, index) => (
          <Route path={path} key={index}>
            <Content />
          </Route>
        ))}
        <Redirect to={redirectToAt404} />
      </Switch>
    </Container>
  );
}
