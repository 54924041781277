import * as common from "common/types";

export enum OrgNodeType {
  Child,
  Orphan, // Missing parent unit
  Top,
}

export type OrgNode = {
  children: OrgNode[];
  depth: number;
  organisation: common.Organisation;
} & (
  | {
      nodeType: OrgNodeType.Child;
      parent: OrgNode;
    }
  | {
      nodeType: OrgNodeType.Orphan | OrgNodeType.Top;
      parent: undefined;
    }
);

export function constructNodes(organisations: common.Organisation[]): {
  topNodes: OrgNode[];
  orphans: OrgNode[]; // Nodes missing a parent unit
} {
  const allById = new Map(
    [...organisations]
      .sort((a, b) => a.displayName.localeCompare(b.displayName))
      .map<[id: string, node: OrgNode]>((organisation) => [
        organisation.id,
        {
          children: [],
          depth: 0,
          nodeType: OrgNodeType.Orphan,
          organisation,
          parent: undefined,
        },
      ])
  );

  const topNodes: OrgNode[] = [];
  const orphans: OrgNode[] = [];
  for (const node of allById.values()) {
    const parentId = node.organisation.parentOrganisation?.id;
    if (!parentId) {
      node.nodeType = OrgNodeType.Top;
      topNodes.push(node);
    } else {
      const parent = allById.get(parentId);
      if (parent) {
        node.nodeType = OrgNodeType.Child;
        node.parent = parent;
        parent.children.push(node);
      } else {
        orphans.push(node);
      }
    }
  }

  traverseOrgNodesDepthFirst(topNodes, (node) => {
    // eslint-disable-next-line no-param-reassign
    node.depth = (node.parent?.depth ?? -1) + 1;
  });

  traverseOrgNodesDepthFirst(orphans, (node) => {
    // eslint-disable-next-line no-param-reassign
    node.depth = (node.parent?.depth ?? 0) + 1;
  });
  return { topNodes, orphans };
}

export function traverseOrgNodesDepthFirst(
  nodes: OrgNode[],
  callback: (node: OrgNode) => void
) {
  for (const node of nodes) {
    callback(node);
    traverseOrgNodesDepthFirst(node.children, callback);
  }
}

export function traverseOrgNodeAncestors(
  node: OrgNode | undefined,
  callback: (node: OrgNode) => boolean | void
) {
  if (node) {
    const continueTraversal = callback(node) ?? true;
    if (continueTraversal) {
      traverseOrgNodeAncestors(node.parent, callback);
    }
  }
}
