import { ReactElement, useEffect, useState } from "react";
import useTheme from "@mui/material/styles/useTheme";
import { settings } from "../settings";
import "rapidoc";

export interface RapiDocProps {
  specUrl: string;
  clientId?: string;
  jwt?: string | null;
  sourceId?: string;
}

export function RapiDoc({
  clientId,
  jwt,
  sourceId,
  specUrl,
}: RapiDocProps): ReactElement {
  const theme = useTheme();

  const serverUrl = sourceId
    ? `${settings.apiHost}/source/${sourceId}/v2.0/`
    : "";

  const [rapiDocElement, setRapiDocElement] = useState<HTMLElement | null>(
    null
  );
  useEffect(() => {
    if (rapiDocElement) {
      const handleBeforeTry = ({
        detail: {
          request: { headers },
        },
      }: Event & { detail: { request: Request } }) => {
        headers.append("impersonate-client-id", String(clientId));
        headers.set("Authorization", "Bearer " + jwt);
      };

      rapiDocElement.addEventListener("before-try" as any, handleBeforeTry);
      return () =>
        rapiDocElement.removeEventListener(
          "before-try" as any,
          handleBeforeTry
        );
    }
  }, [clientId, jwt, rapiDocElement]);

  return (
    <rapi-doc
      ref={setRapiDocElement}
      key={sourceId}
      api-key-name="Authorization"
      api-key-location="header"
      api-key-value="Bearer <FETCH FROM https://skolid.se/connect/token>"
      heading-text="SS12000 Cache"
      api-list-style="group-by-path"
      regular-font={theme.typography.fontFamily}
      render-style="view"
      show-header="false"
      show-info="true"
      allow-authentication="false"
      allow-server-selection="false"
      allow-api-list-style-selection="true"
      allow-search="false"
      schema-description-expanded="true"
      schema-expand-level="3"
      allow-spec-file-load="false"
      allow-spec-url-load="false"
      allow-try={jwt && sourceId ? "true" : "false"}
      schema-style="table"
      spec-url={settings.apiHost + specUrl}
      server-url={serverUrl}
      default-api-server={serverUrl}
      /////////// Colours \/
      // The dark RapiDoc theme looks awesome, but unfortunately it doesn't fit the look of the rest of our app
      theme="light"
      bg-color={theme.palette.background.paper}
      primary-color={theme.palette.primary.main}
      nav-hover-text-color={theme.palette.primary.dark}
      /////////// Colours /\
    />
  );
}
