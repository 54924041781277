import {
  OrgNode,
  traverseOrgNodeAncestors,
  traverseOrgNodesDepthFirst,
} from "./orgNodes";

export enum PermissionChoice {
  Exclude = 1,
  Include = 2,
}

export interface DenormalizedChoiceInfo {
  choice?: PermissionChoice; // Choice made for this node or an ancestor
  choiceIsInhertied: boolean;
  subtreeContainsChoices: boolean;
}

export function denormalizeChoices(
  explicitChoices: Map<string, PermissionChoice>,
  topNodes: OrgNode[],
  orphans: OrgNode[]
) {
  const results = new Map<OrgNode, DenormalizedChoiceInfo>();

  traverseOrgNodesDepthFirst([...topNodes, ...orphans], (node: OrgNode) => {
    const choiceMadeForThisNode = explicitChoices.get(node.organisation.id);
    const inheritedChoice = (node.parent && results.get(node.parent))?.choice;

    results.set(node, {
      choice: choiceMadeForThisNode ?? inheritedChoice,
      choiceIsInhertied:
        choiceMadeForThisNode === undefined && inheritedChoice !== undefined,
      subtreeContainsChoices: false,
    });

    if (choiceMadeForThisNode !== undefined) {
      traverseOrgNodeAncestors(node, (ancestor) => {
        const ancestorResult = results.get(ancestor);
        if (!ancestorResult || ancestorResult.subtreeContainsChoices) {
          return false;
        }
        ancestorResult.subtreeContainsChoices = true;
      });
    }
  });

  return results;
}

export interface Access {
  includes: string[];
  excludes: string[];
}

export function choicesToAccess(
  choices: Map<string, PermissionChoice>
): Access {
  const excludes = [];
  const includes = [];
  for (const [organisationId, choice] of choices) {
    if (choice === PermissionChoice.Exclude) {
      excludes.push(organisationId);
    } else {
      includes.push(organisationId);
    }
  }
  return { excludes, includes };
}

export function accessToChoices({
  excludes,
  includes,
}: Access): Map<string, PermissionChoice> {
  const choices = new Map<string, PermissionChoice>();
  for (const organisationId of excludes) {
    choices.set(organisationId, PermissionChoice.Exclude);
  }
  for (const organisationId of includes) {
    choices.set(organisationId, PermissionChoice.Include);
  }
  return choices;
}
