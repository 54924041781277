export default function downloadText(text: string, filename: string): void {
  const url = URL.createObjectURL(
    new Blob([text], {
      endings: "native",
      type: "text/plain;charset=utf-8",
    })
  );

  try {
    const link = document.createElement("a");
    link.download = filename;
    link.href = url;
    link.click();
  } finally {
    // Revoking the URL right after link.click() should be safe
    // See https://bugzilla.mozilla.org/show_bug.cgi?id=1282407 for a discussion on the topic
    URL.revokeObjectURL(url);
  }
}
