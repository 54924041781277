import { ReactElement, useCallback, useMemo, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Snackbar from "@mui/material/Snackbar";
import Typography from "@mui/material/Typography";
import styled from "@mui/material/styles/styled";
import UserSelect, {
  UserSelectFormData,
  UserSelectProps,
  UserSelectResult,
} from "./UserSelect";
import { EducloudRole, describeEducloudRole } from "common/educloudRoles";

export interface NewRoleAssignmentFormProps {
  onSubmit: (role: EducloudRole, userId: string) => void;
  roles: EducloudRole[];
  userAndRoleAlreadyInList: (role: EducloudRole, userId: string) => boolean;
}

const FormSection = styled("div")(({ theme }) => ({
  display: "inline-block",
  margin: theme.spacing(1, 21, 1, 1),
  verticalAlign: "top",
}));
export default function NewRoleAssignmentForm({
  onSubmit,
  roles,
  userAndRoleAlreadyInList,
}: NewRoleAssignmentFormProps): ReactElement {
  const [userSelectFormData, setUserSelectFormData] =
    useState<UserSelectFormData>();
  const [user, setUser] = useState<UserSelectResult>();
  const [role, setRole] = useState<EducloudRole>(EducloudRole.Admin);
  const [showSnackBar, setShowSnackbar] = useState<boolean>(false);
  const [updatedMessage, setUpdatedMessage] = useState<string>();

  const handleUserSelectChange: UserSelectProps["onChange"] = useCallback(
    (newUserSelectFormData, newUser) => {
      setUserSelectFormData(newUserSelectFormData);
      setUser(newUser);
    },
    []
  );

  const handleSnackbarClose = useCallback(() => {
    setShowSnackbar(false);
  }, []);

  const handleSubmit = useCallback(() => {
    if (user) {
      setUpdatedMessage(user.name + " was added with role: " + role);
      setShowSnackbar(true);
      onSubmit(role, user.id);
      setUserSelectFormData(undefined);
      setUser(undefined);
    }
  }, [onSubmit, role, user]);

  const duplicate = useMemo(
    () => !!user && userAndRoleAlreadyInList(role, user.id),
    [userAndRoleAlreadyInList, user, role]
  );

  let submitButtonText: string;
  if (duplicate) {
    submitButtonText = `${
      user?.name ?? user?.id ?? "user"
    } already has that role`;
  } else {
    submitButtonText = `Make ${
      user?.name ?? user?.id ?? "user"
    } ${describeEducloudRole(role)}`;
  }

  return (
    <Box pt={8} padding={4}>
      <Typography variant="h3">Add User</Typography>
      <div>
        <FormSection>
          <UserSelect
            formData={userSelectFormData}
            onChange={handleUserSelectChange}
          />
        </FormSection>
        <FormSection>
          <InputLabel id="roleLabel">Role</InputLabel>
          {roles.length < 2 ? (
            describeEducloudRole(role)
          ) : (
            <Select
              defaultValue={EducloudRole.Admin}
              labelId="roleLabel"
              onChange={(event) => setRole(event.target.value as EducloudRole)}
              variant="standard"
            >
              {roles.map((role) => (
                <MenuItem key={role} value={role}>
                  {describeEducloudRole(role)}
                </MenuItem>
              ))}
            </Select>
          )}
        </FormSection>
        <Snackbar
          message={updatedMessage}
          open={showSnackBar}
          autoHideDuration={3000}
          onClose={handleSnackbarClose}
        ></Snackbar>
      </div>
      <Button disabled={duplicate || !role || !user} onClick={handleSubmit}>
        {submitButtonText}
      </Button>
    </Box>
  );
}
