import { ReactElement } from "react";
import { validDateFilter } from "../common";
import { ListGenericComponent, ObjectReferenceLink } from "../common/generic";
import { GenericListViewProps } from "../common/rows";
import * as common from "../common/types";
import { compareEmbeddedPersonDisplayNames } from "common/personNames";
import rowValidDateFilterStyle from "./rowValidDateFilterStyle";
import { Column } from "@material-table/core";

export interface DutiesListComponentProps
  extends GenericListViewProps<common.DutyExpanded> {}

const dutyRolesLookup = Object.fromEntries(
  common.allDutyRoles.map((dr) => [dr, dr])
);

function renderDutySignature(duty: common.DutyExpanded) {
  return (
    <ObjectReferenceLink
      item={{ id: duty.id, displayName: duty.signature }}
      kind={common.Target.duties}
    />
  );
}

function renderPerson(duty: common.DutyExpanded) {
  return (
    <ObjectReferenceLink item={duty.person} kind={common.Target.persons} />
  );
}
const dutiesListColumns: Column<common.DutyExpanded>[] = [
  {
    title: "Duty",
    field: "signature",
    render: renderDutySignature,
  },
  {
    title: "Person",
    field: "person.displayName",
    defaultSort: "asc",
    customSort: compareEmbeddedPersonDisplayNames,
    render: renderPerson,
  },
  {
    title: "Role",
    field: "dutyRole",
    width: "22%",
    lookup: dutyRolesLookup,
  },
  {
    title: "Start",
    field: "startDate",
    cellStyle: rowValidDateFilterStyle,
  },
  {
    title: "End",
    field: "endDate",
    cellStyle: rowValidDateFilterStyle,
  },
];

export default function DutiesListComponent(
  props: DutiesListComponentProps
): ReactElement {
  return (
    <ListGenericComponent<common.DutyExpanded>
      checkboxFilter={{
        label: "Only current duties",
        condition: validDateFilter,
        defaultValue: true,
      }}
      columns={dutiesListColumns}
      {...props}
    />
  );
}
