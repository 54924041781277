import { SessionContext } from "@ist-group-private-scope/web-skolid";
import { ReactElement, useContext } from "react";
import { Route, Switch, useRouteMatch } from "react-router";
import SelectCustomerSourceList from "common/SelectCustomerSourceList";
import { Permissions } from "./Permissions";
import PermissionsHeader from "./PermissionsHeader";

export default function PermissionsContent(): ReactElement | null {
  const session = useContext(SessionContext);
  const match = useRouteMatch();

  if (session.loading || !session) {
    return null;
  }

  return (
    <Switch>
      <Route exact path={`${match.path}/:customerId?`}>
        <PermissionsHeader />
        <SelectCustomerSourceList role="ADMIN" />
      </Route>
      <Route path={`${match.path}/:customerId/:sourceId/:clientId?`}>
        <Permissions />
      </Route>
      <Route path="/:target">Page not found</Route>
    </Switch>
  );
}
