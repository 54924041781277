import { SessionContext } from "@ist-group-private-scope/web-skolid";
import { ReactElement, useCallback, useContext } from "react";
import { Route, Switch, useHistory, useRouteMatch } from "react-router";
import AccountsAccessEditor from "./AccountsAccessEditor";
import OrgOrVendorSelector from "common/org-or-vendor-selection/OrgOrVendorSelector";
import AccountsHeader from "./AccountsHeader";
import { Option } from "common/org-or-vendor-selection/types";
import Box from "@mui/material/Box";
import { allEducloudAdminRoles } from "common/educloudRoles";

export default function AccountsContent(): ReactElement | null {
  const session = useContext(SessionContext);
  const match = useRouteMatch();
  const history = useHistory();

  const handleSelection = useCallback(
    ({ id, type }: Option) => {
      history.push(`/accounts/${type}/${id}`);
    },
    [history]
  );

  if (session.loading || !session) {
    return null;
  }

  return (
    <Switch>
      <Route exact path={`${match.path}`}>
        <AccountsHeader />
        <Box pt={8}>
          <OrgOrVendorSelector
            onChange={handleSelection}
            educloudRoleFilter={allEducloudAdminRoles}
          />
        </Box>
      </Route>
      <Route path={`${match.path}/:optionType/:optionId`}>
        <AccountsAccessEditor />
      </Route>
      <Route path="/:target">Page not found</Route>
    </Switch>
  );
}
