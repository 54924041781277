import { ElementType, ReactElement } from "react";
import Box from "@mui/material/Box";
import MuiAlert from "@mui/material/Alert";
import { ErrorData, ResultData, RowData, useApiCall } from "./api";
import * as common from "../common/types";
import { Section } from "./generic";

export interface GenericListViewProps<T> {
  kind: common.Target;
  data: T[];
  title: string;
  isLoading?: boolean;
  apiCall?: common.ApiCall;
}
interface RowsComponentProps<T extends object = any, TT extends object = T> {
  apiCall: common.ApiCall;
  view: ElementType<GenericListViewProps<TT>>;
  mapper?: (rows: T[]) => TT[];
  name?: string;
  mt?: number;
}

export function RowsComponent<T extends object = any, TT extends object = T>(
  props: RowsComponentProps<T, TT>
): ReactElement {
  const data = useApiCall(props.apiCall, true);
  const { view: View } = props;

  if (data) {
    if (isErrorData(data)) {
      return (
        <MuiAlert elevation={6} variant="filled" severity="error">
          {(data as ErrorData).message}
          {(data as ErrorData).code}
        </MuiAlert>
      );
    } else if (data.list) {
      if (props.mapper) {
        return (
          <View
            kind={data.kind}
            data={props.mapper(data.rows)}
            apiCall={props.apiCall}
            title={props.name ?? ""}
          />
        );
      }
      return (
        <View
          kind={data.kind}
          data={data.rows}
          apiCall={props.apiCall}
          title={props.name ?? ""}
        />
      );
    }
    return <Box mt={props.mt ?? 3}>Unknown display</Box>;
  }
  return <View isLoading={true} data={[]} title={props.name ?? ""} />;
}

const isErrorData = (data: ResultData): data is ErrorData =>
  data.kind === "error";

export interface RowComponentProps<T = any> {
  apiCall: common.ApiCall;
  view: ElementType<RowData<T>>;
}

export function RowComponent<T = any>({
  apiCall,
  view: View,
}: RowComponentProps<T>): ReactElement {
  const data: RowData<T> | ErrorData | undefined = useApiCall(
    apiCall,
    false
  ) as any;

  if (!data) {
    return (
      <Section name={apiCall.target} loading={true}>
        <Box p={3}>Loading data...</Box>
      </Section>
    );
  }

  if (data.kind === "error") {
    return (
      <MuiAlert elevation={6} variant="filled" severity="error">
        {(data as ErrorData).protocolError ?? (data as ErrorData).message}
      </MuiAlert>
    );
  }
  return <View {...(data as RowData<T>)} />;
}
