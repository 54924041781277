import Box from "@mui/material/Box";
import MaterialTable, { Column } from "@material-table/core";
import { ReactElement, useMemo } from "react";
import { Link } from "react-router-dom";
import { TimePassedView } from "../common/generic";
import { useSourceInfo } from "../common/sourceInfo";

interface Row {
  clientId: string;
  clientName: string;
  organisationId?: string;
  organisationName?: string;
  sourceDescription: string;
  sourceId: string;
  updated?: string;
  version?: string;
}

const columns: Column<Row>[] = [
  {
    title: "Product",
    field: "clientName",
    defaultGroupOrder: 0,
    width: 0,
  },
  {
    title: "Organisation",
    field: "organisationName",
  },
  {
    title: "SourceId",
    field: "sourceId",
    render: (row: Row) => (
      <Link
        to={`/explorer/${row.organisationId}/${row.sourceId}?clientId=${row.clientId}`}
      >
        {row.sourceId}
      </Link>
    ),
  },
  {
    title: "Source Name",
    field: "sourceDescription",
  },

  {
    title: "Updated",
    field: "updated",
    type: "datetime",
    render: (row: Row) => <TimePassedView date={row.updated} />,
  },
  {
    title: "Version",
    field: "version",
    render: (row: Row) =>
      row?.version ?? <span style={{ color: "red" }}>OFFLINE</span>,
  },
];

export interface ClientListProps {}

export default function ClientList(_props: ClientListProps): ReactElement {
  const { sourceInfo, sourceInfoLoading } = useSourceInfo();
  const rowData = useMemo(
    () =>
      [...sourceInfo.values()].flatMap((sourceInfoItem) =>
        sourceInfoItem.clients.map((client) => ({
          clientId: client.id,
          clientName: client.name,
          organisationId: sourceInfoItem.organisation?.id,
          organisationName: sourceInfoItem.organisation?.name,
          sourceDescription: sourceInfoItem.description,
          sourceId: sourceInfoItem.id,
          updated: sourceInfoItem?.datasourceInfo?.stats?.timing?.snapshotEnd,
          version: sourceInfoItem?.datasourceInfo?.dataFormatVersion,
        }))
      ),
    [sourceInfo]
  );
  return (
    <Box pt={8}>
      <MaterialTable
        title="API clients"
        isLoading={sourceInfoLoading}
        columns={columns}
        // other props
        options={{
          minBodyHeight: 25,
          filtering: true,
          pageSizeOptions: [25, 50, 100, 500],
          pageSize: 50,
          emptyRowsWhenPaging: false,
          grouping: true,
        }}
        data={rowData}
      />
    </Box>
  );
}
