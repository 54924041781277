import {
  ChangeEvent,
  ReactElement,
  SyntheticEvent,
  useCallback,
  useState,
} from "react";
import IconButton from "@mui/material/IconButton";
import InputBase from "@mui/material/InputBase";
import type { Theme } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
import styled from "@mui/material/styles/styled";
import Clear from "@mui/icons-material/Clear";
import Search from "@mui/icons-material/Search";

const SearchContainer = styled("div")(({ theme }) => ({
  position: "relative",
  borderWidth: 1,
  borderColor: theme.palette.grey[500],
  borderStyle: "solid",
  borderRadius: 18,
  backgroundColor: theme.palette.common.white,
  marginLeft: 0,
  lineHeight: "34px",
  padding: 0,
  width: "100%",
  height: "36px",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

const SearchIcon = styled("div")(({ theme }) => ({
  padding: "5px 0 0 6px",
  position: "absolute",
  pointerEvents: "none",
  color: theme.palette.grey[500],
}));
const ClearSearchIcon = styled(IconButton)(({ theme }) => ({
  position: "absolute",
  right: 0,
  top: -2,
  color: theme.palette.grey[500],
  padding: "7px",
}));
const InputRoot = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  padding: 0,
}));

export interface SearchInputProps {
  onSearchSubmit?: (searchTerm: string) => void;
  onChange?: (searchTerm: string) => void;
  placeholder?: string;
  searchTooltip?: string;
  searchAriaLabel?: string;
  clearSearchAriaLabel?: string;
  color?: CSSStyleDeclaration["color"];
}

export default function SearchInput({
  clearSearchAriaLabel,
  color,
  onChange,
  onSearchSubmit,
  placeholder,
  searchAriaLabel = "Search",
  searchTooltip,
}: SearchInputProps): ReactElement {
  const [searchTerm, setSearchTerm] = useState("");
  const [focused, setFocus] = useState(false);
  const handleSubmit = useCallback(
    (event: SyntheticEvent) => {
      event.preventDefault();
      if (onSearchSubmit) {
        onSearchSubmit(searchTerm);
      }
    },
    [onSearchSubmit, searchTerm]
  );
  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setSearchTerm(event.target.value);
      if (onChange) {
        onChange(event.target.value);
      }
    },
    [setSearchTerm, onChange]
  );
  const handleClear = useCallback(() => {
    setSearchTerm("");
    if (onChange) {
      onChange("");
    }
  }, [setSearchTerm, onChange]);

  const unFolded = focused || searchTerm !== "";

  return (
    <form onSubmit={handleSubmit}>
      <SearchContainer>
        <SearchIcon>
          {searchTooltip ? (
            <Tooltip title={searchTooltip}>
              <Search fontSize="small" />
            </Tooltip>
          ) : (
            <Search fontSize="small" />
          )}
        </SearchIcon>
        <InputRoot
          placeholder={placeholder}
          inputProps={{ "aria-label": searchAriaLabel }}
          sx={{
            color: "inherit",
            padding: 0,
            "& input": {
              transition: (theme: Theme) => theme.transitions.create("width"),
              paddingLeft: unFolded ? "33px" : 0,
              width: unFolded ? "20ch" : "34px",
              margin: 0,
              "&::placeholder": {
                opacity: unFolded ? 1 : 0,
                color: (theme: Theme) => color ?? theme.palette.grey[500],
              },
            },
          }}
          value={searchTerm}
          onFocus={() => setFocus(true)}
          onBlur={() => setFocus(false)}
          onChange={handleChange}
        />
        {unFolded ? (
          <ClearSearchIcon
            disabled={searchTerm === ""}
            onClick={handleClear}
            aria-label={clearSearchAriaLabel}
          >
            <Clear fontSize="small" aria-label="clear" />
          </ClearSearchIcon>
        ) : null}
      </SearchContainer>
    </form>
  );
}
