import { ReactElement, SyntheticEvent, useCallback, useMemo } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { ListGenericComponent } from "common/generic";
import { useSourceInfo } from "common/sourceInfo";
import { AccessQuery } from "../api";
import PermissionsHeader from "../PermissionsHeader";
import useSelectClientColumns from "./useSelectClientColumns";
import { Row } from "./types";

export interface SelectClientProps {
  sourceAccessList: AccessQuery[] | undefined;
}

export default function SelectClient({
  sourceAccessList,
}: SelectClientProps): ReactElement {
  const {
    params: { sourceId },
    url,
  } = useRouteMatch<{
    customerId: string;
    sourceId: string;
  }>();
  const history = useHistory();
  const { sourceInfo, sourceInfoLoading } = useSourceInfo();

  const selectRow = useCallback(
    (_event?: SyntheticEvent, row?: Row) => {
      if (row?.clientId) {
        history.push(`${url}/${row.clientId}`);
      }
    },
    [history, url]
  );

  const columns = useSelectClientColumns();

  const data: Row[] | undefined = useMemo(() => {
    const source = sourceInfo.get(sourceId);
    return sourceAccessList
      ?.filter((sourceAccess) => sourceAccess.educloudEnabled)
      .map((sourceAccess) => {
        const client = source?.clients.find(
          (c) => c.id === sourceAccess.clientId
        );
        return {
          ...sourceAccess,
          clientName: client?.name ?? "-",
          vendorName: client?.product?.vendor?.name ?? "-",
        };
      });
  }, [sourceAccessList, sourceId, sourceInfo]);

  const loading = !data || sourceInfoLoading;

  return (
    <>
      <PermissionsHeader typeName="Select Client" />
      <ListGenericComponent
        columns={columns}
        data={data}
        isLoading={loading}
        localization={{
          body: { emptyDataSourceMessage: "No clients with access" },
        }}
        onRowClick={selectRow}
        options={{ filtering: false }}
        title="Select Client"
      />
    </>
  );
}
