import { ReactElement, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import TextField from "@mui/material/TextField";
import { ApolloError, gql, useQuery } from "@apollo/client";

export interface UserSelectResult {
  email: string | null;
  id: string;
  name: string | null;
}

export interface UserSelectFormData {
  key: string;
  name: string;
}

export interface UserSelectProps {
  formData: undefined | UserSelectFormData;
  onChange: (
    formData: UserSelectFormData,
    user: UserSelectResult | undefined
  ) => void;
}

const defaultFormData = { key: "", name: "" } as const;

export default function UserSelect({
  formData: { key, name } = defaultFormData,
  onChange,
}: UserSelectProps): ReactElement {
  const { error, loading, noMatch, user } = useUserLookup(key, name);
  const [lastUserChange, setLastUserChange] = useState<UserSelectResult>();

  useEffect(() => {
    if (lastUserChange !== user) {
      setLastUserChange(user);
      onChange({ key, name }, user);
    }
  }, [key, lastUserChange, name, onChange, user]);

  const statusMessage = (() => {
    if (error) {
      return <>Error: {error.message}</>;
    } else if (loading) {
      return "Loading...";
    } else if (noMatch && key && name) {
      return (
        <>
          No user found with that <em>name</em> and{" "}
          <em>email address or id number</em>
        </>
      );
    } else if (user) {
      return (
        <>
          Found a user with email address{" "}
          <em>{user.email ?? "<No email address>"}</em>
        </>
      );
    }
    return (
      <>
        Please enter <strong>both</strong> a <em>name</em> <strong>and</strong>{" "}
        an <em>email address</em> or <em>identity number</em>
      </>
    );
  })();

  return (
    <Box minHeight={100} width={400}>
      <InputLabel>User's information</InputLabel>
      <TextField
        fullWidth={true}
        label="User's email address or national identity number"
        onChange={(event) => onChange({ key: event.target.value, name }, user)}
        required={!!name}
        sx={{ mt: 1 }}
        value={key}
      />
      <TextField
        fullWidth={true}
        label="User's first or last name"
        onChange={(event) => onChange({ key, name: event.target.value }, user)}
        required={!!key}
        sx={{ mt: 1 }}
        value={name}
      />
      <p>{statusMessage}</p>
    </Box>
  );
}

interface SelectUserQueryResult {
  userByKeyAndName: null | {
    email: string | null;
    id: string;
    name: string | null;
  };
}
interface SelectUserQueryVariables {
  key: string;
  name: string;
}

function useUserLookup(
  key: string,
  name: string
): {
  error?: ApolloError;
  loading: boolean;
  noMatch: boolean;
  user?: UserSelectResult;
} {
  const [variables, setVariables] = useState({ key, name });
  const { data, error, loading } = useQuery<
    SelectUserQueryResult,
    SelectUserQueryVariables
  >(
    gql`
      query SelectUser($key: String!, $name: String!) {
        userByKeyAndName(key: $key, name: $name) {
          email
          id
          name
        }
      }
    `,
    {
      context: {
        clientName: "educloud",
      },
      variables,
    }
  );

  const requirementsMet = !!(key && name);
  const outdatedResult =
    loading || variables.key !== key || variables.name !== name;

  useEffect(() => {
    if (!loading && outdatedResult && requirementsMet) {
      setVariables({ key, name });
    }
  }, [key, loading, name, outdatedResult, requirementsMet]);

  const { userByKeyAndName: user } = data ?? {};

  return {
    error: outdatedResult && requirementsMet ? undefined : error,
    loading: outdatedResult && requirementsMet,
    noMatch: requirementsMet && !outdatedResult && !error && !user,
    user: !requirementsMet || outdatedResult || !user ? undefined : user,
  };
}
