import { ReactElement } from "react";
import { StylistHeader } from "common/stylist/header/StylistHeader";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { ClientSelect } from "../app/App";
import { HeaderBreadcrumbs } from "../common/CustomizedBreadcrumbs";
import { UserMenu } from "../common/generic";
import ExplorerSearch from "./ExplorerSearch";
import OrganisationSelect from "common/OrganisationSelect";

export interface ExplorerHeaderProps {
  itemName?: string;
  typeLink?: string;
  typeName?: string;
}

export default function ExplorerHeader({
  typeName,
  typeLink,
  itemName,
}: ExplorerHeaderProps): ReactElement {
  const paths = [];
  if (typeName) {
    paths.push({ name: typeName, link: typeLink });
  }

  if (itemName) {
    paths.push({ name: itemName, link: typeLink });
  }
  return (
    <Box pb={8}>
      <StylistHeader>
        <Box py={1} width="100%">
          <Grid justifyContent="flex-end" spacing={1} container>
            <Grid sm={12} md={"auto"} style={{ marginRight: "auto" }} item>
              <HeaderBreadcrumbs name={"Data Explorer"} paths={paths} />
            </Grid>
            <OrganisationSelect />
            <ClientSelect />
            <Grid item>
              <UserMenu />
            </Grid>
            <ExplorerSearch />
          </Grid>
        </Box>
      </StylistHeader>
    </Box>
  );
}
