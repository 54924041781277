import { SessionContext } from "@ist-group-private-scope/web-skolid";
import { StylistAppMenu } from "./stylist/header/StylistAppMenu";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import MatUICollapse from "@mui/material/Collapse";
import Grid, { type GridProps } from "@mui/material/Grid";
import Slide, { type SlideProps } from "@mui/material/Slide";
import TablePagination, {
  type TablePaginationProps,
} from "@mui/material/TablePagination";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import FormControlLabel from "@mui/material/FormControlLabel";
import IconButton from "@mui/material/IconButton";
import LinearProgress from "@mui/material/LinearProgress";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";

import useTheme from "@mui/material/styles/useTheme";
import CheckBox from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlank from "@mui/icons-material/CheckBoxOutlineBlank";
import SubjectIcon from "@mui/icons-material/Subject";
import MaterialTable, { MaterialTableProps } from "@material-table/core";
import {
  ReactElement,
  ReactNode,
  Ref,
  SyntheticEvent,
  forwardRef,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import ReactJson from "@microlink/react-json-view";
import {
  Link as RoutnerLink,
  useHistory,
  useLocation,
  useParams,
} from "react-router-dom";
import { validDateFilter, validEndDate, validStartDate } from ".";
import MTableFilterRow from "./tables/MaterialTableFilterRow";
import CustomMaterialTableToolbar from "./tables/CustomMaterialTableToolbar";
import { Target } from "./types";
import padFirstAndLastColumns from "./tables/padFirstAndLastColumns";
import fixedSizeLookupFilterColumns from "./tables/fixedSizeLookupFilterColumns";
import { TableClickableRow } from "./tableComponents";

export interface AdjustProps {
  children: ReactNode;
  x?: number;
  y?: number;
}

export function Adjust({ children, x = 0, y = 0 }: AdjustProps): ReactElement {
  return (
    <div style={{ transform: `translate(${x}px, ${y}px` }}>{children}</div>
  );
}

export function UserMenu(): ReactElement {
  const session = useContext(SessionContext);
  return (
    <StylistAppMenu
      user={session.user}
      onLogoutClick={() => session.logout()}
      logoutTitle="Log out"
    ></StylistAppMenu>
  );
}

export interface SectionProps extends GridProps {
  loading?: boolean;
  name: string | ReactNode;
  titleControls?: ReactNode;
}

export function Section({
  loading,
  name,
  children,
  titleControls,
  ...rest
}: SectionProps): ReactElement {
  const theme = useTheme();
  return (
    <Grid item md={12} {...rest}>
      <Paper elevation={15}>
        <Grid
          container
          alignItems="center"
          style={{
            borderBottomWidth: 1,
            borderBottomStyle: "solid",
            borderBottomColor: theme.palette.primary.main,
            paddingLeft: theme.spacing(3),
            paddingRight: theme.spacing(3),
            lineHeight: theme.spacing(8),
            minHeight: theme.spacing(8),
          }}
        >
          <Grid item>
            <Typography variant="h4">{name}</Typography>
          </Grid>
          {titleControls ? (
            <Grid style={{ marginLeft: "auto" }} item>
              {titleControls}
            </Grid>
          ) : null}
        </Grid>
        {loading ? <LinearProgress color="primary" /> : null}
        {children}
      </Paper>
    </Grid>
  );
}

export interface PropertyItemProps {
  children?: ReactNode;
  title: string;
  value?: string | null | undefined | boolean | number;
}

export function PropertyItem({
  children,
  title,
  value,
}: PropertyItemProps): ReactElement {
  const content =
    typeof value === "boolean" ? (
      <Grid item flex="1">
        {value ? (
          <CheckBox color="primary" />
        ) : (
          <CheckBoxOutlineBlank color="disabled" />
        )}
      </Grid>
    ) : value ? (
      <Typography flex="1" paragraph>
        {value}
      </Typography>
    ) : !children ? (
      <Typography flex="1" paragraph>
        {" "}
        -{" "}
      </Typography>
    ) : null;

  return (
    <Grid item>
      <Typography variant="overline">{title}</Typography>
      {content}
      {children ? (
        <Typography flex="1" paragraph>
          {children}
        </Typography>
      ) : null}
    </Grid>
  );
}

export interface ValidStartDateStringProps {
  start?: string | null;
}
export function ValidStartDateString({
  start,
}: ValidStartDateStringProps): ReactElement {
  return (
    <span
      style={{
        color: validStartDate(start) ? "green" : "red",
        whiteSpace: "nowrap",
      }}
    >
      {start}
    </span>
  );
}

export interface ValidEndDateStringProps {
  end?: string | null;
}
export function ValidEndDateString({
  end,
}: ValidEndDateStringProps): ReactElement {
  return (
    <span
      style={{
        color: validEndDate(end) ? "green" : "red",
        whiteSpace: "nowrap",
      }}
    >
      {end}
    </span>
  );
}

export interface PropertyItemDateRangeProps {
  end?: string | null;
  start?: string | null;
  title: string;
}

export function PropertyItemDateRange({
  end,
  start,
  title,
}: PropertyItemDateRangeProps): ReactElement {
  const dateStyleGroup = {
    color: validDateFilter({ startDate: start, endDate: end })
      ? "green"
      : "red",
  };
  return (
    <PropertyItem title={title}>
      <Box style={dateStyleGroup}>
        {start} - {end}
      </Box>
    </PropertyItem>
  );
}

export interface DataBoxProps {
  children?: ReactNode;
  data: { id: string };
  title?: ReactNode;
}

export function DataBox({
  children,
  data,
  title = "Data",
}: DataBoxProps): ReactElement {
  return (
    <Section
      name={title}
      titleControls={
        data.id ? (
          <Typography variant="caption" color="primary">
            ID: {data.id}
          </Typography>
        ) : null
      }
    >
      {children}
      <div style={{ paddingLeft: 12 }}>
        <JsonDialog src={data} />
      </div>
    </Section>
  );
}

export interface DataBoxSectionProps {
  children: ReactNode;
}

export function DataBoxSection({
  children,
}: DataBoxSectionProps): ReactElement {
  return (
    <>
      <Box px={3} pt={3}>
        <Grid container spacing={2}>
          {children}
        </Grid>
      </Box>
      <Divider />
    </>
  );
}

export interface DataBoxBlockProps extends GridProps {}
export function DataBoxBlock(props: DataBoxBlockProps): ReactElement {
  return <Grid container item direction="column" xs={3} {...props} />;
}

export interface CollapseProps {
  label?: string;
  children: ReactNode;
  variant?: "contained" | "text";
  pullRight?: boolean;
}

export function Collapse({
  children,
  label = "json data",
  pullRight,
  variant = "contained",
}: CollapseProps): ReactElement {
  const [open, setOpen] = useState(false);

  return (
    <div style={{ marginTop: "20px" }}>
      <Grid
        container
        justifyContent={
          `flex-${pullRight ? "end" : "start"}` as "flex-end" | "flex-start"
        }
        direction="row"
      >
        <Button
          onClick={() => {
            setOpen(!open);
          }}
          variant={variant}
          color="primary"
        >
          {open ? "Hide" : "Show"} {label}
        </Button>
      </Grid>
      <MatUICollapse in={open} unmountOnExit>
        {children}
      </MatUICollapse>
    </div>
  );
}

export function useObjectReferenceCallback(kind: Target) {
  const { sourceId, customerId } = useParams<{
    sourceId?: string;
    customerId?: string;
  }>();
  const clientId = new URLSearchParams(useLocation().search).get("clientId");
  const history = useHistory();
  return useCallback(
    (_event?: SyntheticEvent, item?: { id?: string }) => {
      if (item?.id) {
        history.push(
          `/explorer/${customerId}/${sourceId}/${kind}/${item.id}${
            clientId ? `?clientId=${clientId}` : ""
          }`
        );
      }
    },
    [kind, sourceId, customerId, clientId, history]
  );
}

export function useObjectLinkCallback() {
  const { sourceId, customerId } = useParams<{
    sourceId?: string;
    customerId?: string;
  }>();
  const clientId = new URLSearchParams(useLocation().search).get("clientId");
  const history = useHistory();
  return useCallback(
    (_event?: SyntheticEvent, kind?: Target, id?: string) => {
      if (id && kind) {
        history.push(
          `/explorer/${customerId}/${sourceId}/${kind}/${id}${
            clientId ? `?clientId=${clientId}` : ""
          }`
        );
      }
    },
    [sourceId, customerId, clientId, history]
  );
}

export interface ObjectReferenceLinkItemBase {
  displayName?: string;
  id?: string;
}
export interface ObjectReferenceLinkProps<
  Item extends ObjectReferenceLinkItemBase,
> {
  idProperty?: keyof Item;
  item?: Item;
  kind: Target;
}

export function ObjectReferenceLink<Item extends ObjectReferenceLinkItemBase>({
  idProperty = "id",
  item,
  kind,
}: ObjectReferenceLinkProps<Item>): ReactElement | null {
  const { sourceId, customerId } = useParams<{
    sourceId?: string;
    customerId?: string;
  }>();
  const clientId = new URLSearchParams(useLocation().search).get("clientId");
  const theme = useTheme();

  const id = item?.[idProperty];

  if (!id) {
    return null;
  }

  return (
    <Link
      component={RoutnerLink}
      to={`/explorer/${customerId}/${sourceId}/${kind}/${id}${
        clientId ? `?clientId=${clientId}` : ""
      }`}
      style={{ borderBottomColor: theme.palette.primary.main }}
      onClick={(evt: SyntheticEvent) => evt.stopPropagation()}
    >
      {item?.displayName ?? "???"}
    </Link>
  );
}

const noRows: any[] = [];

export type ListGenericComponentProps<T extends object> = {
  checkboxFilter?: {
    condition: (row: T) => boolean;
    defaultValue?: boolean;
    label: string;
  };
  data?: T[];
  name?: (row: T) => string;
  orderBy?: string[];
  kind?: Target;
  title?: string;
  // url?: (row: T) => string;
} & Omit<MaterialTableProps<T>, "data">;

export function ListGenericComponent<T extends { [key: string]: any }>({
  checkboxFilter,
  columns,
  components,
  data = noRows,
  name,
  options,
  title,
  ...otherProps
}: ListGenericComponentProps<T>) {
  const [checkboxFilterState, setCheckboxFilterState] = useState<boolean>(
    checkboxFilter?.defaultValue ?? false
  );
  const theme = useTheme();

  const filteredMappedData = useMemo(() => {
    let filteredMappedData = data;

    if (checkboxFilter && checkboxFilterState) {
      filteredMappedData = filteredMappedData.filter((o) =>
        checkboxFilter.condition(o)
      );
    }

    if (name) {
      filteredMappedData = filteredMappedData.map((row) => ({
        ...row,
        displayName: name(row),
      }));
    }

    return filteredMappedData;
  }, [checkboxFilter, checkboxFilterState, name, data]);

  const columnsWithPadding = useMemo(
    () => fixedSizeLookupFilterColumns(padFirstAndLastColumns(columns, theme)),
    [columns, theme]
  );

  // Override default H6 style
  const styledTitle =
    typeof title === "string" ? (
      <Typography variant="h4">{title}</Typography>
    ) : (
      title
    );

  return (
    <Grid xs={12} item>
      <MaterialTable
        {...otherProps}
        title={styledTitle}
        data={filteredMappedData}
        columns={columnsWithPadding}
        // other props
        options={{
          padding: "dense",
          minBodyHeight: 25,
          filtering: true,
          // sorting: undefined can be removed at some point.
          // There's a bug in MaterialTable that causes it to complain that
          // "Property `sorting` has been deprecated, please start using `maxColumnSort` instead."
          // if sorting is NOT set
          sorting: undefined,
          emptyRowsWhenPaging: false,
          pageSizeOptions: [15, 50, 100],
          pageSize: 15,
          filterCellStyle: {
            paddingTop: 0,
            paddingLeft: 0,
            paddingRight: theme.spacing(1),
            paddingBottom: theme.spacing(3),
            borderBottomWidth: 1,
            borderBottomStyle: "solid",
            borderBottomColor: theme.palette.divider,
          },
          headerStyle: {
            color: theme.palette.primary.main,
            textTransform: "uppercase",
            paddingBottom: 0,
            fontSize: "12px",
            fontWeight: "normal",
          },
          ...options,
        }}
        components={{
          Container: (props) => <Paper elevation={15}>{props.children}</Paper>,
          FilterRow: (props) => <MTableFilterRow {...props} />,
          Toolbar: (tbProps) => (
            <div
              style={{
                borderBottomWidth: 1,
                borderBottomStyle: "solid",
                borderBottomColor: theme.palette.primary.main,
              }}
            >
              <CustomMaterialTableToolbar {...tbProps}>
                {checkboxFilter && (
                  <FormControlLabel
                    style={{ marginLeft: "auto" }}
                    control={
                      <Checkbox
                        checked={checkboxFilterState}
                        onChange={() =>
                          setCheckboxFilterState(!checkboxFilterState)
                        }
                        color="primary"
                      />
                    }
                    label={checkboxFilter.label}
                  />
                )}
              </CustomMaterialTableToolbar>
            </div>
          ),
          Pagination: (paginationProps: TablePaginationProps) => {
            return (
              <Grid
                component="td"
                container
                justifyContent="space-between"
                style={{ paddingLeft: 12, marginTop: -8 }}
              >
                <JsonDialog src={data} />
                <Grid item>
                  <TablePagination
                    component={Box}
                    {...(paginationProps as any)}
                    labelRowsPerPage=""
                  />
                </Grid>
              </Grid>
            );
          },
          ...components,
        }}
      />
    </Grid>
  );
}

export interface SimpleTableProps {
  cols: string[];
  noRowsMsg: string;
  rows: ReactNode[][];
}

export function SimpleTable({
  cols,
  noRowsMsg,
  rows,
}: SimpleTableProps): ReactElement {
  const theme = useTheme();
  return (
    <>
      {rows.length > 0 ? (
        <Table size="small">
          <TableHead>
            <TableRow>
              {cols.map((col, i) => (
                <TableCell
                  key={i}
                  style={{
                    paddingLeft: i === 0 ? 24 : 0,
                    color: theme.palette.primary.main,
                    textTransform: "uppercase",
                    paddingBottom: 0,
                    fontSize: "12px",
                    fontWeight: "normal",
                  }}
                >
                  {col}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row: ReactNode[], i: number) => {
              return (
                <TableRow key={"r" + i}>
                  {row.map((col, j) => (
                    <TableCell
                      key={"c" + j}
                      style={{ paddingLeft: j === 0 ? 24 : 0 }}
                    >
                      {col}
                    </TableCell>
                  ))}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      ) : (
        <Box p={3}>{noRowsMsg}</Box>
      )}
      <Divider color="gray" />
      <div style={{ paddingLeft: 12 }}>
        <JsonDialog src={rows} />
      </div>
    </>
  );
}

export interface SimpleTableWithClickProps {
  cols: string[];
  data: Array<{ id: string; row: ReactNode[] }>;
  noRowsMsg: string;
  onRowClick: (Event: SyntheticEvent, id: string) => void;
}

export function SimpleTableWithClick({
  cols,
  data,
  noRowsMsg,
  onRowClick,
}: SimpleTableWithClickProps): ReactElement {
  const theme = useTheme();

  return (
    <>
      {data.length > 0 ? (
        <Table size="small">
          <TableHead>
            <TableRow>
              {cols.map((col, i) => (
                <TableCell
                  key={i}
                  style={{
                    paddingLeft: i === 0 ? 24 : 0,
                    color: theme.palette.primary.main,
                    textTransform: "uppercase",
                    paddingBottom: 0,
                    fontSize: "12px",
                    fontWeight: "normal",
                  }}
                >
                  {col}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((rowData, i: number) => {
              return (
                <TableClickableRow
                  onClick={(evt) => onRowClick(evt, rowData.id)}
                  key={"r" + i}
                >
                  {rowData.row.map((col, j) => (
                    <TableCell
                      key={"c" + j}
                      style={{ paddingLeft: j === 0 ? 24 : 0 }}
                    >
                      {col}
                    </TableCell>
                  ))}
                </TableClickableRow>
              );
            })}
          </TableBody>
        </Table>
      ) : (
        <Box p={3}>{noRowsMsg}</Box>
      )}
      <Divider color="gray" />
      <div style={{ paddingLeft: 12 }}>
        <JsonDialog src={data} />
      </div>
    </>
  );
}

export interface ObjectTableComponentProps {
  data: {};
  depth: number;
  noBorder?: boolean;
}

export function ObjectTableComponent({
  data,
  depth,
  noBorder,
}: ObjectTableComponentProps): ReactElement {
  if (depth <= 0) {
    return <p>-hidden table-</p>;
  }
  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell>Key</TableCell>
          <TableCell>Value</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {Object.entries(data).map(([k, v]) => (
          <TableRow key={k}>
            <TableCell>{k + ""}</TableCell>
            <TableCell>
              {typeof v === "object" && v !== null ? (
                <ObjectTableComponent depth={depth - 1} data={v} noBorder />
              ) : (
                (v as string)
              )}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}

export interface JsonDialogProps {
  src: any;
  title?: string;
}

const Transition = forwardRef(function Transition(
  props: SlideProps,
  ref: Ref<unknown>
) {
  return <Slide direction="left" ref={ref} {...props} />;
});

export function JsonDialog({ src, title }: JsonDialogProps): ReactElement {
  const [open, setJsonViewOpen] = useState(false);
  return (
    <>
      <Tooltip title="Show Raw JSON Data">
        <IconButton
          aria-label="Show Raw Data"
          style={{ color: "gray" }}
          onClick={(e) => setJsonViewOpen(true)}
        >
          <SubjectIcon color="inherit" />
        </IconButton>
      </Tooltip>
      <Dialog
        TransitionComponent={Transition}
        onClose={(e) => setJsonViewOpen(false)}
        aria-labelledby="raw-json-view-title"
        title="Show JSON view"
        open={open}
      >
        <DialogTitle id="raw-json-view-title">
          {title ? title : "Raw JSON Data"}
        </DialogTitle>
        <DialogContent>
          <ReactJson
            theme="monokai"
            src={src}
            name={title || "data"}
            displayDataTypes={false}
            enableClipboard={false}
            style={{ padding: "20px", borderRadius: "6px" }}
            collapsed={3}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={(e) => setJsonViewOpen(false)} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export interface JsonViewProps {
  name?: string;
  src: object;
}

export function JsonView({ name = "data", src }: JsonViewProps): ReactElement {
  return (
    <Box mt={4}>
      <ReactJson
        collapsed={3}
        displayDataTypes={false}
        enableClipboard={false}
        name={name}
        src={src}
        style={{ borderRadius: "6px", padding: "20px" }}
        theme="monokai"
      />
    </Box>
  );
}

export interface TimeViewProps {
  date?: Date | string | null;
}

export function TimeView({ date }: TimeViewProps): ReactElement | null {
  if (!date) {
    return null;
  }

  return <>{new Date(date).toLocaleString()}</>;
}

export interface TimePassedViewProps {
  date?: Date | string | null;
  now?: Date | string | null;
}

export function TimePassedView({
  date,
  now,
}: TimePassedViewProps): ReactElement | null {
  const [nowTime, setTimeNow] = useState(now ? new Date(now) : new Date());

  useEffect(() => {
    if (!now) {
      const interval = setInterval(() => setTimeNow(new Date()), 1000);
      return () => clearInterval(interval);
    }
  }, [now]);

  if (!date) {
    return null;
  }

  const dateDiff = (nowTime.getTime() - new Date(date).getTime()) / 1000;

  if (dateDiff < 60) {
    const seconds = Math.round(dateDiff % 60);
    return <>{`${seconds}s`}</>;
  }
  const days = Math.floor(dateDiff / 86400);
  const hours = Math.floor((dateDiff - days * 86400) / 3600);
  const minutes = Math.floor((dateDiff - days * 86400 - hours * 3600) / 60);
  return (
    <>
      {(days > 0 ? `${days} days ` : "") +
        (hours > 0 ? `${hours}h ` : "") +
        (minutes > 0 ? `${minutes}m ` : "")}
    </>
  );
}
