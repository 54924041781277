import { StylistHeader } from "../common/stylist/header/StylistHeader";
import { ReactElement } from "react";
import { SourceBasedBreadcrumbs } from "../common/CustomizedBreadcrumbs";
import { UserMenu } from "../common/generic";
import ClientList from "./ClientList";

export default function MyClientsContent(): ReactElement {
  return (
    <>
      <StylistHeader>
        <SourceBasedBreadcrumbs name="API clients" />
        <UserMenu />
      </StylistHeader>
      <ClientList />
    </>
  );
}
