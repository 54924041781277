import { ReactElement } from "react";
import Alert from "@mui/material/Alert";
import { WorkStatus } from "common/sourceInfo";
import { TimeView } from "common/generic";

export interface WorkStatusDisplayProps {
  status?: WorkStatus;
}

export default function WorkStatusDisplay({
  status,
}: WorkStatusDisplayProps): ReactElement {
  if (!status || status.status === "idle") {
    return <></>;
  }

  if (status.status === "error") {
    const firstError =
      status.errors?.length > 0
        ? status.errors[0]
        : { instanceName: "", time: "", message: "Unknown error" };
    return (
      <Alert severity="error" title={firstError.instanceName}>
        <TimeView date={firstError.time} />
        <p>{firstError.message}</p>
      </Alert>
    );
  }
  return (
    <Alert key="updating" severity="info">
      {status.status} {status.instanceName}
    </Alert>
  );
}
