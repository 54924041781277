import { Column } from "@material-table/core";
import type { Theme } from "@mui/material/styles";

// Adds a CSS padding to the first and last columns
export default function padFirstAndLastColumns<RowData extends object>(
  columns: Column<RowData>[],
  theme: Theme
): Column<RowData>[] {
  const cwp = [...columns];
  // Add padding to the cells in the beginning / end
  if (cwp.length) {
    const first = (cwp[0] = { ...cwp[0] });
    const last = (cwp[cwp.length - 1] = {
      ...cwp[cwp.length - 1],
    });

    first.cellStyle = {
      paddingLeft: theme.spacing(3),
      ...first.cellStyle,
    };
    last.cellStyle = {
      paddingRight: theme.spacing(3),
      ...last.cellStyle,
    };

    first.headerStyle = {
      paddingLeft: theme.spacing(3),
      ...first.headerStyle,
    };
    last.headerStyle = {
      paddingRight: theme.spacing(3),
      ...last.headerStyle,
    };

    first.filterCellStyle = {
      paddingLeft: theme.spacing(3),
      ...first.filterCellStyle,
    };
    last.filterCellStyle = {
      paddingRight: theme.spacing(3),
      ...last.filterCellStyle,
    };
  }
  return cwp;
}
