import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { ReactElement } from "react";
import { Route, Switch, useRouteMatch } from "react-router";
import { useSourceId } from "../common";
import {
  DataBox,
  DataBoxSection,
  ListGenericComponent,
  ObjectReferenceLink,
  PropertyItem,
  DataBoxBlock,
} from "../common/generic";
import { RowComponent } from "../common/rows";
import * as common from "../common/types";
import ExplorerHeader from "./ExplorerHeader";

interface SingleProgrammeProps {
  row?: common.Programme | null;
}

function SingleProgramme({ row }: SingleProgrammeProps): ReactElement {
  const programme = row;

  if (!programme) {
    return <Typography variant="h2">No programme found</Typography>;
  }

  return (
    <Grid
      container
      spacing={3}
      alignItems="stretch"
      alignContent="stretch"
      style={{
        flexGrow: 1,
      }}
    >
      <DataBox data={programme} title="Programme">
        <DataBoxSection>
          <DataBoxBlock xs={3}>
            <PropertyItem title="Name" value={programme.name} />
            <PropertyItem title="Code" value={programme.code} />
          </DataBoxBlock>
          <DataBoxBlock xs={3}>
            <PropertyItem title="Type" value={programme.type} />
            <PropertyItem title="School Type" value={programme.schoolType} />
          </DataBoxBlock>
          <DataBoxBlock xs={3}>
            <PropertyItem title="Parent">
              <ObjectReferenceLink
                kind={common.Target.programmes}
                item={programme.parentProgramme}
              />
            </PropertyItem>
          </DataBoxBlock>
        </DataBoxSection>
      </DataBox>
      <ListGenericComponent<common.ProgrammeContent>
        columns={[
          { title: "Type", field: "type", cellStyle: { verticalAlign: "top" } },
          {
            title: "Points",
            field: "points",
            cellStyle: { verticalAlign: "top" },
          },
          {
            title: "Content",
            render: (value) =>
              value.content.map((c) => (
                <p>
                  <ObjectReferenceLink
                    item={c}
                    kind={common.Target.syllabuses}
                  />
                </p>
              )),
          },
        ]}
        // Flattern out the structure and group in the table
        data={programme.content ?? []}
        options={{ filtering: false, toolbar: false, paging: false }}
        title="Programme Content"
      />
    </Grid>
  );
}

export default function Programmes(): ReactElement {
  const rm = useRouteMatch();

  const ac: common.ApiCall = {
    sourceId: useSourceId(),
    target: common.Target.programmes,
    method: common.Method.GET,
  };

  if (!rm) {
    throw Error("RouteMatch not defined.");
  }

  return (
    <>
      <ExplorerHeader typeLink={rm.path} typeName="Programme" />
      <Switch>
        <Route
          path={`${rm.path}/:programmeId`}
          render={({ match }) => (
            <RowComponent<common.Programme>
              apiCall={{ ...ac, path: match.params.programmeId }}
              view={SingleProgramme}
            />
          )}
        />
      </Switch>
    </>
  );
}
