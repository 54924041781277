import Box from "@mui/material/Box";
import MuiAlert from "@mui/material/Alert";
import { ReactElement, ReactNode } from "react";

export interface ErrorMessageProps {
  children: ReactNode;
}
export default function ErrorMessage({
  children,
}: ErrorMessageProps): ReactElement {
  return (
    <Box pt={3}>
      <MuiAlert elevation={6} variant="filled" severity="error">
        {children}
      </MuiAlert>
    </Box>
  );
}
