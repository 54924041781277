import {
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from "react";
import { SessionContext } from "@ist-group-private-scope/web-skolid";
import Box from "@mui/material/Box";
import BackupIcon from "@mui/icons-material/Backup";
import { Link } from "react-router-dom";
import { ListGenericComponent, TimePassedView } from "../common/generic";
import { Column } from "@material-table/core";
import { WorkStatus, useSourceInfo } from "../common/sourceInfo";
import WorkStatusDisplay from "./WorkStatusDisplay";
import { useAccessItems } from "../common/sessionInfo";

const columns: Column<ListRow>[] = [
  { title: "Organisation", field: "org" },
  {
    title: "Source ID",
    field: "sourceId",
    render: (s: ListRow) => (
      <Link to={`/source-settings/${s.sourceId}`}>{s.sourceId}</Link>
    ),
  },
  {
    title: "Descr",
    field: "description",
  },
  {
    title: "Updated",
    field: "updated",
    type: "datetime",
    render: (s: ListRow) => <TimePassedView date={s.updated} />,
  },
  {
    title: "Version",
    field: "version",
    render: (s: ListRow) =>
      s?.version ?? <span style={{ color: "red" }}>OFFLINE</span>,
  },
  {
    title: "Node",
    field: "datanode",
  },
  {
    title: "Status",
    field: "status",
  },
  {
    title: "StatusTime",
    field: "statustime",
    render: (s: ListRow) =>
      s?.statusTime ? <TimePassedView date={s.statusTime} /> : null,
  },
  {
    title: "Info",
    field: "info",
    render: (s: ListRow) => <WorkStatusDisplay status={s.info} />,
  },
];

interface ListRow {
  description: string;
  sourceId: string;
  org: string | undefined;
  orgId: string | undefined;
  updated: Date | string | undefined | null;
  version: string | undefined;
  datanode: string | undefined;
  status: WorkStatus["status"];
  statusTime?: WorkStatus["time"];
  info?: WorkStatus;
}

export interface SourceListProps {
  refreshSource: (sourceId: string) => void;
}

export default function SourceList({
  refreshSource,
}: SourceListProps): ReactElement {
  const session = useContext(SessionContext);
  const { sourceInfo, sourceInfoLoading, sourceInfoReload } = useSourceInfo();
  const accessItems = useAccessItems();

  const rowClick = useCallback(
    (_event: any, rowData: ListRow | ListRow[]) => {
      for (const row of [rowData].flat()) {
        refreshSource(row.sourceId);
      }
    },
    [refreshSource]
  );
  useEffect(() => {
    let handle: NodeJS.Timeout | undefined;
    if (sourceInfoReload) {
      handle = setInterval(sourceInfoReload, 60000);
      return () => handle && clearInterval(handle);
    }
  }, [sourceInfoReload]);

  const sourceDataList = useMemo(
    () =>
      [...sourceInfo.values()].map((s) => ({
        datanode: s?.datasourceInfo?.instanceName,
        description: s.description,
        info: s?.workStatus,
        org: s.organisation?.name,
        orgId: s.organisation?.id,
        sourceId: s.id,
        status: s?.workStatus?.status ?? "idle",
        statusTime: s?.workStatus?.time,
        updated: s?.datasourceInfo?.stats?.timing?.snapshotEnd
          ? s?.datasourceInfo?.stats?.timing?.snapshotEnd
          : s?.datasourceInfo?.time,
        version: s?.datasourceInfo?.dataFormatVersion,
      })),
    [sourceInfo]
  );
  const actions =
    accessItems?.length === 0 ||
    accessItems?.includes("/source-settings#update")
      ? [
          {
            icon: BackupIcon as any,
            tooltip: "Force source update",
            onClick: rowClick,
          },
          {
            icon: "cached",
            tooltip: "Reload list",
            isFreeAction: true,
            onClick: () => {
              if (sourceInfoReload) {
                sourceInfoReload();
              }
            },
          },
        ]
      : [
          {
            icon: "cached",
            tooltip: "Reload list",
            isFreeAction: true,
            onClick: () => {
              if (sourceInfoReload) {
                sourceInfoReload();
              }
            },
          },
        ];

  return (
    <Box pt={8}>
      <ListGenericComponent
        title="Source Info"
        isLoading={session.loading || !session || sourceInfoLoading}
        actions={actions}
        columns={columns}
        options={{
          minBodyHeight: 25,
          filtering: true,
          emptyRowsWhenPaging: false,
          pageSizeOptions: [25, 50, 100, 500],
          pageSize: 100,
          actionsColumnIndex: -1,
          idSynonym: "sourceId",
        }}
        data={sourceDataList}
      />
    </Box>
  );
}
