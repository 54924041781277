import { useCallback, useRef, useState } from "react";

// The dirty flag is set to true every time a value changes
// The reset() function sets the flag to false again
// The initial flag value is false
export default function useValuesChangedFlag(
  ...values: any[]
): [dirty: boolean, reset: () => void] {
  const [dirty, setDirty] = useState(false);
  const oldValuesRef = useRef<any[]>(values);
  const oldValues = oldValuesRef.current;

  if (
    !dirty &&
    // Compare the content of the new and the old array
    (values.length !== oldValues.length ||
      !oldValues.every((oldValue, index) => Object.is(oldValue, values[index])))
  ) {
    setDirty(true);
  }

  oldValuesRef.current = values;

  const reset = useCallback(() => setDirty(false), []);
  return [dirty, reset];
}
