import { useContext, useEffect, useMemo, useState } from "react";
import { SessionContext } from "@ist-group-private-scope/web-skolid";
import { useSourceInfo } from "./sourceInfo";
import useOrgsAndVendors from "./org-or-vendor-selection/useOrgsAndVendors";
import { allEducloudAdminRoles } from "./educloudRoles";
import { OptionType } from "./org-or-vendor-selection/types";
import { UserServiceImpl } from "../service/UserService";

export function useIsSuperadmin(): boolean {
  const session = useContext(SessionContext);
  const user = session.user?.profile;
  const isSuperadmin =
    (Array.isArray(user?.role) && user?.role.includes("Administrator")) ||
    user?.role === "Administrator";

  return isSuperadmin;
}

export function useAccessItems(reload?: boolean): Array<string> | undefined {
  const session = useContext(SessionContext);
  const token = session.user?.access_token;
  const [accessItems, setAccessItems] = useState<Array<string> | undefined>();
  useEffect(() => {
    const savedAccessItems = sessionStorage.getItem("accessItems");
    if (savedAccessItems && !reload) {
      setAccessItems(JSON.parse(savedAccessItems));
    } else {
      const userServiceImpl = new UserServiceImpl();
      const user = userServiceImpl.getUser(token);
      user
        .then((user) => {
          setAccessItems(user.accessItems);
          sessionStorage.setItem(
            "accessItems",
            JSON.stringify(user.accessItems)
          );
        })
        .catch(() => {
          setAccessItems([]);
          sessionStorage.removeItem("accessItems");
        });
    }
  }, [reload, token]);
  return accessItems;
}

export interface RolesSummary {
  educloudOrganisationAdmin: boolean; // Assigned an ADMIN role in at least one organisation (or user is super admin)
  educloudVendorAdmin: boolean; // Assigned an ADMIN role in at least one vendor (or user is super admin)
  sourceRoleDataOwner: boolean; // "ADMIN" of at least one source (or user is super admin)
  sourceRoleVendor: boolean; // "VENDOR_ADMIN" of at least one source (or user is super admin)
  superAdmin: boolean;
  user: true; // Any logged in user
}

// useRolesSummary tells you what different roles the current user has.
// undefined return value means loading or not logged in.
export function useRolesSummary(): RolesSummary | undefined {
  const { sourceInfo } = useSourceInfo();
  const superAdmin = useIsSuperadmin();

  // TODO: Replace this with a lighter graphql query
  // It is wasteful to fetch every accessible vendor and org for this
  const { options: orgsAndVendors } = useOrgsAndVendors(
    allEducloudAdminRoles,
    superAdmin
  );

  return useMemo(() => {
    const loaded = orgsAndVendors || superAdmin;
    if (loaded) {
      const sourceRoleDataOwner =
        superAdmin ||
        [...sourceInfo.values()].some(({ role }) => role === "ADMIN");

      const sourceRoleVendor =
        superAdmin ||
        [...sourceInfo.values()].some(({ role }) => role === "VENDOR_ADMIN");

      const educloudOrganisationAdmin =
        (superAdmin ||
          orgsAndVendors?.some(
            ({ type }) => type === OptionType.Organisation
          )) ??
        false;

      const educloudVendorAdmin =
        (superAdmin ||
          orgsAndVendors?.some(({ type }) => type === OptionType.Vendor)) ??
        false;

      return {
        educloudOrganisationAdmin,
        educloudVendorAdmin,
        sourceRoleDataOwner,
        sourceRoleVendor,
        superAdmin,
        user: true,
      };
    }
  }, [orgsAndVendors, sourceInfo, superAdmin]);
}
