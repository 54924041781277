import {
  SessionContext,
  SessionProvider,
  SessionState,
} from "@ist-group-private-scope/web-skolid";
import { StylistApp } from "../common/stylist/StylistApp";
import { StylistPageGrid } from "../common/stylist/StylistPageGrid";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import LinearProgress from "@mui/material/LinearProgress";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
import styled from "@mui/material/styles/styled";
import Divider from "@mui/material/Divider";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Typography from "@mui/material/Typography";
import { ReactElement, MouseEvent, useContext, useState } from "react";
import {
  BrowserRouter,
  useHistory,
  useLocation,
  useParams,
} from "react-router-dom";
import { AppContent } from "./AppContent";
import { SourceInfoProvider, useSourceInfo } from "../common/sourceInfo";
import { GraphqlProvider } from "../graphql";
import { settings } from "../settings";
import DrawerMenu from "./DrawerMenu";
import { HeaderButton } from "common/HeaderButton";

const Splash = styled(Box)(({ theme }) => ({
  margin: "auto",
  minWidth: "40%",
  padding: theme.spacing(5),
}));

export default function App(): ReactElement {
  return (
    <BrowserRouter>
      <AppWithSession />
    </BrowserRouter>
  );
}

function AppWithSession(): ReactElement {
  const history = useHistory();
  return (
    <SessionProvider
      automaticSilentRenew
      clientId={settings.skolid.clientId}
      environment={settings.skolid.environment}
      navigate={history.replace}
      scope={settings.skolid.scope}
    >
      <AppWithOrWithoutUser />
    </SessionProvider>
  );
}

function AppWithOrWithoutUser(): ReactElement {
  const session = useContext(SessionContext);
  return (
    <GraphqlProvider>
      <StylistApp>
        {session.user ? <AppWithUser /> : <AppWithoutUser session={session} />}
      </StylistApp>
    </GraphqlProvider>
  );
}

interface AppWithoutUserProps {
  session: SessionState;
}

function AppWithoutUser({ session }: AppWithoutUserProps): ReactElement {
  return (
    <Splash>
      <Paper elevation={20}>
        {session.loading ? <LinearProgress color="primary" /> : null}
        <Box py={6} px={8}>
          <Typography variant="h1">
            EduCloud
            <br />
            SS12000:2020
          </Typography>
          <Typography variant="h3">Configuration Portal</Typography>
          <Box mt={4} mx={-8}>
            <Divider />
          </Box>
          <Box mt={4} mb={6}>
            <Typography variant="body1">
              Login as an administrator for an organisation or datasource.
            </Typography>
          </Box>
          <Button
            color="primary"
            variant="contained"
            disabled={session.loading}
            onClick={() => session.login()}
          >
            Login
          </Button>
        </Box>
      </Paper>
    </Splash>
  );
}

function AppWithUser(): ReactElement {
  return (
    <SourceInfoProvider>
      <DrawerMenu />
      <StylistPageGrid style={{ flexGrow: 1 }}>
        <AppContent />
      </StylistPageGrid>
    </SourceInfoProvider>
  );
}

export function ClientSelect(): ReactElement | null {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const history = useHistory();
  const location = useLocation();
  const { sourceId } = useParams<{ sourceId?: string }>();
  const { sourceInfo } = useSourceInfo();
  const clientId = new URLSearchParams(location.search).get("clientId");

  const handleMenuOpen = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const select = (id?: string) => {
    if (id) {
      history.push(`${location.pathname}?clientId=${id}`);
    } else {
      history.push(location.pathname);
    }
    handleMenuClose();
  };

  if (!sourceId) {
    return null;
  }
  const selectedSource = sourceInfo.get(sourceId);

  const numClients = selectedSource?.clients?.length ?? 0;
  if (numClients < 1) {
    return null;
  }

  const menuItems = selectedSource!.clients.map((client) => (
    <MenuItem key={client.id} onClick={() => select(client.id)}>
      {client.name}
    </MenuItem>
  ));

  let selectedClient;
  if (clientId) {
    selectedClient = selectedSource?.clients.find(
      (client) => client.id === clientId
    );
  } else if (selectedSource?.role === "VENDOR_ADMIN") {
    selectedClient = selectedSource?.clients[0];
  }
  return (
    <Grid item>
      <HeaderButton
        aria-label="Select client"
        aria-controls="menu-client"
        aria-haspopup="true"
        onClick={handleMenuOpen}
      >
        {selectedClient ? selectedClient.name : "View as client"}
        <ArrowDropDownIcon color="inherit" />
      </HeaderButton>
      <Menu
        id="menu-client"
        anchorEl={anchorEl}
        style={{ marginTop: 40 }}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        {selectedSource?.role === "ADMIN" ||
        selectedSource?.role === "SUPER_ADMIN" ? (
          <MenuItem key="*" onClick={() => select()}>
            *
          </MenuItem>
        ) : null}

        {menuItems}
      </Menu>
    </Grid>
  );
}
