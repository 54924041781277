import { ElementType, FC } from "react";
import type { SvgIconProps } from "@mui/material/SvgIcon";

import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import CloudCircleIcon from "@mui/icons-material/CloudCircle";
import DescriptionIcon from "@mui/icons-material/Description";
import PageviewIcon from "@mui/icons-material/Pageview";
import SecurityIcon from "@mui/icons-material/Security";
import VpnKeyIcon from "@mui/icons-material/VpnKey";

import ExplorerContent from "explorer/ExplorerContent";
import SourceSettingsContent from "source-settings/SourceSettingsContent";
import MyClientsContent from "my-clients/MyClientsContent";
import PermissionsContent from "permissions/PermissionsContent";
import ApiDocContent from "api-doc/ApiDocContent";
import AccountsContent from "accounts/AccountsContent";
import { RolesSummary } from "common/sessionInfo";

export type AppSectionRoleRestriction = keyof RolesSummary;

export interface AppSection {
  availableTo: AppSectionRoleRestriction[];
  Content: ElementType<{}>;
  Icon: FC<SvgIconProps>;
  name: string;
  path: `/${string}`;
}

const appSections: AppSection[] = [
  {
    Content: ExplorerContent,
    Icon: PageviewIcon,
    availableTo: ["user"],
    name: "Data Explorer",
    path: "/explorer",
  },
  {
    Content: PermissionsContent,
    Icon: SecurityIcon,
    availableTo: ["sourceRoleDataOwner"],
    name: "Data Permissions",
    path: "/permissions",
  },
  {
    Content: MyClientsContent,
    Icon: VpnKeyIcon,
    availableTo: ["sourceRoleVendor"],
    name: "API Clients",
    path: "/clients",
  },
  {
    Content: ApiDocContent,
    Icon: DescriptionIcon,
    availableTo: ["sourceRoleVendor"],
    name: "API Documentation",
    path: "/api-doc",
  },
  {
    Content: SourceSettingsContent,
    Icon: CloudCircleIcon,
    availableTo: ["superAdmin"],
    name: "Manage Sources",
    path: "/source-settings",
  },
  {
    Content: AccountsContent,
    Icon: SupervisorAccountIcon,
    availableTo: ["educloudOrganisationAdmin", "educloudVendorAdmin"],
    name: "Manage Accounts",
    path: "/accounts",
  },
];

export const redirectToAt404 = appSections[0].path;

export default appSections;
