import type { Theme } from "@mui/material/styles";
import Button, { type ButtonProps } from "@mui/material/Button";
import { ReactElement } from "react";

export function HeaderButton(props: ButtonProps): ReactElement {
  return (
    <Button
      color="inherit"
      sx={{
        background: "inherit",
        height: (theme: Theme) => theme.spacing(4),
        padding: (theme: Theme) => theme.spacing(0, 1, 0, 2),
        textTransform: "initial",
        typography: "body1",
      }}
      {...props}
    />
  );
}
