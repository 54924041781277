import { Column } from "@material-table/core";
import useTheme from "@mui/material/styles/useTheme";
import { useMemo } from "react";
import ClientAccessChipList from "../ClientAccessChipList";
import { Row } from "./types";

export default function useSelectClientColumns(): Column<Row>[] {
  const theme = useTheme();

  return useMemo(
    () => [
      {
        title: "Vendor",
        field: "vendorName",
        cellStyle: { verticalAlign: "top" },
      },
      {
        title: "Product/Service",
        field: "clientName",
        cellStyle: (_, a) => ({
          color: !a.educloudEnabled ? "red" : "inherit",
          verticalAlign: "top",
        }),
      },
      {
        title: "Access to schools",
        field: "permissionMode",
        cellStyle: (_cellData, rowData) => ({
          verticalAlign: "top",
          color:
            !rowData.educloudEnabled ||
            (rowData.includes.length === 0 &&
              rowData.permissionMode === "NORMAL")
              ? "red"
              : "inherit",
        }),
        render: (rowData) =>
          !rowData.educloudEnabled
            ? "No access"
            : rowData.permissionMode === "ALL_ACCESS"
            ? "All"
            : rowData.permissionMode === "ALL_ACCESS_WITH_EXCLUDES"
            ? "All except excluded"
            : rowData.permissionMode === "NORMAL" && rowData.includes.length
            ? "Access to selected"
            : "No access",
      },
      {
        title: "Access to data",
        field: "",
        cellStyle: { paddingBottom: theme.spacing(1) }, // Adjust for Chip margin. Standard padding = 2 spacings.
        render: (row) => <ClientAccessChipList {...row} />,
        filtering: false, // Custom sorting and filtering could be implemented instead of disabling
      },
    ],
    [theme]
  );
}
