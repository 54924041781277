import { ReactElement, useCallback, useMemo } from "react";
import { gql, useMutation, useQuery } from "@apollo/client";
import { useRouteMatch } from "react-router";
import AccountsAccessEditorPresentation from "./AccountsAccessEditorPresentation";
import {
  allEducloudOrganisationRoles,
  allEducloudVendorRoles,
  EducloudRole,
  EducloudUserRoleAssignment,
} from "common/educloudRoles";
import NewRoleAssignmentForm from "./NewRoleAssignmentForm";
import { useIsGraphqlAuthenticated } from "../graphql";

export interface AccountsAccessEditorProps {}

export default function AccountsAccessEditor(): ReactElement | null {
  const {
    params: { subjectId, subjectType },
  } = useRouteMatch<{
    subjectId: string;
    subjectType: string;
  }>("/accounts/:subjectType/:subjectId") || {
    params: {
      subjectId: "",
      subjectType: "",
    },
  };

  const isOrganisation = subjectType === "Organisation";

  const graphqlAuthenticated = useIsGraphqlAuthenticated();
  const {
    data: { organisation } = {},
    loading: loadingOrganisation,
    refetch: refetchOrganisation,
  } = useQuery<OrgQueryResult>(ORG_QUERY, {
    context: {
      clientName: "educloud",
    },
    fetchPolicy: "network-only",
    skip: !graphqlAuthenticated || !isOrganisation,
    variables: { subjectId },
  });

  const {
    data: { vendor } = {},
    loading: loadingVendor,
    refetch: refetchVendor,
  } = useQuery<VendorQueryResult>(VENDOR_QUERY, {
    context: {
      clientName: "educloud",
    },
    fetchPolicy: "network-only",
    skip: !graphqlAuthenticated || isOrganisation,
    variables: { subjectId },
  });

  const refetch = isOrganisation ? refetchOrganisation : refetchVendor;
  const loading = isOrganisation ? loadingOrganisation : loadingVendor;
  const data = loading ? undefined : isOrganisation ? organisation : vendor;

  const roleAssignments: EducloudUserRoleAssignment[] = useMemo(
    () =>
      (data?.roleAssignments.nodes
        .map(({ role, subject: user }) =>
          "id" in user
            ? {
                emailAddress: user.email,
                name: user.name,
                role,
                userId: user.id,
              }
            : undefined
        )
        .filter((assignment) => assignment) as EducloudUserRoleAssignment[]) ??
      [],
    [data]
  );

  const userAndRoleAlreadyInList = useCallback(
    (role: string, userId: string) =>
      roleAssignments.some((ra) => ra.userId === userId && ra.role === role),
    [roleAssignments]
  );

  const [unassignOrg, { loading: unassigningOrgRole }] = useMutation<
    any,
    MutateOrganisationRoleVariables
  >(ORG_UNASSIGN_MUTATION, {
    context: {
      clientName: "educloud",
    },
  });
  const [unassignVendor, { loading: unassigningVendorRole }] = useMutation<
    any,
    MutateVendorRoleVariables
  >(VENDOR_UNASSIGN_MUTATION, {
    context: {
      clientName: "educloud",
    },
  });

  const handleUnassign = useCallback(
    ({ userId, role }: EducloudUserRoleAssignment) => {
      if (isOrganisation) {
        unassignOrg({
          variables: { input: { organisationId: subjectId, role, userId } },
        }).finally(() => refetch());
      } else {
        unassignVendor({
          variables: { input: { vendorId: subjectId, role, userId } },
        }).finally(() => refetch());
      }
    },
    [unassignOrg, unassignVendor, refetch, subjectId, isOrganisation]
  );

  const unassigning = unassigningOrgRole || unassigningVendorRole;

  const [assignOrg, { loading: assigningOrgRole }] = useMutation<
    any,
    MutateOrganisationRoleVariables
  >(ORG_ASSIGN_MUTATION, {
    context: {
      clientName: "educloud",
    },
  });
  const [assignVendor, { loading: assigningVendorRole }] = useMutation<
    any,
    MutateVendorRoleVariables
  >(VENDOR_ASSIGN_MUTATION, {
    context: {
      clientName: "educloud",
    },
  });

  const handleAssign = useCallback(
    (role: EducloudRole, userId: string) => {
      if (isOrganisation) {
        assignOrg({
          variables: { input: { organisationId: subjectId, role, userId } },
        }).finally(() => refetch());
      } else {
        assignVendor({
          variables: { input: { vendorId: subjectId, role, userId } },
        }).finally(() => refetch());
      }
    },
    [assignOrg, assignVendor, isOrganisation, refetch, subjectId]
  );

  const assigning = assigningOrgRole || assigningVendorRole;

  const roles = isOrganisation
    ? allEducloudOrganisationRoles
    : allEducloudVendorRoles;

  return (
    <AccountsAccessEditorPresentation
      loading={assigning || loading || unassigning}
      newAssignmentsForm={
        <NewRoleAssignmentForm
          onSubmit={handleAssign}
          roles={roles}
          userAndRoleAlreadyInList={userAndRoleAlreadyInList}
        />
      }
      onUnassign={handleUnassign}
      roleAssignments={roleAssignments}
      subjectName={data?.name}
    />
  );
}

interface OrgOrVendorInfo {
  name: string;
  roleAssignments: {
    nodes: {
      role: EducloudRole;
      subject:
        | {
            id: string;
            name?: string;
            email?: string;
          }
        | {};
    }[];
  };
}

interface OrgQueryResult {
  organisation: OrgOrVendorInfo;
}

interface VendorQueryResult {
  vendor: OrgOrVendorInfo;
}

const ORG_QUERY = gql`
  query OrganisationRoleAssignmentsQuery($subjectId: ID!) {
    organisation(id: $subjectId) {
      name
      roleAssignments {
        nodes {
          role
          subject {
            ... on User {
              id
              name
              email
            }
          }
        }
      }
    }
  }
`;

const VENDOR_QUERY = gql`
  query VendorRoleAssignmentsQuery($subjectId: ID!) {
    vendor(id: $subjectId) {
      name
      roleAssignments {
        nodes {
          role
          subject {
            ... on User {
              id
              name
              email
            }
          }
        }
      }
    }
  }
`;

interface MutateOrganisationRoleVariables {
  input: {
    organisationId: string;
    role: EducloudRole;
    userId: string;
  };
}
interface MutateVendorRoleVariables {
  input: {
    role: EducloudRole;
    userId: string;
    vendorId: string;
  };
}

const ORG_UNASSIGN_MUTATION = gql`
  mutation UnassignOrgRole($input: UnassignOrganisationRoleInput!) {
    unassignOrganisationRole(input: $input) {
      dummy
    }
  }
`;

const VENDOR_UNASSIGN_MUTATION = gql`
  mutation UnassignVendorRole($input: UnassignVendorRoleInput!) {
    unassignVendorRole(input: $input) {
      dummy
    }
  }
`;

const ORG_ASSIGN_MUTATION = gql`
  mutation AssignOrgRole($input: AssignOrganisationRoleInput!) {
    assignOrganisationRole(input: $input) {
      roleAssignment {
        role
      }
    }
  }
`;

const VENDOR_ASSIGN_MUTATION = gql`
  mutation AssignVendorRole($input: AssignVendorRoleInput!) {
    assignVendorRole(input: $input) {
      roleAssignment {
        role
      }
    }
  }
`;
