import { ReactElement, ReactNode } from "react";
import { DrawerOpenContext } from "./drawer/drawerContext";
import { useLocalStorage } from "./useLocalStorage";

export function StylistAppContent({
  children,
}: {
  children: ReactNode;
}): ReactElement {
  const [open, setOpen] = useLocalStorage<boolean>("drawer-open", true);

  return (
    <DrawerOpenContext.Provider value={{ open, setOpen }}>
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
        }}
      >
        {children}
      </div>
    </DrawerOpenContext.Provider>
  );
}
