export enum Target {
  organisations = "organisations",
  groups = "groups",
  placements = "placements",
  persons = "persons",
  duties = "duties",
  syllabuses = "syllabuses",
  activities = "activities",
  grades = "grades",
  studyplans = "studyplans",
  programmes = "programmes",
}

export const acceptedTargets = [
  "organisations",
  "groups",
  "placements",
  "persons",
  "duties",
  "syllabuses",
  "activities",
  "grades",
  "studyplans",
  "programmes",
];

// Need to validate
// organisation should be an uuid, that exisits in the set of all organisation ids

export enum Method {
  POST = "POST",
  GET = "GET",
}

export interface ApiCall {
  target: Target;
  method: Method;
  sourceId: string;
  body?: any;
  path?: string;
  organisation?: string;
  member?: string;
  teacher?: string;
  student?: string;
  group?: string;
  child?: string;
  owner?: string;
  "relationship.entity.type"?:
    | "enrolment"
    | "duty"
    | "placement.child"
    | "placement.owner"
    | "responsibleFor.enrolment"
    | "responsibleFor.placement";
  "relationship.organisation"?: string;
  schoolUnitCode?: string;
  expand?: string[];
  schoolTypes?: string[];
  groupType?: string[];
  expandReferenceNames?: "true" | "false";
  sortkey?: string;
  limit?: string;
  pageToken?: string;
  parent?: string;
  nameContains?: string[];
  organisationCode?: string;
}

export const paramBlacklist: (keyof ApiCall)[] = [
  "target",
  "method",
  "sourceId",
  "body",
  "path",
];

// Not needed as user run query as self
// does not offer protection client-side anyway

// export const acceptedFields = [
//   "target",
//   "customerId",
//   "child",
//   "owner",
//   "schoolTypes",
//   "groupType",
//   "organisation",
//   "groups",
//   "person",
//   "relationship.entity.type",
//   "relationship.organisation",
//   "placement.placedAt",
//   "placedAt",
//   "schoolUnitCode",
//   "organisationCode",
//   "dutyAt",
//   "expand",
//   "expandReferenceNames",
//   "sortkey",
//   "limit",
//   "pageToken",
//   "parent"
// ];

export type ActivityReference = ObjectReference;
export type CalendarEventReference = ObjectReference;
export type DutyReference = ObjectReference;
export type OrganisationReference = ObjectReference;
export type PersonReference = ObjectReference;
export type PlacementReference = ObjectReference;
export type ProgrammeReference = ObjectReference;
export type SchoolUnitReference = ObjectReference;
export type GroupReference = ObjectReference;
export type SyllabusReference = ObjectReference;

export interface Absence {
  id: string;
  meta: Meta;
  startTime: string;
  endTime: string;
  type: CodeAbsenceType;
  student: PersonReference;
  organisation?: SchoolUnitReference;
  registeredBy?: PersonReference;
}

export interface Activity {
  id: string;
  meta?: Meta;
  displayName: string;
  calendarEventsRequired: boolean;
  startDate: string;
  endDate?: string;
  activityType?: "Undervisning" | "Elevaktivitet" | "Läraraktivitet" | "Övrigt";
  comment?: string;
  minutesPlanned?: number;
  groups: GroupReference[];
  teachers?: Array<{
    duty?: DutyReference;
    startDate?: string;
    endDate?: string;
    minutesPlanned?: number;
  }>;
  syllabus?: SyllabusReference;
  organisation: OrganisationReference;
  parentActivity?: ActivityReference;
}

export type ActivityExpanded = Activity & {
  _embedded?: {
    groups?: Group[];
    syllabus?: Syllabus;
    teachers?: Duty[];
  };
  _searchValue?: {
    persons?: string[];
  };
};

export interface AggregatedAttendance {
  activity: ActivityReference;
  student: PersonReference;
  startDate: string;
  endDate: string;
  attendanceSum: number;
  validAbsenceSum?: number;
  invalidAbsenceSum?: number;
  otherAttendanceSum?: number;
  reportedSum?: number;
  offeredSum?: number;
  _embedded?: {
    activity?: Activity;
    student?: Person;
  };
}

export interface Attendance {
  id: string;
  meta: Meta;
  calendarEvent: CalendarEventReference;
  student: PersonReference;
  reporter?: PersonReference;
  isReported: boolean;
  attendanceMinutes: number;
  validAbsenceMinutes?: number;
  invalidAbsenceMinutes?: number;
  otherAttendanceMinutes?: number;
  absenceReason?: string;
  reportedTimestamp?: string;
}

export interface AttendanceEvent {
  id: string;
  meta: Meta;
  time: string;
  eventType: "In" | "Ut";
  person?: PersonReference;
  registeredBy?: PersonReference;
  group: GroupReference;
  _embedded?: {
    registeredBy?: Person;
    child?: Person;
    group?: GroupFragment;
  };
}

export interface AttendanceSchedule {
  id: string;
  meta: Meta;
  placement: PlacementReference;
  numberOfWeeks?: number;
  startDate: string;
  endDate?: string;
  temporary?: boolean;
  state: Array<{
    state: "Godkänt" | "Begärt" | "Nekat";
    registeredAt: string;
    comment?: string;
    registeredBy?: PersonReference;
  }>;
  scheduleEntries?: Array<{
    weekOffset?: number;
    dayOfWeek?:
      | "Måndag"
      | "Tisdag"
      | "Onsdag"
      | "Torsdag"
      | "Fredag"
      | "Lördag"
      | "Söndag";
    startTime?: string;
    length?: number;
  }>;
}

export interface CalendarEvent {
  id: string;
  meta: Meta;
  activity: ActivityReference;
  startTime: string;
  endTime: string;
  cancelled?: boolean;
  teachingLengthTeacher?: number;
  teachingLengthStudent?: number;
  comment?: string;
  studentExceptions?: Array<{
    student: PersonReference;
    participates: boolean;
    startTime?: string;
    endTime?: string;
    teachingLength?: number;
  }>;
  teacherExceptions?: Array<{
    duty: DutyReference;
    participates: boolean;
    startTime?: string;
    endTime?: string;
    teachingLength?: number;
  }>;
  rooms?: ObjectReference[];
  resources?: ObjectReference[];
  _embedded?: {
    activity?: Activity;
    attendance?: Attendance[];
  };
}

export type CodeAbsenceType = "Beviljad ledighet" | "Anmäld frånvaro";

export type CodeAssignmentRole =
  | "Mentor"
  | "Förskollärare"
  | "Barnskötare"
  | "Fritidspedagog"
  | "Specialpedagog"
  | "Elevhälsopersonal"
  | "Pedagogisk ledare"
  | "Schemaläggare"
  | "Lärarassistent"
  | "Administrativ personal";

export type CodeCurriculum =
  | "Lgy70"
  | "Lgr80"
  | "Lpo94"
  | "Lpf94"
  | "Lpfö98"
  | "GR2000"
  | "GY2000"
  | "GYSÄR2000"
  | "GYVUX2000"
  | "GYVUX2001"
  | "GYVUX2002"
  | "GR2011"
  | "GRSÄR2011"
  | "SPEC2011"
  | "SAM2011"
  | "Lvux12"
  | "GY2011"
  | "GYSÄR2013"
  | "VU2013";

export const allDutyRoles = [
  // "Övrig pedagogisk personal" and "Förskolechef" are deprecated in 2.1, but we need to keep them for now.
  "Annan personal",
  "Barnskötare",
  "Bibliotekarie",
  "Förskolechef",
  "Förskollärare",
  "Förstelärare",
  "Fritidspedagog",
  "Kurator",
  "Lärarassistent",
  "Lärare",
  "Övrig arbetsledning",
  "Rektor",
  "Skoladministratör",
  "Skolläkare",
  "Skolpsykolog",
  "Skolsköterska",
  "Speciallärare/specialpedagog",
  "Studie- och yrkesvägledare",
  "Övrig pedagogisk personal",
] as const;

export type CodeDutyRole = (typeof allDutyRoles)[number];

export type CodeGroupType =
  | "Undervisning"
  | "Klass"
  | "Mentor"
  | "Provgrupp"
  | "Schema"
  | "Avdelning"
  | "Personalgrupp"
  | "Övrigt";

export type CodeObjectType =
  | "Absence"
  | "AttendanceEvent"
  | "Attendance"
  | "Grade"
  | "CalendarEvent"
  | "AttendanceSchedule"
  | "Resource"
  | "Room"
  | "Activity"
  | "Duty"
  | "Placement"
  | "StudyPlan"
  | "Programme"
  | "Syllabus"
  | "SchoolUnitOffering"
  | "Group"
  | "Person"
  | "Organisation";

export type CodeOrganisationType =
  | "Huvudman"
  | "Stadsdel"
  | "Rektorsområde"
  | "Skola"
  | "Skolenhet"
  | "Övrigt";

export type CodeRelationType =
  | "Vårdnadshavare"
  | "Annan ansvarig"
  | "God man"
  | "Utsedd behörig";

export type CodeSchoolType =
  | "FS"
  | "FKLASS"
  | "FTH"
  | "OPPFTH"
  | "GR"
  | "GRS"
  | "TR"
  | "SP"
  | "SAM"
  | "GY"
  | "GYS"
  | "SARVUX"
  | "SARVUXGR"
  | "SARVUXGY"
  | "VUX"
  | "VUXSFI"
  | "VUXGR"
  | "VUXGY"
  | "VUXSARTR"
  | "VUXSARGR"
  | "VUXSARGY"
  | "SFI"
  | "KU"
  | "YH"
  | "FHS"
  | "STF"
  | "KKU"
  | "HS"
  | "ABU"
  | "AU";

export interface CreateSubscription {
  name: string;
  target: string;
  resourceTypes: Array<{
    resource?: CodeObjectType;
  }>;
  organisations?: string[];
}
export interface Duty {
  id: string;
  meta?: Meta;
  person?: PersonReference;
  assignmentRole?: AssignmentRole[];
  dutyAt?: OrganisationReference;
  dutyRole: CodeDutyRole;
  signature?: string;
  dutyPercent?: number;
  hoursPerYear?: number;
  startDate: string;
  endDate?: string;
}

export interface AssignmentRole {
  group: GroupReference;
  assignmentRoleType: CodeAssignmentRole;
  startDate: string;
  endDate?: string;
}

export type DutyExpanded = Duty & {
  _embedded?: {
    person?: Person;
  };
};

export interface Enrolment {
  enroledAt: SchoolUnitReference;
  schoolYear?: number;
  schoolType: CodeSchoolType;
  startDate: string;
  endDate?: string;
  educationCode?: string;
  programme?: ProgrammeReference;
  specification?: string;
}

export interface Error {
  code: string;
  message: string;
}

export interface Grade {
  id: string;
  meta?: Meta;
  student: PersonReference;
  organisation: SchoolUnitReference;
  registeredBy?: PersonReference; // Required by SS12000, not implemented at the time of writing (see ECSS-231)
  gradingTeacher?: PersonReference;
  group?: GroupReference;
  registeredDate: string;
  gradeValue: string;
  finalGrade: boolean;
  trial?: boolean;
  adaptedStudyPlan: string;
  remark?: string;
  converted?: boolean;
  correctionType?: "Ändring" | "Rättelse";
  semester?: "HT" | "VT";
  year?: number;
  syllabus?: SyllabusReference;
  diplomaProject?: {
    title: string;
    description: string;
    titleEngish?: string;
    descriptionEnglish?: string;
  };
}

export interface IdLookup {
  ids?: string[];
}

export interface Meta {
  created?: string;
  modified?: string;
}

export interface ObjectReference {
  id: string;
  displayName?: string;
}
export interface Organisation {
  id: string;
  meta: Meta;
  displayName: string;
  organisationCode?: string;
  organisationType: CodeOrganisationType;
  organisationNumber?: string;
  parentOrganisation?: OrganisationReference;
  schoolUnitCode?: string;
  schoolTypes?: CodeSchoolType[];
  municipalityCode?: string;
  url?: string;
  email?: string;
  phoneNumber?: string;
  contactInfo?: Array<{
    infoType?: "Support" | "Publik";
    info?: string;
  }>;
  startDate?: string;
  endDate?: string;
}

export interface Phonenumber {
  value?: string;
  type?: "Hem" | "Arbete";
  mobile?: boolean;
}
export interface Email {
  value: string;
  type?: "Privat" | "Skola elev" | "Skola personal" | "Arbete övrigt";
}
export interface PersonAddresses {
  type?:
    | "Folkbokföring"
    | "Särskildpostadress"
    | "Tillfälligadress"
    | "Postadress";
  streetAddress?: string;
  locality?: string;
  postalCode?: string;
  countyCode?: number;
  municipalityCode?: number;
  realEstateDesignation?: string;
  country?: string;
}

export interface CivicNo {
  value: string;
  nationality?: string;
}
export interface EduPersonPrincipalNames {
  value: string;
  type?: "Pedagogisk personal" | "Övrig personal" | "Elev" | "Vårdnadshavare";
}
export interface Person {
  id: string;
  meta?: Meta;
  givenName: string;
  middleName?: string;
  familyName: string;
  eduPersonPrincipalNames?: string[];
  externalIdentifiers?: ExternalIdentifier[];
  civicNo?: CivicNo;
  birthDate?: string;
  sex?: "Man" | "Kvinna" | "Okänt";
  securityMarking?: "Ingen" | "Sekretessmarkering" | "Skyddad folkbokföring";
  personStatus?: "Aktiv" | "Utvandrad" | "Avliden";
  emails?: Email[];
  phoneNumbers?: Phonenumber[];
  addresses?: PersonAddresses[];
  photo?: string;
  enrolments?: Enrolment[];
  responsibles?: PersonResponsible[];
}
export interface PersonResponsible {
  person?: PersonReference;
  relationType?: CodeRelationType;
}
export interface ExternalIdentifier {
  value: string;
  context: string;
  globallyUnique: boolean;
}
export interface GroupMembership {
  group?: GroupFragment;
  startDate?: string;
  endDate?: string;
}

export type PersonExpanded = Person & {
  _embedded?: {
    responsibleFor?: Array<{
      person?: PersonReference;
      relationType?: CodeRelationType;
    }>;
    placements?: Placement[];
    ownedPlacements?: Placement[];
    duties?: Duty[];
    groupMemberships?: GroupMembership[];
  };
};

export interface Placement {
  id: string;
  meta?: Meta;
  placedAt?: SchoolUnitReference;
  group?: GroupReference;
  child?: PersonReference;
  owners?: PersonReference[];
  schoolType?: "FS" | "FTH" | "OPPFTH";
  startDate?: string;
  endDate?: string;
  reason?: "Omsorg" | "Erbjuden tid" | "Eget behov";
  maxWeeklyScheduleHours?: number;
}

export type PlacementExpanded = Placement & {
  _embedded?: {
    child?: Person;
    owners?: Person[];
    responsibles?: Person[];
  };
};

export interface Programme {
  id: string;
  meta?: Meta;
  name: string;
  type?:
    | "Yrkesprogram"
    | "Högskoleförberedande program"
    | "Intruduktionsprogram"
    | "Nationellt yrkespaket"
    | "Regionalt yrkespaket"
    | "Fjärde tekniskt år"
    | "Programinriktning"
    | "Utgång";
  parentProgramme?: { id: string; displayName?: string };
  schoolType: CodeSchoolType;
  code?: string;
  content?: ProgrammeContent[];
}

export interface ProgrammeContent {
  type:
    | "Gymnasiegemensamma"
    | "Programgemensamma"
    | "Inriktning"
    | "Programfördjupning"
    | "Gymnasiearbete"
    | "Individuellt val";
  points?: number;
  content: SyllabusReference[];
}

export interface Resource {
  id: string;
  meta: Meta;
  displayName: string;
  description?: string;
  owner: OrganisationReference;
}

export interface Room {
  id: string;
  meta: Meta;
  displayName: string;
  seats?: number;
  owner: OrganisationReference;
}

export interface SchoolUnitOffering {
  startDate?: string;
  endDate?: string;
  offeredAt: SchoolUnitReference;
  offeredSyllabuses: SyllabusReference[];
  offeredProgrammes?: ProgrammeReference[];
}
export interface GroupMembership {
  person?: PersonReference;
  startDate?: string;
  endDate?: string;
}

export type Group = GroupFragment & {
  groupMemberships?: GroupMembership[];
};

export interface GroupAssignmentRole {
  duty?: DutyReference;
  assignmentRoleType?: CodeAssignmentRole;
  startDate?: string;
  endDate?: string;
}

export type GroupExpanded = Group & {
  _embedded?: {
    assignmentRoles?: GroupAssignmentRole[];
  };
};

export interface GroupFragment {
  id: string;
  meta?: Meta;
  displayName: string;
  startDate: string;
  endDate?: string;
  groupType: CodeGroupType;
  schoolType?: {} & CodeSchoolType;
  organisation: OrganisationReference;
}

export interface StudyPlan {
  id: string;
  student: PersonReference;
  meta?: Meta;
  content?: StudyPlanContent[];
  notes?: StudyPlanNotes[];
  startDate: string;
  endDate?: string;
}

export type StudyPlanContentType =
  | "Gymnasiegemensamma"
  | "Programgemensamma"
  | "Inriktning"
  | "Programfördjupning"
  | "Gymnasiearbete"
  | "Individuellt val"
  | "Borttagna"
  | "Utökade";

export interface StudyPlanContent {
  title?: string;
  type?: StudyPlanContentType;
  points?: number;
  syllabuses: StudyPlanContentSyllabus[];
}

export interface StudyPlanContentSyllabus {
  syllabus: SyllabusReference;
  note?: string;
  startDate?: string;
  endDate?: string;
  hours?: number;
}

export interface StudyPlanNotes {
  type?:
    | "Anteckningar"
    | "Andra insatser som är gynnsamma för elevens kunskapsutveckling"
    | "Elevens tidigare arbetslivserfarenhet och studier"
    | "Validering av kunskaper och kompetenser"
    | "Elevens mål med studierna";
  note?: string;
}

export type Subscription = {
  id: string;
  expires: string;
} & CreateSubscription;

export interface Syllabus {
  id: string;
  meta?: Meta;
  schoolType: {} & CodeSchoolType;
  subjectCode: string;
  subjectName: string;
  subjectDesignation?: string;
  courseCode?: string;
  courseName?: string;
  startSchoolYear?: number;
  endSchoolYear?: number;
  points?: number;
  curriculum?: CodeCurriculum;
  languageCode?: string;
  specialisationCourseContent?: {
    title: string;
    description: string;
    titleEngish?: string;
    descriptionEnglish?: string;
  };
  official: boolean;
}

export const SchoolTypeLookupDef = {
  FS: "Förskola",
  FKLASS: "Förskoleklass",
  FTH: "Fritidshem",
  OPPFTH: "Öppen fritidsverksamhet",
  GR: "Grundskola",
  GRS: "Grundsärskola",
  TR: "Träningsskolan",
  SP: "Specialskola",
  SAM: "Sameskola",
  GY: "Gymnasieskola",
  GYS: "Gymnasiesärskola",
  VUX: "Kommunal vuxenutbildning",
  VUXSFI: "Kommunal vuxenutbildning i svenska för invandrare",
  VUXGR: "Kommunal vuxenutbildning på grundläggande nivå",
  VUXGY: "Kommunal vuxenutbildning på gymnasial nivå",
  VUXSARGR:
    "Kommunal vuxenutbildning som särskild utbildning på grundläggande nivå",
  VUXSARTR:
    "Kommunal vuxenutbildning som särskild utbildning som motsvarar träningsskolan",
  VUXSARGY:
    "Kommunal vuxenutbildning som särskild utbildning på gymnasial nivå",
  SARVUX: "Särskild utbildning för vuxna",
  SARVUXGR: "Särskild utbildning för vuxna på grundläggande nivå",
  SARVUXGY: "Särskild utbildning för vuxna på gymnasial nivå",
  SFI: "Kommunal vuxenutbildning i svenska för invandrare",
  KU: "Kulturskola",
  YH: "Yrkeshögskola",
  FHS: "Folkhögskola",
  STF: "Studieförbund",
  KKU: "Konst- och kulturutbildning",
  HS: "Högskola",
  ABU: "Arbetsmarknadsutbildning",
  AU: "Annan undervisning",
};
