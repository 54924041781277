import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Toolbar from "@mui/material/Toolbar";
import type { Theme } from "@mui/material/styles";
import { ReactElement, ReactNode, useContext } from "react";
import { DrawerOpenContext } from "../drawer/drawerContext";
import { drawerWidth, px2Rem } from "../stylistTheme";

export interface StylistHeaderProps {
  children: ReactNode;
  open?: boolean;
}

export function StylistHeader({
  children,
  open,
}: StylistHeaderProps): ReactElement {
  const appContext = useContext(DrawerOpenContext);
  const isOpen = open !== undefined ? open : appContext.open;
  return (
    <Box
      sx={{
        // Make sure that we pad the body with the same height as the appbar (which is fixed)
        mb: 8,
        width: "100%",
      }}
    >
      <AppBar
        color="default"
        position="fixed"
        elevation={1}
        sx={{
          lineHeight: px2Rem(24),
          fontWeight: 700,
          letterSpacing: 0,
          backgroundColor: "#FFFFFF",
          border: "1px solid rgba(0, 0, 0, 0.12)",
          borderTop: "none",
          transition: (theme: Theme) =>
            theme.transitions.create(["padding"], {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.leavingScreen,
            }),
          // Shift everything when the menu is open
          paddingLeft: (theme: Theme) =>
            isOpen ? drawerWidth : theme.spacing(7),
        }}
      >
        <Toolbar>
          <Grid
            container
            justifyContent="space-between"
            direction="row"
            alignItems="center"
          >
            {children}
          </Grid>
        </Toolbar>
      </AppBar>
    </Box>
  );
}
