import { useMemo } from "react";
import { useIsGraphqlAuthenticated } from "../../graphql";
import { gql, useQuery } from "@apollo/client";
import { Option, OptionType } from "./types";
import { useIsSuperadmin } from "common/sessionInfo";
import { EducloudRole } from "common/educloudRoles";

export default function useOrgsAndVendors(
  educloudRoleFilter?: EducloudRole[], // Only return organisations and vendors where the current user has one of these roles
  skip?: boolean
): {
  loading: boolean;
  options: Option[] | undefined;
} {
  const graphqlAuthenticated = useIsGraphqlAuthenticated();
  const superAdmin = useIsSuperadmin();

  const regularUserResult = useQuery<RegularUsersOrgsAndVendorsQueryResult>(
    REGULAR_USER_QUERY,
    {
      context: {
        clientName: "educloud",
      },
      fetchPolicy: "network-only",
      skip: !graphqlAuthenticated || skip || superAdmin,
    }
  );

  const superAdminQueryResult = useQuery<SuperAdminOrgsAndVendorsQueryResult>(
    SUPER_ADMIN_QUERY,
    {
      context: {
        clientName: "educloud",
      },
      fetchPolicy: "network-only",
      skip: !graphqlAuthenticated || skip || !superAdmin,
    }
  );

  const { data, loading } = superAdmin
    ? superAdminQueryResult
    : regularUserResult;

  const options: Option[] | undefined = useMemo(() => {
    let results: Option[] | undefined;

    if (data?.me) {
      results = [];
      // Regular role assignments
      const includedUnitIds = new Set(); // For preventing duplicate entries
      for (const { organisation, organisationRole, vendor, vendorRole } of data
        .me.roleAssignments.nodes) {
        const unit = organisation ?? vendor;
        const role = organisationRole ?? vendorRole;

        const roleTypeMatches =
          role && educloudRoleFilter?.includes(role) !== false;

        if (roleTypeMatches && unit && !includedUnitIds.has(unit.id)) {
          includedUnitIds.add(unit.id);
          results.push({
            id: unit.id,
            name: unit.name,
            type: organisation ? OptionType.Organisation : OptionType.Vendor,
          });
        }
      }
    } else if (data?.organisations) {
      // Super admins
      results = [];
      for (const [nodes, type] of [
        [data.organisations.nodes, OptionType.Organisation],
        [data.vendors.nodes, OptionType.Vendor],
      ] as [IdAndName[], OptionType][]) {
        for (const { id, name } of nodes) {
          results.push({
            id,
            name,
            type,
          });
        }
      }
    }
    results?.sort(
      (a, b) => a.type.localeCompare(b.type) || a.name.localeCompare(b.name)
    );
    return results;
  }, [data, educloudRoleFilter]);

  return { loading, options };
}

interface IdAndName {
  id: string;
  name: string;
}

interface RegularUsersOrgsAndVendorsQueryResult {
  me: null | {
    roleAssignments: {
      nodes: {
        organisation?: IdAndName;
        organisationRole?: EducloudRole;
        vendor?: IdAndName;
        vendorRole?: EducloudRole;
      }[];
    };
  };
  organisations: never;
  vendors: never;
}

// For regular users (admins, devs, sales)
const REGULAR_USER_QUERY = gql`
  query RegularUserOrgsAndVendorsQuery {
    me {
      roleAssignments {
        nodes {
          ... on VendorRoleAssignment {
            vendorRole: role
            vendor {
              id
              name
            }
          }
          ... on OrganisationRoleAssignment {
            organisationRole: role
            organisation {
              id
              name
            }
          }
        }
      }
    }
  }
`;

interface SuperAdminOrgsAndVendorsQueryResult {
  me: never;
  organisations: {
    nodes: IdAndName[];
  };
  vendors: {
    nodes: IdAndName[];
  };
}

// This gets all the organisations and vendors,
// but isn't available to regular administrators.
// Necessary since super admins don't necessarily have assignments for
// every single organisation and vendor
const SUPER_ADMIN_QUERY = gql`
  query SuperAdminOrgsAndVendorsQuery {
    organisations {
      nodes {
        id
        name
      }
    }
    vendors {
      nodes {
        id
        name
      }
    }
  }
`;
