import { useApolloClient } from "@apollo/client";
import { useCallback } from "react";
import downloadText from "common/downloadText";
import * as common from "common/types";
import { GET_CLIENT_SOURCE_AUDIT_LOGS_QUERY, AuditEntry } from "./api";

function arrayDiff<Value>(values: Value[], remove: Value[]): Value[] {
  const set = new Set(values);
  for (const re of remove) {
    set.delete(re);
  }
  return [...set];
}

function formatAuditLogEntry(
  { actorClientId, actor, created, ip, newValue, oldValue }: AuditEntry,
  idToOrganisation: Map<string, common.Organisation>
): string {
  const [
    inclusionsAdded,
    inclusionsRemoved,
    exclusionsAdded,
    exclusionsRemoved,
  ] = [
    [newValue.includes, oldValue?.includes],
    [oldValue?.includes, newValue.includes],
    [newValue.excludes, oldValue?.excludes],
    [oldValue?.excludes, newValue.excludes],
  ].map(([a = [], b = []]) =>
    arrayDiff(a, b)
      .map((oId) => idToOrganisation.get(oId)?.displayName ?? oId)
      .join(", ")
  );

  const permissionModeChanged =
    newValue.permissionMode !== oldValue?.permissionMode;

  const includeAliasPersonsChanged =
    newValue.includeAliasPersons !== oldValue?.includeAliasPersons;

  const actorNameOrId = actor?.name ?? actor?.id;

  return [
    `Date: ${new Date(created).toLocaleString()}`,
    (actorNameOrId || actorClientId) &&
      `Actor: ${actorNameOrId} @ ${actorClientId}`,
    ip && `IP address: ${ip}`,
    permissionModeChanged &&
      `Permission mode: ${newValue.permissionMode}` +
        (oldValue ? ` (was ${oldValue.permissionMode})` : ""),
    includeAliasPersonsChanged &&
      `Include persons with alternative person data: ${newValue.includeAliasPersons}` +
        (oldValue ? ` (was ${oldValue.includeAliasPersons})` : ""),
    inclusionsRemoved && `Inclusions removed: ${inclusionsRemoved}`,
    exclusionsRemoved && `Exclusions removed: ${exclusionsRemoved}`,
    inclusionsAdded && `Inclusions added: ${inclusionsAdded}`,
    exclusionsAdded && `Exclusions added: ${exclusionsAdded}`,
  ]
    .filter((line) => !!line)
    .join("\n");
}

export default function useAuditDataFileDownloads(
  sourceId: string,
  clientId: string,
  organisations: common.Organisation[]
): () => Promise<void> {
  const { query } = useApolloClient();
  const download = useCallback(async () => {
    const { data, errors } = await query<{
      clientSourceAuditLogs: AuditEntry[];
    }>({
      fetchPolicy: "network-only",
      query: GET_CLIENT_SOURCE_AUDIT_LOGS_QUERY,
      variables: { clientId: clientId, sourceId: sourceId },
    });
    if (errors) {
      console.error("Query errors: %o", errors);
      throw new Error("Query errors");
    }

    const idToOrganisation = new Map(organisations.map((org) => [org.id, org]));
    const text = data.clientSourceAuditLogs
      .map((logEntry) => formatAuditLogEntry(logEntry, idToOrganisation))
      .reverse() // Newest first
      .join("\n\n--------\n\n");
    downloadText(text, "Log.txt");
  }, [clientId, organisations, query, sourceId]);
  return download;
}
