import { ReactElement } from "react";
import { validDateFilter } from "../common";
import { ListGenericComponent, ObjectReferenceLink } from "../common/generic";
import { GenericListViewProps } from "../common/rows";
import * as common from "../common/types";
import rowValidDateFilterStyle from "./rowValidDateFilterStyle";
import { Column } from "@material-table/core";

function renderChildDisplayName(placement: common.PlacementExpanded) {
  return (
    <ObjectReferenceLink item={placement.child} kind={common.Target.persons} />
  );
}

function renderGroup(placement: common.PlacementExpanded) {
  return (
    <ObjectReferenceLink item={placement.group} kind={common.Target.groups} />
  );
}

const placementsListColumns: Column<common.PlacementExpanded>[] = [
  {
    title: "Name",
    field: "displayName",
    render: renderChildDisplayName,
  },
  {
    title: "Group",
    field: "group.id",
    render: renderGroup,
    grouping: true,
  },
  { title: "Type", field: "schoolType" },
  { title: "Reason", field: "reason" },
  {
    title: "Start",
    field: "startDate",
    cellStyle: rowValidDateFilterStyle,
  },
  {
    title: "End",
    field: "endDate",
    cellStyle: rowValidDateFilterStyle,
  },
];

export interface PlacementsListComponentProps
  extends GenericListViewProps<common.PlacementExpanded> {}

export function PlacementsListComponent(
  props: PlacementsListComponentProps
): ReactElement {
  return (
    <ListGenericComponent<common.PlacementExpanded>
      orderBy={["child.displayName"]}
      name={(row) => row.child?.displayName || "???"}
      checkboxFilter={{
        label: "Only current placements",
        condition: validDateFilter,
        defaultValue: true,
      }}
      columns={placementsListColumns}
      {...props}
    />
  );
}
