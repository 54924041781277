import { ReactElement, ReactNode, useCallback } from "react";
import SearchInput from "../SearchInput";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import type { Theme } from "@mui/material/styles";

// These types are a guess based on our earlier code and Material-Table's code.
// Material-Table's types and documentation leave - at the time of writing 2021 - something to be desired.
export interface CustomMaterialTableToolbarProps {
  actions?: any[];
  children?: ReactNode;
  components: any;
  dataManager?: any;
  onSearchChanged: (text: string) => unknown;
  title?: ReactNode;
  [propName: string]: unknown;
}

export default function CustomMaterialTableToolbar({
  actions = [],
  children,
  components,
  dataManager,
  onSearchChanged,
  title,
}: CustomMaterialTableToolbarProps): ReactElement {
  const onSearchChange = useCallback(
    (searchText: string) => {
      dataManager?.changeSearchText(searchText);
      onSearchChanged(searchText);
    },
    [dataManager, onSearchChanged]
  );

  return (
    <Toolbar
      sx={{
        paddingLeft: (theme: Theme) => theme.spacing(3),
        paddingRight: (theme: Theme) => theme.spacing(3),
      }}
    >
      <Box sx={{ overflow: "hidden" }}>
        {typeof title === "string" ? (
          <Typography
            variant="h6"
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {title}
          </Typography>
        ) : (
          title
        )}
      </Box>
      <Box flex="1 1 10%" />
      {children}
      <SearchInput
        onChange={onSearchChange}
        placeholder="Search"
        searchAriaLabel="Search"
        clearSearchAriaLabel="Clear search"
      />
      <Box
        sx={{
          color: (theme: Theme) => theme.palette.text.secondary,
        }}
      >
        <components.Actions
          actions={actions.filter((a) => a.position === "toolbar")}
          components={components}
        />
      </Box>
    </Toolbar>
  );
}
