import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import type { Theme } from "@mui/material/styles";
import styled from "@mui/material/styles/styled";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import PersonIcon from "@mui/icons-material/Person";
import { MouseEvent, ReactNode, useState } from "react";

interface Address {
  /** Full mailing address, formatted for display or use on a mailing label */
  formatted?: string;
  /** Full street address component, which MAY include house number, street name, Post Office Box, and multi-line extended street address information */
  street_address?: string;
  /** City or locality component */
  locality?: string;
  /** State, province, prefecture, or region component */
  region?: string;
  /** Zip code or postal code component */
  postal_code?: string;
  /** Country name component */
  country?: string;
}

export interface User {
  profile?: {
    /** End-User's full name */
    name?: string;
    /** Given name(s) or first name(s) of the End-User */
    given_name?: string;
    /** Surname(s) or last name(s) of the End-User */
    family_name?: string;
    /** Middle name(s) of the End-User */
    middle_name?: string;
    /** Casual name of the End-User that may or may not be the same as the given_name. */
    nickname?: string;
    /** Shorthand name that the End-User wishes to be referred to at the RP, such as janedoe or j.doe. */
    preferred_username?: string;
    /** URL of the End-User's profile page */
    profile?: string;
    /** URL of the End-User's profile picture */
    picture?: string;
    /** URL of the End-User's Web page or blog */
    website?: string;
    /** End-User's preferred e-mail address */
    email?: string;
    /** True if the End-User's e-mail address has been verified; otherwise false. */
    email_verified?: boolean;
    /** End-User's gender. Values defined by this specification are female and male. */
    gender?: string;
    /** End-User's birthday, represented as an ISO 8601:2004 [ISO8601‑2004] YYYY-MM-DD format */
    birthdate?: string;
    /** String from zoneinfo [zoneinfo] time zone database representing the End-User's time zone. */
    zoneinfo?: string;
    /** End-User's locale, represented as a BCP47 [RFC5646] language tag. */
    locale?: string;
    /** End-User's preferred telephone number. */
    phone_number?: string;
    /** True if the End-User's phone number has been verified; otherwise false. */
    phone_number_verified?: boolean;
    /** object 	End-User's preferred address in JSON [RFC4627] */
    address?: Address;
    /** Time the End-User's information was last updated. */
    updated_at?: number;
  };
}

export interface StylistAppMenuProps {
  children?: ReactNode;
  logoutTitle?: string;
  onLogoutClick?: () => void;
  user?: User | null;
}

const AvatarBase = styled(Avatar)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  fontSize: theme.spacing(1.5),
  height: theme.spacing(4),
  marginRight: theme.spacing(1),
  marginLeft: theme.spacing(-2),
  width: theme.spacing(4),
}));

export function StylistAppMenu({
  children,
  logoutTitle,
  onLogoutClick,
  user,
}: StylistAppMenuProps) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleUserMenuOpen = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleUserMenuClose = () => {
    setAnchorEl(null);
  };
  const logoutTitleText = logoutTitle === undefined ? "Log out" : logoutTitle;
  return user ? (
    <Grid style={{ flexGrow: 0 }} item>
      <Button
        aria-label="The current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleUserMenuOpen}
        color="inherit"
        variant="text"
        sx={{
          height: (theme: Theme) => theme.spacing(4),
          padding: (theme: Theme) => theme.spacing(0, 1, 0, 2),
          textTransform: "initial",
          typography: "body1",
        }}
      >
        <AvatarBase src={user.profile?.picture} variant="circular">
          <PersonIcon fontSize="small" />
        </AvatarBase>
        {user.profile?.nickname ?? user.profile?.given_name}
        <ArrowDropDownIcon color="inherit" />
      </Button>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        style={{ marginTop: 40 }}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleUserMenuClose}
      >
        {children}
        {onLogoutClick ? (
          <MenuItem onClick={onLogoutClick}>{logoutTitleText}</MenuItem>
        ) : null}
      </Menu>
    </Grid>
  ) : null;
}
