import { ReactElement } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { Route, Switch, useRouteMatch } from "react-router";
import { useSourceId } from "../common";
import {
  DataBox,
  DataBoxBlock,
  DataBoxSection,
  ListGenericComponent,
  ObjectReferenceLink,
  PropertyItem,
  useObjectReferenceCallback,
} from "../common/generic";
import { GenericListViewProps, RowComponent } from "../common/rows";
import * as common from "../common/types";
import ExplorerHeader from "./ExplorerHeader";

interface SingleGradeProps {
  kind: common.Target;
  row?: common.Grade | null;
}

function SingleGrade({ kind, row: grade }: SingleGradeProps): ReactElement {
  if (!grade) {
    return <Typography variant="h2">No grade found</Typography>;
  }

  return (
    <Grid
      container
      spacing={3}
      alignItems="stretch"
      alignContent="stretch"
      style={{
        flexGrow: 1,
      }}
    >
      <DataBox data={grade} title="Grade">
        <DataBoxSection>
          <DataBoxBlock xs={4}>
            <PropertyItem title="Student">
              <ObjectReferenceLink
                kind={common.Target.persons}
                item={grade.student}
              />
            </PropertyItem>
            <PropertyItem title="Organisation">
              <ObjectReferenceLink
                kind={common.Target.organisations}
                item={grade.organisation}
              />
            </PropertyItem>
          </DataBoxBlock>
          <DataBoxBlock xs={4}>
            <PropertyItem title="Syllabus">
              <ObjectReferenceLink
                kind={common.Target.syllabuses}
                item={grade.syllabus}
              />
            </PropertyItem>
            <PropertyItem title="Grade Value" value={grade.gradeValue} />
          </DataBoxBlock>
          <DataBoxBlock xs={4}>
            <PropertyItem title="Semester" value={grade.semester} />
            <PropertyItem title="Year" value={grade.year} />
          </DataBoxBlock>
          <DataBoxBlock xs={4}>
            <PropertyItem title="Registred By">
              <ObjectReferenceLink
                item={grade.registeredBy}
                kind={common.Target.persons}
              />
            </PropertyItem>
            <PropertyItem title="Registered" value={grade.registeredDate} />
          </DataBoxBlock>
        </DataBoxSection>
        <DataBoxSection>
          <DataBoxBlock xs={4}>
            <PropertyItem title="Grading Teacher">
              <ObjectReferenceLink
                item={grade.gradingTeacher}
                kind={common.Target.persons}
              />
            </PropertyItem>
            <PropertyItem title="Group">
              <ObjectReferenceLink
                item={grade.group}
                kind={common.Target.groups}
              />
            </PropertyItem>
          </DataBoxBlock>
          <DataBoxBlock xs={4}>
            <PropertyItem title="Remark" value={grade.remark} />
            <PropertyItem title="Final Grade" value={grade.finalGrade} />
          </DataBoxBlock>
          <DataBoxBlock xs={4}>
            <PropertyItem title="Trial" value={grade.trial} />
            <PropertyItem
              title="Adapted Study Plan"
              value={grade.adaptedStudyPlan}
            />
          </DataBoxBlock>
          <DataBoxBlock xs={4}>
            <PropertyItem title="Converted" value={grade.converted} />
            <PropertyItem title="Correction" value={grade.correctionType} />
          </DataBoxBlock>
        </DataBoxSection>
        <DataBoxSection>
          <DataBoxBlock xs={4}>
            {grade.diplomaProject && (
              <>
                <h5>Diploma Project</h5>
                <PropertyItem
                  title="Title"
                  value={grade.diplomaProject?.title}
                />
                <PropertyItem
                  title="Description"
                  value={grade.diplomaProject?.description}
                />
                <PropertyItem
                  title="Title (EN)"
                  value={grade.diplomaProject?.titleEngish}
                />
                <PropertyItem
                  title="Description (EN)"
                  value={grade.diplomaProject?.descriptionEnglish}
                />
              </>
            )}
          </DataBoxBlock>
        </DataBoxSection>
      </DataBox>
    </Grid>
  );
}

export const GradesListComponent = (
  props: GenericListViewProps<common.Grade>
) => {
  const click = useObjectReferenceCallback(common.Target.grades);
  return (
    <ListGenericComponent<common.Grade>
      onRowClick={click}
      columns={[
        {
          title: "Grade",
          field: "gradeValue",
        },
        {
          title: "Semester",
          field: "semester",
        },
        {
          title: "Year",
          field: "year",
        },
        {
          title: "Syllabus",
          field: "syllabus.displayName",
          render: (value) => (
            <ObjectReferenceLink
              item={value.syllabus}
              kind={common.Target.syllabuses}
            />
          ),
        },
        {
          title: "Unit",
          field: "schoolUnit",
          render: (value) => (
            <ObjectReferenceLink
              item={value.organisation}
              kind={common.Target.organisations}
            />
          ),
        },
        {
          title: "Registered By",
          field: "registeredBy",
          render: (value) => (
            <ObjectReferenceLink
              item={value.registeredBy}
              kind={common.Target.persons}
            />
          ),
        },
      ]}
      {...props}
      title={(<Typography variant="h4">Grades</Typography>) as any}
    />
  );
};

export function Grades(): ReactElement {
  const rm = useRouteMatch();

  const ac: common.ApiCall = {
    sourceId: useSourceId(),
    target: common.Target.grades,
    method: common.Method.GET,
  };

  if (!rm) {
    throw Error("RouteMatch not defined.");
  }

  return (
    <>
      <ExplorerHeader typeLink={rm.path} typeName="Grade" />
      <Switch>
        <Route
          path={`${rm.path}/:gradeId`}
          render={({ match }) => (
            <RowComponent<common.Grade>
              apiCall={{ ...ac, path: match.params.gradeId }}
              view={SingleGrade}
            />
          )}
        />
      </Switch>
    </>
  );
}
