import IconButton from "@mui/material/IconButton";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { ChevronRight, KeyboardArrowDown } from "@mui/icons-material/";
import { mdiOpenInNew } from "@mdi/js";
import { Icon } from "@mdi/react";
import { ReactElement, useCallback, useState } from "react";
import { Link, useParams } from "react-router-dom";
import filterRow from "common/filterRow";
import { ValidEndDateString, ValidStartDateString } from "common/generic";
import { FilterData } from "explorer/OrganisationTree";
import PermissionCheckbox from "./PermissionCheckbox";
import { OrgNode } from "./orgNodes";
import { DenormalizedChoiceInfo, PermissionChoice } from "./choices";

export interface RowAndChildrenProps {
  filters: FilterData;
  getChoiceInfoForNode: (node: OrgNode) => DenormalizedChoiceInfo;
  node: OrgNode;
  setChoiceForNode: (
    node: OrgNode,
    choice: PermissionChoice | undefined
  ) => void;
}

export default function RowAndChildren({
  filters,
  getChoiceInfoForNode,
  node,
  setChoiceForNode,
}: RowAndChildrenProps): ReactElement {
  const { organisation } = node;
  const organisationId = organisation.id;
  const choiceInfo = getChoiceInfoForNode(node);

  const persistanceId = `permission_${node.depth}_${organisationId}`;
  const { customerId, sourceId } = useParams<{
    customerId?: string;
    sourceId?: string;
  }>();

  const [unfolded, setUnFolded] = useState<Set<string>>(() => {
    try {
      const persistanceItem = localStorage.getItem(persistanceId);
      if (persistanceItem) {
        return new Set(JSON.parse(persistanceItem) as string[]);
      }
    } catch (e) {}

    if (node.depth === 0) {
      return new Set([organisationId]);
    }
    return new Set();
  });

  const handleChoose = useCallback(
    (newChoice: undefined | PermissionChoice) => {
      setChoiceForNode(node, newChoice);
    },
    [node, setChoiceForNode]
  );

  const updateFolded = useCallback(() => {
    const newUnfoldedSet = new Set(unfolded);
    if (newUnfoldedSet.has(organisationId)) {
      newUnfoldedSet.delete(organisationId);
    } else {
      newUnfoldedSet.add(organisationId);
    }
    localStorage.setItem(
      persistanceId,
      JSON.stringify(Array.from(newUnfoldedSet))
    );
    setUnFolded(newUnfoldedSet);
  }, [organisationId, unfolded, persistanceId]);

  const opened = unfolded.has(organisationId);
  const visible = filterRow(organisation, filters);

  return (
    <>
      {visible ? (
        <TableRow>
          <TableCell
            style={{
              paddingLeft: node.depth * 24,
              whiteSpace: "nowrap",
              overflow: "overlay",
            }}
          >
            <div
              style={{
                width: 32,
                display: "inline-block",
                marginTop: -6,
                height: 14,
              }}
            >
              {node.children.length ? (
                <IconButton onClick={updateFolded} size="small">
                  {opened ? <KeyboardArrowDown /> : <ChevronRight />}{" "}
                </IconButton>
              ) : null}
            </div>
            <PermissionCheckbox
              choiceInfo={choiceInfo}
              onChoose={handleChoose}
            />
            {organisation.displayName}
            <Link
              style={{
                verticalAlign: "middle",
                padding: 0,
                display: "inline-flex",
                margin: "0 0 0 4px",
              }}
              target="_blank"
              to={`/explorer/${customerId}/${sourceId}/organisations/${organisationId}`}
            >
              <Icon path={mdiOpenInNew} size={1} color="gray" />
            </Link>
          </TableCell>
          <TableCell>{organisation.organisationType ?? ""}</TableCell>
          <TableCell>{organisation.schoolTypes?.join(", ") ?? ""}</TableCell>
          <TableCell>{organisation.organisationCode ?? ""}</TableCell>
          <TableCell>{organisation.schoolUnitCode ?? ""}</TableCell>
          <TableCell>
            <ValidStartDateString start={organisation.startDate} />
          </TableCell>
          <TableCell>
            <ValidEndDateString end={organisation.endDate} />
          </TableCell>
        </TableRow>
      ) : null}
      {(opened || !visible) &&
        node.children.map((childNode) => {
          return (
            <RowAndChildren
              filters={filters}
              getChoiceInfoForNode={getChoiceInfoForNode}
              key={childNode.organisation.id}
              node={childNode}
              setChoiceForNode={setChoiceForNode}
            />
          );
        })}
    </>
  );
}
