import Box from "@mui/material/Box";
import MuiAlert from "@mui/material/Alert";
import { ReactElement, useEffect } from "react";
import { useRouteMatch } from "react-router-dom";
import {
  DataBoxBlock,
  DataBoxSection,
  PropertyItem,
  Section,
} from "common/generic";
import { RowsComponent } from "common/rows";
import { useSourceInfo } from "common/sourceInfo";
import * as common from "common/types";
import useValuesChangedFlag from "common/useValuesChangedFlag";
import { useClientList } from "./api";
import ClientAccessChipList from "./ClientAccessChipList";
import PermissionsHeader from "./PermissionsHeader";
import SelectClient from "./SelectClient";
import ListOrganisationsComponent from "./ListOrganisationsComponent";
import ErrorMessage from "./ErrorMessage";

export function Permissions(): ReactElement {
  const routeMatch = useRouteMatch<{
    customerId: string;
    sourceId: string;
    clientId?: string;
  }>();
  const { clientId, sourceId } = routeMatch.params;
  const {
    error,
    data,
    loading,
    refetch: refetchClientList,
  } = useClientList(sourceId);
  const { sourceInfo, sourceInfoLoading } = useSourceInfo();

  // We refetch the client list every time the client selection changes
  const [needsClientRefetch, resetNeedsClientRefetch] =
    useValuesChangedFlag(clientId);
  useEffect(() => {
    if (data && !loading && needsClientRefetch) {
      resetNeedsClientRefetch();
      refetchClientList();
    }
  }, [
    data,
    loading,
    needsClientRefetch,
    refetchClientList,
    resetNeedsClientRefetch,
  ]);

  if (error) {
    return <ErrorMessage>{error.message}</ErrorMessage>;
  }
  if (!clientId) {
    return <SelectClient sourceAccessList={data?.sourceAccessList} />;
  }
  const clientInfo = sourceInfo
    .get(sourceId)
    ?.clients.find((c) => c.id === clientId);
  const accessClient = data?.sourceAccessList?.find(
    (a) => a.clientId === clientId
  );
  const productNames = clientInfo?.product?.name ?? null;
  const anyLoading = loading || sourceInfoLoading;

  return (
    <>
      <PermissionsHeader itemName={productNames ?? clientId} />
      <Section
        style={{ width: "fit-content" }}
        name={productNames ?? clientId}
        loading={anyLoading}
        md="auto"
      >
        <DataBoxSection>
          {anyLoading ? (
            <Box pt={8} />
          ) : clientInfo ? (
            <>
              <DataBoxBlock>
                <PropertyItem title="Client">
                  {clientInfo?.name ?? clientId} [{clientId}]
                </PropertyItem>
              </DataBoxBlock>
              <DataBoxBlock>
                <PropertyItem title="Vendor">
                  {clientInfo?.product?.vendor?.name ?? "Unknown"}
                </PropertyItem>
              </DataBoxBlock>
              <DataBoxBlock xs={6}>
                <PropertyItem title="Data Protection Officer" value="" />
              </DataBoxBlock>
              <DataBoxBlock xs={12}>
                <PropertyItem title="Shared Data">
                  <ClientAccessChipList
                    clientId={clientId}
                    sourceId={sourceId}
                  />
                </PropertyItem>
              </DataBoxBlock>
            </>
          ) : (
            <MuiAlert elevation={6} variant="filled" severity="error">
              No information about this client
            </MuiAlert>
          )}
          {accessClient?.educloudEnabled === false ? (
            <Box pt={3}>
              <MuiAlert elevation={6} variant="filled" severity="error">
                This client does not have an active subscription in educloud
              </MuiAlert>
            </Box>
          ) : null}
        </DataBoxSection>
        <RowsComponent<common.Organisation>
          apiCall={{
            sourceId,
            target: common.Target.organisations,
            method: common.Method.GET,
          }}
          view={ListOrganisationsComponent}
        />
      </Section>
    </>
  );
}
