import { SessionContext } from "@ist-group-private-scope/web-skolid";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import Link from "@mui/material/Link";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
import Select from "@mui/material/Select";
import Typography from "@mui/material/Typography";
import Alert from "@mui/material/Alert";
import { StylistHeader } from "common/stylist/header/StylistHeader";
import { ReactElement, useCallback, useContext } from "react";
import { useHistory, useRouteMatch } from "react-router";
import { HeaderBreadcrumbs } from "../common/CustomizedBreadcrumbs";
import { Section, UserMenu } from "../common/generic";
import { useSourceInfoList } from "../common/sourceInfo";
import { RapiDoc } from "./RapiDoc";

function ApiDocHeader(): ReactElement {
  return (
    <StylistHeader>
      <HeaderBreadcrumbs name="API Documentation" />
      <UserMenu />
    </StylistHeader>
  );
}

export default function ApiDocContent(): ReactElement {
  const {
    params: { sourceId, clientId },
  } = useRouteMatch<{
    clientId?: string;
    sourceId?: string;
  }>("/api-doc/:clientId?/:sourceId?") || {
    params: {},
  };
  const session = useContext(SessionContext);
  const jwt = session.user && session.user.access_token;

  return (
    <>
      <ApiDocHeader />
      <Grid container spacing={3} alignContent="stretch">
        <Grid xs={12} item>
          <Alert severity="info">
            See these technical instructions for{" "}
            <Link
              rel="external"
              target="_blank"
              href="https://educloud.ist.com/guides/authentication/fetch-and-use-access-token-r2/"
            >
              how to get the access token
            </Link>
            .
          </Alert>
        </Grid>
        <SelectApiTarget />
        <Grid xs={12} item>
          <Paper elevation={2}>
            <Box py={3}>
              <RapiDoc
                jwt={jwt}
                sourceId={sourceId}
                clientId={clientId}
                specUrl="/docs/ss12000:2020"
              ></RapiDoc>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
}

function SelectApiTarget(): ReactElement {
  const {
    params: { sourceId, clientId },
  } = useRouteMatch<{
    clientId?: string;
    sourceId?: string;
  }>("/api-doc/:clientId?/:sourceId?") || { params: {} };
  const history = useHistory();
  const { sourceInfoList, sourceInfoLoading } = useSourceInfoList();
  const handleChangeSource = useCallback(
    (event: { target: { value: unknown } }) => {
      history.push(`/api-doc/${clientId}/${event.target.value}`);
    },
    [history, clientId]
  );
  const handleChangeClient = useCallback(
    (event: { target: { value: unknown } }) => {
      history.push(`/api-doc/${event.target.value}`);
    },
    [history]
  );

  const clients = Array.from(
    new Map(
      sourceInfoList?.flatMap(
        (sourceInfoItem) =>
          sourceInfoItem?.clients?.map((client) => [client.id, client.name]) ??
          []
      )
    )
  );
  return (
    <>
      <Section name="Select API Client" lg={6} loading={sourceInfoLoading}>
        <Box p={3} pt={0}>
          <Typography paragraph>
            Select the API-Client to use when calling the API-endpoints.
          </Typography>
          <Typography paragraph>
            Only clients associated to the product your account is connected to
            will be available. Contact the IST support if you cannot access the
            clients that should be available to you.
          </Typography>
          <FormControl fullWidth>
            <InputLabel id="clientId">API Client</InputLabel>
            <Select
              style={{ minHeight: 56 }}
              labelId="clientId"
              value={clientId ?? "-"}
              variant="standard"
              onChange={handleChangeClient}
            >
              {!clientId && (
                <MenuItem key="-" value={"-"}>
                  Select API Client
                </MenuItem>
              )}
              {clients.map((client) => (
                <MenuItem key={client[0]} value={client[0]}>
                  <span style={{ marginRight: "0.5em" }}>{client[1]}</span>
                  <Typography variant="subtitle2">{client[0]}</Typography>
                </MenuItem>
              )) ?? null}
            </Select>
          </FormControl>
        </Box>
      </Section>
      {clientId && (
        <Section lg={6} name="Select API Source" loading={sourceInfoLoading}>
          <Box p={3} pt={0}>
            <Typography paragraph>
              Select the data source to direct the API-calls to.
            </Typography>
            <Typography paragraph>
              Only data sources associated with the currently selected client
              will be available. Contact the dataowner if a source is missing in
              the list.
            </Typography>
            <FormControl fullWidth>
              <InputLabel id="sourceId">Data source</InputLabel>
              <Select
                style={{ minHeight: 56, minWidth: 200 }}
                labelId="sourceId"
                value={sourceId ?? "-"}
                variant="standard"
                onChange={handleChangeSource}
              >
                {!sourceId && (
                  <MenuItem key="-" value={"-"}>
                    Select API Source
                  </MenuItem>
                )}
                {sourceInfoList
                  ?.filter((s) => s.clients?.some((c) => c.id === clientId))
                  .map((source) => (
                    <MenuItem key={source.id} value={source.id}>
                      <div style={{ marginRight: "0.5em" }}>
                        {source.organisation?.name}
                      </div>
                      <Typography variant="subtitle2" component="span">
                        {source.id}
                      </Typography>
                      {source.description && (
                        <span style={{ marginLeft: "0.5em" }}>
                          - {source.description}
                        </span>
                      )}
                    </MenuItem>
                  )) ?? null}
              </Select>
            </FormControl>
          </Box>
        </Section>
      )}
    </>
  );
}
