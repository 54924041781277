import { ReactElement, memo } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { ClientSelect } from "../app/App";
import { HeaderBreadcrumbs } from "../common/CustomizedBreadcrumbs";
import { UserMenu } from "../common/generic";
import { StylistHeader } from "../common/stylist/header/StylistHeader";

export interface AccountsHeaderProps {
  subjectName?: string;
}

export default memo(function AccountsHeader({
  subjectName,
}: AccountsHeaderProps): ReactElement {
  const paths = subjectName ? [{ name: subjectName }] : [];

  return (
    <Box>
      <StylistHeader>
        <Box py={1} width="100%">
          <Grid justifyContent="flex-end" spacing={1} container>
            <Grid sm={12} md={"auto"} style={{ marginRight: "auto" }} item>
              <HeaderBreadcrumbs name={"Manage Accounts"} paths={paths} />
            </Grid>
            <ClientSelect />
            <Grid item>
              <UserMenu />
            </Grid>
          </Grid>
        </Box>
      </StylistHeader>
    </Box>
  );
});
