import { ReactElement } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { Route, Switch, useRouteMatch } from "react-router";
import { useSourceId } from "../common";
import {
  DataBox,
  DataBoxSection,
  ListGenericComponent,
  ObjectReferenceLink,
  PropertyItem,
  PropertyItemDateRange,
  useObjectReferenceCallback,
} from "../common/generic";
import { GenericListViewProps, RowComponent } from "../common/rows";
import * as common from "../common/types";
import ExplorerHeader from "./ExplorerHeader";
import { Column } from "@material-table/core";

interface SingleStudyplanProps {
  kind: common.Target;
  row?: common.StudyPlan | null;
}

function SingleStudyplan({
  row: studyplan,
}: SingleStudyplanProps): ReactElement {
  if (!studyplan) {
    return <Typography variant="h2">No studyplan found</Typography>;
  }

  return (
    <Grid
      container
      spacing={3}
      alignItems="stretch"
      alignContent="stretch"
      style={{
        flexGrow: 1,
      }}
    >
      <DataBox data={studyplan} title="Studyplan">
        <DataBoxSection>
          <PropertyItem title="Student">
            <ObjectReferenceLink
              kind={common.Target.persons}
              item={studyplan.student}
            />
          </PropertyItem>
          <PropertyItemDateRange
            title="Valid"
            start={studyplan.startDate}
            end={studyplan.endDate}
          />
        </DataBoxSection>
      </DataBox>
      <ListGenericComponent<
        common.StudyPlanContentSyllabus & { groupType: string }
      >
        columns={[
          { title: "Group", field: "groupType", defaultGroupOrder: 1 },
          {
            title: "Syllabus",
            field: "syllabus.displayName",
            render: (value) => (
              <ObjectReferenceLink
                item={value.syllabus}
                kind={common.Target.syllabuses}
              />
            ),
          },
          { title: "Hours", field: "hours" },
          { title: "Note", field: "note" },
          { title: "Start", field: "startDate" },
          { title: "Stop", field: "endDate" },
        ]}
        // Flattern out the structure and group in the table
        data={
          studyplan.content?.flatMap((content) =>
            content.syllabuses.map((item) => ({
              ...item,
              groupType:
                (content.title ?? "" + content.type ?? "") +
                (content.points ? " (" + content.points + ")" : ""),
            }))
          ) ?? []
        }
        options={{ filtering: false, toolbar: false, paging: false }}
      />
    </Grid>
  );
}

const studyplansListColumns: Column<common.StudyPlan>[] = [
  {
    title: "Student",
    field: "student",
    render: (value) => (
      <ObjectReferenceLink item={value.student} kind={common.Target.persons} />
    ),
  },
  { title: "Start", field: "startDate" },
  { title: "Stop", field: "endDate" },
];

export interface StudyplansListComponentProps
  extends GenericListViewProps<common.StudyPlan> {}

export function StudyplansListComponent(
  props: StudyplansListComponentProps
): ReactElement {
  const rowCallback = useObjectReferenceCallback(common.Target.studyplans);
  return (
    <ListGenericComponent<common.StudyPlan>
      columns={studyplansListColumns}
      onRowClick={rowCallback}
      {...props}
      title="Studyplans"
    />
  );
}

export function Studyplans(): ReactElement {
  const rm = useRouteMatch();

  const ac: common.ApiCall = {
    sourceId: useSourceId(),
    target: common.Target.studyplans,
    method: common.Method.GET,
  };

  if (!rm) {
    throw Error("RouteMatch not defined.");
  }

  return (
    <>
      <ExplorerHeader typeLink={rm.path} typeName="Studyplan" />
      <Switch>
        <Route
          path={`${rm.path}/:studyplanId`}
          render={({ match }) => (
            <RowComponent<common.StudyPlan>
              apiCall={{ ...ac, path: match.params.studyplanId }}
              view={SingleStudyplan}
            />
          )}
        />
      </Switch>
    </>
  );
}
